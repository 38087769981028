import { FC } from 'react'

import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import { FICO_SCORE_TYPE_OPTIONS } from '@/constants/formFieldOptions'

import RadioFormItem from './default-items/radio-form-item/RadioFormItem'

const { ficoScoreType } = FORM_FIELD_NAMES_MAP

const FicoScoreFormItem: FC = () => {
  return (
    <RadioFormItem
      name={ficoScoreType}
      options={FICO_SCORE_TYPE_OPTIONS}
      rules={[
        { required: true, message: 'Please select your FICO credit score' }
      ]}
      vertical
    />
  )
}

export default FicoScoreFormItem
