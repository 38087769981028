import { FC, ReactNode } from 'react'

import { Flex } from 'antd'

import InfoIcon from '@/components/icons/InfoIcon'

import './InfoContainer.scss'

interface IInfoBlockProps {
  title: string
  children: ReactNode
  className?: string
}

const InfoContainer: FC<IInfoBlockProps> = ({
  title,
  children,
  className = ''
}) => {
  return (
    <Flex vertical className={`info-block__container ${className}`}>
      <Flex align='center' gap={12} className='info-block__title'>
        <InfoIcon />
        {title}
      </Flex>
      <div className='info-block__text'>{children}</div>
    </Flex>
  )
}

export default InfoContainer
