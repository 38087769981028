import React, { ReactElement, useEffect, useRef } from 'react'

import { Form } from 'antd'
import PhoneInput, { PhoneInputProps, PhoneNumber } from 'antd-phone-input'
import { FormItemProps } from 'antd/es/form'
import { useWatch } from 'antd/es/form/Form'
import { NamePath } from 'antd/es/form/interface'
import { getCountry } from 'react-phone-hooks'

import { REQUIRED_FIELD_ERROR_MESSAGE } from '@/constants/texts'

import './PhoneInputFormItem.scss'

interface IPhoneInputFormItemProps extends  PhoneInputProps {
  name: NamePath
  fullName?: NamePath
  required?: boolean
  label?: string
}

const PhoneInputFormItem = ({
  name,
  fullName,
  required = true,
  label = 'Phone Number',
  ...rest
}: IPhoneInputFormItemProps): ReactElement => {
  const value: PhoneNumber = useWatch(fullName ?? name)

  const form = Form.useFormInstance()

  useEffect(() => {
    if (!value || value.isoCode === 'us') {
      return
    }

    form.setFieldValue(name, { ...value, isoCode: 'us', countryCode: '1' })
  }, [form, value])

  const uneditableLength = useRef<number>(0)

  const [prevValue, setPrevValue] = React.useState<PhoneNumber | null>(null)

  const getUneditableLength = (value: PhoneNumber) => {
    const [_isoCode, _country, countryCode, mask] =
      getCountry(value?.isoCode as keyof string[][]) ?? []

    let length = value.countryCode?.toString().length ?? 0

    // +1 for the "+" sign
    if (mask.split(' ')[0].length !== countryCode.length + 1) {
      // +2 for the parentheses
      length += (value.areaCode?.length ?? 0) + 2
    }

    return length
  }

  const handleKeyDown: PhoneInputProps['onKeyDown'] = (e) => {
    if (['ArrowLeft', 'ArrowRight'].includes(e.key)) {
      return
    }

    const target = e.target as HTMLInputElement

    if (
      (value && value.isoCode !== 'us') ||
      (e.key === 'Backspace' && target.selectionStart !== target.selectionEnd)
    ) {
      return
    }

    if (
      target.selectionStart! <= uneditableLength.current ||
      (e.key === 'Backspace' &&
        target.selectionStart! <= uneditableLength.current + 1)
    ) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  const handleChange: PhoneInputProps['onChange'] = (value) => {
    if (prevValue?.isoCode !== value.isoCode) {
      uneditableLength.current = getUneditableLength(value)
    }

    setPrevValue(value)
  }

  const handleMount: PhoneInputProps['onMount'] = (value) => {
    if (value) {
      uneditableLength.current = getUneditableLength(value)
    }
  }

  return (
    <Form.Item
      name={name}
      className={'phone-input-form-item'}
      label={label}
      required={required}
      rules={[
        {
          validator: (_, { valid, areaCode, phoneNumber }) => {
            if (!areaCode && !phoneNumber) {
              return required
                ? Promise.reject(new Error(REQUIRED_FIELD_ERROR_MESSAGE))
                : Promise.resolve()
            }

            if (valid(true)) return Promise.resolve()
            return Promise.reject(new Error('Invalid Phone Number'))
          }
        }
      ]}
    >
      <PhoneInput
        country='us'
        onlyCountries={['us']}
        disableDropdown
        distinct
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onMount={handleMount}
        className='phone-input'
        autoComplete='tel'
        {...rest}
      />
    </Form.Item>
  )
}

export default PhoneInputFormItem
