import { FC } from 'react'

import { Rule } from 'antd/es/form'
import { NamePath } from 'antd/es/form/interface'

import { USA_STATE_OPTIONS } from '@/constants/formFieldOptions'

import SelectFormItem from './default-items/select-form-item/SelectFormItem'

interface ISelectStateFormItemProps {
  name: NamePath
  fullName?: NamePath
  label?: string
  rules?: Rule[]
  placeholder?: string
}

const SelectStateFormItem: FC<ISelectStateFormItemProps> = ({
  name,
  fullName,
  rules,
  label = 'State',
  placeholder = 'Select from the list'
}) => {
  return (
    <SelectFormItem
      name={name}
      fullName={fullName}
      label={label}
      placeholder={placeholder}
      options={USA_STATE_OPTIONS}
      rules={
        rules ?? [
          {
            required: true,
            message: 'Please select a State'
          }
        ]
      }
    />
  )
}

export default SelectStateFormItem
