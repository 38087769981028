import { useMutation } from '@tanstack/react-query'

import { TFormSubStep } from '@/types/formSteps'

type TMutateVars = {
  step?: number
  subStep?: TFormSubStep
}

type TMutateFunc = (vars?: TMutateVars) => Promise<void>

const usePatchApplication = (
  next: <T>(
    apiCallback: (data: T) => Promise<void>,
    step?: number,
    subStep?: TFormSubStep
  ) => Promise<void>,

  asyncCallback: (data: any) => Promise<void>
): [TMutateFunc, boolean] => {
  const { mutateAsync, isPending } = useMutation({
    mutationFn: async (vars?: TMutateVars) => {
      await next(asyncCallback, vars?.step, vars?.subStep)
    }
  })

  // useSetOnBackToReviewClick(mutateAsync)

  return [mutateAsync, isPending]
}

export default usePatchApplication
