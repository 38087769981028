import { ReactElement, useCallback, useState } from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import { ConfigProvider, Spin } from 'antd'
import { Outlet } from 'react-router-dom'

import AccessTokenModal from '@/components/access-token-modal/AccessTokenModal'
import { REQUIRED_FIELD_ERROR_MESSAGE } from '@/constants/texts'
import useGetDefaultColorsMap from '@/hooks/useGetDefaultColorsMap'
import useInitializeApp from '@/hooks/useInitializeApp'

const AppLayout = (): ReactElement => {
  const [shouldShowAccessTokenModal, setShouldShowAccessTokenModal] =
    useState(false)

  const toggleAccessTokenModal = useCallback(() => {
    setShouldShowAccessTokenModal((prev) => !prev)
  }, [])

  const isLoading = useInitializeApp(toggleAccessTokenModal)

  const colorsMap = useGetDefaultColorsMap()

  const {
    'brand-primary': brandPrimary,
    'brand-primary-hover': brandPrimaryHover,
    'main-text': mainText,
    error
  } = colorsMap

  return (
    <ConfigProvider
      form={{
        validateMessages: {
          required: REQUIRED_FIELD_ERROR_MESSAGE
        }
      }}
      theme={{
        token: {
          colorPrimary: brandPrimary,
          colorPrimaryHover: brandPrimaryHover,
          colorPrimaryActive: brandPrimaryHover,
          colorPrimaryBgHover: brandPrimaryHover,
          marginLG: 16,
          colorError: error
        },
        components: {
          Typography: {
            colorTextHeading: mainText
          },
          Button: {
            colorPrimary: brandPrimary,
            defaultBorderColor: brandPrimary
          }
        }
      }}
    >
      {isLoading ? (
        <div className='full-screen-block'>
          <Spin indicator={<LoadingOutlined spin />} size='large' />
        </div>
      ) : (
        <Outlet />
      )}

      <AccessTokenModal
        toggleModalOpen={toggleAccessTokenModal}
        open={shouldShowAccessTokenModal}
      />
    </ConfigProvider>
  )
}

export default AppLayout
