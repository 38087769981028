import { FC, useState } from 'react'

import { Flex, Tooltip } from 'antd'

import InfoIcon from '@/components/icons/InfoIcon'

import Close from '../icons/Close'

import './InfoTooltip.scss'

const InfoTooltip: FC = () => {
  const [open, setOpen] = useState(false)

  return (
    <Tooltip
      open={open}
      onOpenChange={(value) => setOpen(value)}
      rootClassName='tooltip-body'
      title={
        <>
          <Flex gap={32}>
            <div>
              <h4>Why does this matter?</h4>
              <p>
                This information is needed to assess your financial stability
                and ability to meet obligations. Details about other businesses
                help provide a clearer picture of your ownership and potential
                risks.
              </p>
            </div>
            <Close onClick={() => setOpen(false)} />
          </Flex>
        </>
      }
      placement='top'
      trigger={['click']}
    >
      <div style={{ display: 'flex' }}>
        <InfoIcon />
      </div>
    </Tooltip>
  )
}

export default InfoTooltip
