import { FC } from 'react'

import { InputProps } from 'antd'
import { NamePath } from 'antd/es/form/interface'

import InputFormItem from './default-items/input-form-item/InputFormItem'

interface ILastNameFormItemProps extends InputProps {
  name: NamePath
}

const LastNameFormItem: FC<ILastNameFormItemProps> = ({ name, ...rest }) => {
  return (
    <InputFormItem
      name={name}
      autoComplete='family-name'
      label='Last Name'
      placeholder='Last Name'
      rules={[
        {
          required: true,
          whitespace: true
        },
      ]}
      {...rest}
    />
  )
}

export default LastNameFormItem
