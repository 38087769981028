import { FC } from 'react'

import useIsDesktop from '@/hooks/useIsDesktop'

const withDeviceDependent = <P extends object>(
  WrappedComponent: FC<P>,
  withDesktop: boolean // true === show on desktop (width >= 1440)
) => {
  return (props: P) => {
    const isDesktop = useIsDesktop()

    return isDesktop === withDesktop ? <WrappedComponent {...props} /> : null
  }
}

export default withDeviceDependent
