import { FC } from 'react'

import { Rule } from 'antd/es/form'
import { NamePath } from 'antd/es/form/interface'

import { BUSINESS_ENTITY_OPTIONS } from '@/constants/formFieldOptions'

import SelectFormItem from './default-items/select-form-item/SelectFormItem'

interface IBusinessEntityFormItemProps {
  name: NamePath
  rules?: Rule[]
}

const BusinessEntityFormItem: FC<IBusinessEntityFormItemProps> = ({
  name,
  rules
}) => {
  return (
    <SelectFormItem
      name={name}
      label='Entity Type'
      placeholder='Select from the list'
      options={BUSINESS_ENTITY_OPTIONS}
      rules={
        rules ?? [
          {
            required: true,
            message: 'Please select a Business Entity'
          }
        ]
      }
    />
  )
}

export default BusinessEntityFormItem
