import { FC } from 'react'

import { Col, RadioChangeEvent, Row } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'

import { patchApplication } from '@/api/app.service'
import BusinessAsDBAFormItem from '@/components/form/form-items/BusinessAsDBAFormItem'
import BusinessEinFormItem from '@/components/form/form-items/BusinessEinFormItem'
import BusinessEntityFormItem from '@/components/form/form-items/BusinessEntityFormItem'
import BusinessNameFormItem from '@/components/form/form-items/BusinessNameFormItem'
import CityFormItem from '@/components/form/form-items/CityFormItem'
import EmployeesNumberFormItem from '@/components/form/form-items/EmployeesNumberFormItem'
import IndustryFormItem from '@/components/form/form-items/IndustryFormItem'
import SelectStateFormItem from '@/components/form/form-items/SelectStateFormItem'
import ZipCodeFormItem from '@/components/form/form-items/ZipCodeFormItem'
import AddressFormItem from '@/components/form/form-items/address-form-item/AddressFormItem'
import BusinessActivityCode from '@/components/form/form-items/business-activity-code/BusinessActivityCode'
import CheckboxFormItem from '@/components/form/form-items/default-items/checkbox-form-item/CheckboxFormItem'
import DatePickerFormItem from '@/components/form/form-items/default-items/date-picker-form-item/DatePickerFormItem'
import InputFormItem from '@/components/form/form-items/default-items/input-form-item'
import RadioFormItem from '@/components/form/form-items/default-items/radio-form-item/RadioFormItem'
import PhoneInputFormItem from '@/components/form/form-items/phone-input-form-item/PhoneInputFormItem'
import NavigationControls from '@/components/navigation-controls/NavigationControls'
import {
  ADDRESS_FIELD_NAMES_MAP,
  FORM_FIELD_NAMES_MAP,
  LOCAL_TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP,
  TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP
} from '@/constants/formFieldNames'
import { YES_OR_NO_TYPE_OPTIONS } from '@/constants/formFieldOptions'
import { useTypedSelector } from '@/hooks/redux'
import useCustomWatch from '@/hooks/useCustomWatch'
import usePatchApplication from '@/hooks/usePatchApplication'
import useSetPrefilledData from '@/hooks/useSetPrefilledData'
import { IStepProps } from '@/types/types'
import getFormattedPhoneNumber from '@/utils/getFormattedPhoneNumber'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import './BusinessMainInfo.scss'

const { targetBusinessOverview } = FORM_FIELD_NAMES_MAP

const {
  isDBA,
  legalAddress,
  physicalAddress,
  businessEntityType,
  legalAddressSameAsPhysical,
  name,
  dbaName,
  phoneNumber,
  employeesCount,
  isFranchise,
  franchiseName,
  formationState,
  businessStartedAt,
  businessEIN,
  businessActivityCode,
  industryType
} = TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP

const { city, zipCode, street, usaStateType } = ADDRESS_FIELD_NAMES_MAP

const StartBusinessInfo: FC<IStepProps> = ({ form, next, prev }) => {
  const applicationId = useTypedSelector(
    ({ formState }) => formState.applicationId
  )

  useSetPrefilledData(form, [name, isDBA, legalAddress], targetBusinessOverview)

  const [patch, isLoading] = usePatchApplication(
    next,
    async ({ targetBusinessOverview: businessOverviewValue }) => {
      let updatedBusinessOverviewValue = businessOverviewValue

      if (businessOverviewValue.legalAddressSameAsPhysical) {
        form.setFieldValue(
          [targetBusinessOverview, physicalAddress],
          businessOverviewValue.legalAddress
        )
      }

      updatedBusinessOverviewValue = [legalAddress, physicalAddress].reduce(
        (acc, addressType) => {
          if (
            acc[addressType] &&
            Object.entries(acc[addressType]).some(([_, value]) => !!value)
          ) {
            return acc
          }

          acc[addressType] = undefined
          return acc
        },
        { ...updatedBusinessOverviewValue }
      )

      updatedBusinessOverviewValue =
        businessOverviewValue.legalAddressSameAsPhysical
          ? {
              ...businessOverviewValue,
              physicalAddress: businessOverviewValue.legalAddress
            }
          : businessOverviewValue

      await tryCatchWithMessage(async () => {
        await patchApplication(applicationId, [
          {
            path: '/targetBusinessOverview',
            value: {
              ...updatedBusinessOverviewValue,
              businessEIN: updatedBusinessOverviewValue.businessEIN?.replace(
                /-/g,
                ''
              ),
              phoneNumber: getFormattedPhoneNumber(
                updatedBusinessOverviewValue.phoneNumber
              )
            }
          }
        ])
      })
    }
  )

  const hasBusinessNameValue = useCustomWatch(
    LOCAL_TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP.hasBusinessName,
    form
  )
  const isFranchiseValue = useCustomWatch(
    [targetBusinessOverview, isFranchise],
    form
  )
  const isAddressUnknownValue = useCustomWatch(
    LOCAL_TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP.isAddressUnknown,
    form
  )
  const legalAddressSameAsPhysicalValue = useCustomWatch(
    [targetBusinessOverview, legalAddressSameAsPhysical],
    form
  )

  const isOnlyOneEmployeeValue = useCustomWatch(
    LOCAL_TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP.isOnlyOneEmployee,
    form
  )

  const withRadioRestoreValue =
    (callback: () => void, triggerValue = false) =>
    (e: RadioChangeEvent | CheckboxChangeEvent) => {
      if (
        e.target.value === triggerValue ||
        e.target.checked === triggerValue
      ) {
        callback()
      }
    }

  return (
    <div className={'business-info'}>
      <h2 className={'business-info__title'}>Business Information</h2>
      <p className={'business-info__subtitle'}>
        Please provide these details about your business:
      </p>
      <Row gutter={16}>
        <Col span={24}>
          <RadioFormItem
            label='Do you have a name for your new business?'
            rules={[{ required: false }]}
            name={
              LOCAL_TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP.hasBusinessName
            }
            options={YES_OR_NO_TYPE_OPTIONS}
            onChange={withRadioRestoreValue(() => {
              form.setFieldValue([targetBusinessOverview, isDBA], false)
              form.setFieldValue([targetBusinessOverview, dbaName], undefined)
              form.setFieldValue([targetBusinessOverview, name], undefined)
            })}
          />
        </Col>
        {hasBusinessNameValue && (
          <Col span={24}>
            <BusinessNameFormItem
              rules={[{ required: false }]}
              name={[targetBusinessOverview, name]}
            />
            <BusinessAsDBAFormItem
              dbaNameItemName={[targetBusinessOverview, dbaName]}
              isDbaItemName={[targetBusinessOverview, isDBA]}
              required={false}
            />
          </Col>
        )}
        <Col span={24}>
          <RadioFormItem
            label='Is it a franchise?'
            rules={[{ required: false }]}
            name={[targetBusinessOverview, isFranchise]}
            options={YES_OR_NO_TYPE_OPTIONS}
            onChange={withRadioRestoreValue(() =>
              form.setFieldValue(
                [targetBusinessOverview, franchiseName],
                undefined
              )
            )}
          />
          {isFranchiseValue && (
            <InputFormItem
              rules={[{ required: false }]}
              label='Name of Franchise'
              placeholder='Enter Name'
              name={[targetBusinessOverview, franchiseName]}
            />
          )}
        </Col>
        <Col span={24}>
          <EmployeesNumberFormItem
            rules={[{ required: false }]}
            name={[targetBusinessOverview, employeesCount]}
            disabled={isOnlyOneEmployeeValue}
          />
        </Col>
        <CheckboxFormItem
          name={
            LOCAL_TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP.isOnlyOneEmployee
          }
          required={false}
          className='business-info__checkbox'
          onChange={(e) =>
            e.target.checked &&
            form.setFieldValue([targetBusinessOverview, employeesCount], 1)
          }
        >
          I am the only employee
        </CheckboxFormItem>
        <Col span={24}>
          <BusinessEntityFormItem
            rules={[{ required: false }]}
            name={[targetBusinessOverview, businessEntityType]}
          />
        </Col>
        <Col span={24}>
          <SelectStateFormItem
            rules={[{ required: false }]}
            name={[targetBusinessOverview, formationState]}
          />
        </Col>
        <Col span={24}>
          <DatePickerFormItem
            rules={[{ required: false }]}
            name={[targetBusinessOverview, businessStartedAt]}
            label='Date of Formation'
          />
        </Col>
        <Col span={24}>
          <BusinessEinFormItem
            required={false}
            name={[targetBusinessOverview, businessEIN]}
          />
        </Col>
        <Col span={24}>
          <BusinessActivityCode
            rules={[{ required: false }]}
            name={[targetBusinessOverview, businessActivityCode]}
          />
        </Col>
        <Col span={24}>
          <IndustryFormItem
            rules={[{ required: false }]}
            name={[targetBusinessOverview, industryType]}
          />
        </Col>
        <Col span={24}>
          <PhoneInputFormItem
            label='Business Phone Number'
            rules={[{ required: false }]}
            name={[targetBusinessOverview, phoneNumber]}
            required={false}
          />
        </Col>
        <Col span={24} style={{ marginTop: '24px' }}>
          <CheckboxFormItem
            name={
              LOCAL_TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP.isAddressUnknown
            }
            rules={[{ required: false }]}
            onChange={withRadioRestoreValue(() => {
              form.setFieldValue(
                [targetBusinessOverview, physicalAddress],
                undefined
              )
              form.setFieldValue(
                [targetBusinessOverview, legalAddressSameAsPhysical],
                undefined
              )
            })}
          >
            The address is unknown
          </CheckboxFormItem>
        </Col>
        {!(isAddressUnknownValue ?? false) && (
          <>
            <Col span={24}>
              <AddressFormItem
                label='Business Address'
                name={[targetBusinessOverview, legalAddress, street]}
                rules={[
                  {
                    required: false
                  }
                ]}
              />
            </Col>
            <Col xs={24} md={8}>
              <CityFormItem
                name={[targetBusinessOverview, legalAddress, city]}
                rules={[
                  {
                    required: false
                  }
                ]}
              />
            </Col>
            <Col xs={24} md={8}>
              <SelectStateFormItem
                placeholder='Select'
                name={[targetBusinessOverview, legalAddress, usaStateType]}
                rules={[
                  {
                    required: false
                  }
                ]}
              />
            </Col>
            <Col xs={24} md={8}>
              <ZipCodeFormItem
                name={[targetBusinessOverview, legalAddress, zipCode]}
                rules={[
                  {
                    required: false
                  }
                ]}
              />
            </Col>
            <Col span={24}>
              <RadioFormItem
                label='Is the mailing address the same as the business address?'
                name={[targetBusinessOverview, legalAddressSameAsPhysical]}
                options={YES_OR_NO_TYPE_OPTIONS}
                rules={[{ required: false }]}
                onChange={withRadioRestoreValue(() => {
                  form.setFieldValue(
                    [targetBusinessOverview, physicalAddress],
                    undefined
                  )
                }, true)}
              />
            </Col>
            {!(legalAddressSameAsPhysicalValue ?? true) && (
              <>
                <Col span={24}>
                  <AddressFormItem
                    label='Mailing Address'
                    name={[targetBusinessOverview, physicalAddress, street]}
                    rules={[
                      {
                        required: false
                      }
                    ]}
                  />
                </Col>
                <Col xs={24} md={8}>
                  <CityFormItem
                    name={[targetBusinessOverview, physicalAddress, city]}
                    rules={[
                      {
                        required: false
                      }
                    ]}
                  />
                </Col>
                <Col xs={24} md={8}>
                  <SelectStateFormItem
                    placeholder='Select'
                    name={[
                      targetBusinessOverview,
                      physicalAddress,
                      usaStateType
                    ]}
                    rules={[
                      {
                        required: false
                      }
                    ]}
                  />
                </Col>
                <Col xs={24} md={8}>
                  <ZipCodeFormItem
                    name={[targetBusinessOverview, physicalAddress, zipCode]}
                    rules={[
                      {
                        required: false
                      }
                    ]}
                  />
                </Col>
              </>
            )}
          </>
        )}
      </Row>

      <NavigationControls
        isLoading={isLoading}
        onNext={() => {
          patch()
        }}
        onPrev={() => prev()}
      />
    </div>
  )
}

export default StartBusinessInfo
