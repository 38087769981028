import React, {
  ChangeEventHandler,
  FC,
  useEffect,
  useRef,
  useState
} from 'react'

import { Form, Input, InputProps, InputRef } from 'antd'
import { Rule } from 'antd/es/form'
import { NamePath } from 'antd/es/form/interface'

import SlashedEye from '@/components/icons/SlashedEye'

const insertString = (
  originalStr: string,
  insertStr: string,
  index: number
): string => {
  if (!originalStr) {
    return insertStr
  }

  return originalStr.slice(0, index) + insertStr + originalStr.slice(index)
}

const removeSubstring = (
  originalStr: string,
  start: number,
  length: number
): string => {
  return originalStr.slice(0, start) + originalStr.slice(start + length)
}

interface ISecureFormItemProps extends InputProps {
  name: NamePath
  fullName: NamePath
  label?: string
  rules: Rule[]
  validator: (value: string) => Promise<void>
  formatRawValue: (
    value: string,
    partFormat?: (part: string) => string
  ) => string
}

const SecureFormItem: FC<ISecureFormItemProps> = ({
  formatRawValue,
  validator,
  label,
  rules,
  name,
  fullName,
  ...rest
}) => {
  const form = Form.useFormInstance()

  const [isValueShown, setIsValueShown] = useState(false)
  const [realValue, setRealValue] = useState('')
  const [displayValue, setDisplayValue] = useState(
    '*'.repeat(realValue?.length)
  )

  const selectionRef = useRef({ start: 0, end: 0 })

  useEffect(() => {
    const initValue = form.getFieldValue(fullName ?? name)

    initValue && setRealValue(formatRawValue(initValue))
    initValue && form.validateFields([fullName ?? name])
  }, [fullName, name])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplayValue(displayMaskedValue(realValue, false))
      setTimeout(() => !isValueShown && restoreCursorPosition(), 0)
    }, 500)

    return () => {
      clearTimeout(timeout)
    }
  }, [realValue, isValueShown])

  const getNewValue = (value: string) => {
    if (isValueShown) {
      return { newValue: value, shouldShowLastDigit: true }
    }

    let newValue
    let shouldShowLastDigit

    if (value.length >= realValue.length) {
      const addedValueLength = realValue.length - value.length
      const addedValue = value
        .replace(/[-]/g, '*')
        .replace(/[*]/g, '')
        .slice(addedValueLength)

      newValue = insertString(
        realValue,
        addedValue,
        selectionRef.current.start - 1
      )
      shouldShowLastDigit = !isNaN(Number(addedValue))
    } else {
      const removedValueLength = realValue.length - value.length

      const startIndex = selectionRef.current.start

      newValue = !value
        ? ''
        : removeSubstring(realValue, startIndex, removedValueLength)

      shouldShowLastDigit = false
    }

    return { newValue, shouldShowLastDigit }
  }

  const handleValueFromEvent: ChangeEventHandler<HTMLInputElement> = ({
    target
  }) => {
    if (target.selectionDirection === 'backward') {
      selectionRef.current = {
        start: target.selectionEnd!,
        end: target.selectionStart!
      }
    } else {
      selectionRef.current = {
        start: target.selectionStart!,
        end: target.selectionEnd!
      }
    }

    const { newValue, shouldShowLastDigit } = getNewValue(target.value)

    const formattedValue = formatRawValue(newValue)

    setRealValue(formattedValue)
    setDisplayValue(displayMaskedValue(formattedValue, shouldShowLastDigit))

    return formattedValue
  }

  const displayMaskedValue = (value: string, showLastDigit: boolean) => {
    if (!value) return ''

    const masked = formatRawValue(value, (part) => '*'.repeat(part.length))

    if (!showLastDigit) {
      return masked
    }

    const digitIndex =
      value.slice(
        selectionRef.current.start - 1,
        selectionRef.current.start
      ) === '-'
        ? selectionRef.current.start
        : selectionRef.current.start - 1

    let withLastDigit = removeSubstring(masked, digitIndex, 1)
    withLastDigit = insertString(
      withLastDigit,
      value.slice(digitIndex, digitIndex + 1),
      digitIndex
    )

    return withLastDigit
  }

  const toggleShowValue = () => setIsValueShown((prev) => !prev)

  const inputRef = useRef<InputRef>(null)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectionLength = e.target.selectionEnd! - e.target.selectionStart!
    const formattedValue = formatRawValue(getNewValue(e.target.value).newValue)

    const digitIndex =
      formattedValue.slice(
        e.target.selectionStart! - 1,
        e.target.selectionStart!
      ) === '-'
        ? e.target.selectionStart! + 1
        : e.target.selectionStart

    selectionRef.current = {
      start: digitIndex!,
      end: digitIndex! + selectionLength
    }
    setTimeout(restoreCursorPosition, 0)
  }

  const restoreCursorPosition = () => {
    inputRef.current?.setSelectionRange(
      selectionRef.current.start,
      selectionRef.current.end
    )
  }

  return (
    <Form.Item
      getValueProps={() => ({
        value: isValueShown ? realValue : displayValue
      })}
      className={'input'}
      name={name}
      label={label}
      style={{ width: '100%' }}
      getValueFromEvent={handleValueFromEvent}
      rules={[
        ...rules,
        {
          validator: (_, value) => validator(value)
        }
      ]}
    >
      <Input
        onChange={handleInputChange}
        ref={inputRef}
        suffix={
          <SlashedEye isSlashed={!isValueShown} onClick={toggleShowValue} />
        }
        {...rest}
      />
    </Form.Item>
  )
}

export default SecureFormItem
