import { PhoneNumber } from 'antd-phone-input'

import { OwnerDto } from '@/generated-types'

enum EUsaState {
  AL = 'al',
  AK = 'ak',
  AZ = 'az',
  AR = 'ar',
  CA = 'ca',
  CO = 'co',
  CT = 'ct',
  DE = 'de',
  DC = 'dc',
  FL = 'fl',
  GA = 'ga',
  HI = 'hi',
  ID = 'id',
  IL = 'il',
  IN = 'in',
  IA = 'ia',
  KS = 'ks',
  KY = 'ky',
  LA = 'la',
  ME = 'me',
  MD = 'md',
  MA = 'ma',
  MI = 'mi',
  MN = 'mn',
  MS = 'ms',
  MO = 'mo',
  MT = 'mt',
  NE = 'ne',
  NV = 'nv',
  NH = 'nh',
  NJ = 'nj',
  NM = 'nm',
  NY = 'ny',
  NC = 'nc',
  ND = 'nd',
  OH = 'oh',
  OK = 'ok',
  OR = 'or',
  PA = 'pa',
  PR = 'pr',
  RI = 'ri',
  SC = 'sc',
  SD = 'sd',
  TN = 'tn',
  TX = 'tx',
  UT = 'ut',
  VT = 'vt',
  VA = 'va',
  WA = 'wa',
  WV = 'wv',
  WI = 'wi',
  WY = 'wy'
}

enum EBusinessEntity {
  SCorp = 's_corp',
  CCorp = 'c_corp',
  LLC = 'llc',
  SoleProprietor = 'sole_proprietor'
}

enum ETimeframeType {
  immediately = 'immediately',
  TwoForWeeks = 'two_four_weeks',
  FourWeeksPlus = 'four_weeks_plus'
}

enum EAnnualRevenueType {
  PreRevenue = 'pre_revenue',
  RevenueBelow100k = 'revenue_below_100k',
  Revenue100k250k = 'revenue_100k_250k',
  Revenue250k500k = 'revenue_250k_500k',
  Revenue500k1m = 'revenue_500k_1m',
  Revenue1m2m = 'revenue_1m_2m',
  Revenue2m5m = 'revenue_2m_5m',
  RevenueAbove5m = 'revenue_above_5m'
}

enum EIndustryType {
  Agriculture = 'agriculture',
  Mining = 'mining',
  Utilities = 'utilities',
  Construction = 'construction',
  Manufacturing = 'manufacturing',
  WholesaleTrade = 'wholesale_trade',
  RetailTrade = 'retail_trade',
  Transportation = 'transportation',
  Information = 'information',
  FinanceAndInsurance = 'finance_and_insurance',
  RealEstateRentalAndLeasing = 'real_estate_rental_and_leasing',
  Services = 'services',
  Management = 'management',
  AdministrativeAndSupport = 'administrative_and_support',
  EducationalServices = 'educational_services',
  HealthCare = 'health_care',
  ArtsAndEntertainment = 'arts_and_entertainment',
  AccommodationAndFoodServices = 'accommodation_and_food_services',
  PublicAdministration = 'public_administration',
  OtherServices = 'other_services'
}

enum EFicoScoreType {
  Excellent = 'excellent',
  Good = 'good',
  Average = 'average',
  Fair = 'fair',
  Poor = 'poor',
  VeryPoor = 'very_poor'
}

enum EFundingPurposeType {
  BuyBusiness = 'buy_business',
  StartBusiness = 'start_business',
  BuyCommercialRealEstate = 'buy_commercial_real_estate',
  BusinessExpansion = 'business_expansion',
  CashCrunch = 'cash_crunch',
  DebtRefinancing = 'debt_refinancing',
  EquipmentPurchase = 'equipment_purchase',
  InventoryPurchase = 'inventory_purchase',
  MarketingCampaign = 'marketing_campaign',
  MeetPayroll = 'meet_payroll',
  Renovations = 'renovations',
  WorkingCapital = 'working_capital',
  Other = 'other'
}

enum EMaritalStatusType {
  Married = 'married',
  Unmarried = 'unmarried',
  Separated = 'separated'
}

enum ERealEstateType {
  Existing = 'existing',
  New = 'new'
}

enum ECitizenshipStatus {
  USCitizen = 'us_citizen',
  LawfulPermanentResident = 'lawful_permanent_resident',
  Other = 'other'
}

export type TFormSelectOption = Record<string, string>

export type TFormOwnerField = Omit<OwnerDto, 'phoneNumber'> & {
  phoneNumber: PhoneNumber | string
}

export {
  EUsaState,
  EBusinessEntity,
  ETimeframeType,
  EIndustryType,
  EAnnualRevenueType,
  EFicoScoreType,
  EFundingPurposeType,
  EMaritalStatusType,
  ERealEstateType,
  ECitizenshipStatus
}
