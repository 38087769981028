import { ReactElement } from 'react'

import { Col, Form, FormInstance, InputNumber, Row } from 'antd'

import CityFormItem from '@/components/form/form-items/CityFormItem'
import FirstNameFormItem from '@/components/form/form-items/FirstNameFormItem'
import LastNameFormItem from '@/components/form/form-items/LastNameFormItem'
import SelectStateFormItem from '@/components/form/form-items/SelectStateFormItem'
import ZipCodeFormItem from '@/components/form/form-items/ZipCodeFormItem'
import AddressFormItem from '@/components/form/form-items/address-form-item/AddressFormItem'
import DatePickerFormItem from '@/components/form/form-items/default-items/date-picker-form-item/DatePickerFormItem'
import SelectFormItem from '@/components/form/form-items/default-items/select-form-item/SelectFormItem'
import EmailInputFormItem from '@/components/form/form-items/email-input-form-item/EmailInputFormItem'
import PhoneInputFormItem from '@/components/form/form-items/phone-input-form-item/PhoneInputFormItem'
import SSNFormItem from '@/components/steps/business-owner-info/SSNFormItem'
import {
  ADDRESS_FIELD_NAMES_MAP,
  FORM_FIELD_NAMES_MAP,
  OWNER_INFO_FIELD_NAMES_MAP
} from '@/constants/formFieldNames'
import { MARTIAL_STATUS_OPTIONS } from '@/constants/formFieldOptions'
import { REQUIRED_FIELD_SHORT_ERROR_MESSAGE } from '@/constants/texts'
import useIsSoleProprietor from '@/hooks/uesIsSoleProprietor'
import useSetPrefilledData from '@/hooks/useSetPrefilledData'
import percentIcon from '@/images/icons/percentage-icon.svg'

type TPrimaryInfoFieldsProps = {
  name: number
  currentOwnershipPercentage: number
  ownershipPercentage: number
  idx: number
  form: FormInstance
}

const {
  firstName,
  lastName,
  email,
  phoneNumber,
  birthDate,
  businessWeight,
  address,
  socialSecurityNumber,
  maritalStatusType
} = OWNER_INFO_FIELD_NAMES_MAP

const { owners: ownersFieldName } = FORM_FIELD_NAMES_MAP

const { city, zipCode, usaStateType, street } = ADDRESS_FIELD_NAMES_MAP

const PrimaryInfoFields = ({
  name,
  currentOwnershipPercentage,
  ownershipPercentage,
  idx,
  form
}: TPrimaryInfoFieldsProps): ReactElement => {
  const isPrimaryOwner = idx === 0

  const isSoleProprietor = useIsSoleProprietor()

  useSetPrefilledData(
    form,
    [
      firstName,
      lastName,
      email,
      birthDate,
      businessWeight,
      address,
      socialSecurityNumber,
      maritalStatusType
    ],
    'owners',
    idx
  )

  return (
    <Row gutter={16}>
      <Col span={24}>
        <FirstNameFormItem name={[name, firstName]} />
      </Col>
      <Col span={24}>
        <LastNameFormItem name={[name, lastName]} />
      </Col>
      {!isSoleProprietor && (
        <Col span={24}>
          <Form.Item
            name={[name, businessWeight]}
            label='Ownership Percentage'
            rules={[
              {
                required: true,
                type: 'number',
                message: 'Total ownership percentage should be 100%',
                min: 1,
                max: getMaxOwnershipPercentageValidation(
                  ownershipPercentage,
                  currentOwnershipPercentage
                )
              }
            ]}
            className={'ownership__input'}
            style={{ width: '100%' }}
          >
            <InputNumber
              type='number'
              min={1}
              max={100}
              placeholder='Enter Percentage'
              suffix={
                <img src={percentIcon} alt={'Percent'} width={18} height={18} />
              }
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      )}
      <Col span={24}>
        <EmailInputFormItem name={[name, email]} />
      </Col>
      {!isPrimaryOwner && (
          <Col md={12} xs={24}>
            <PhoneInputFormItem
              name={[name, phoneNumber]}
              fullName={[ownersFieldName, name, phoneNumber]}
              label='Mobile Phone Number'
              className={'ownership__input-with-prefix ownership__field-phone'}
            />
          </Col>
      )}
      <Col {...(!isPrimaryOwner ? { md: 12, xs: 24 } : { span: 24 })}>
        <DatePickerFormItem
          rules={[{ required: isPrimaryOwner }]}
          name={[name, birthDate]}
          label='Date of Birth'
        />
      </Col>
      <Col span={24}>
        <AddressFormItem
          rules={[{ required: isPrimaryOwner }]}
          label='Home Address'
          name={[name, address, street]}
        />
      </Col>

      <Col md={8} xs={24}>
        <CityFormItem
          rules={[
            {
              required: isPrimaryOwner,
              message: REQUIRED_FIELD_SHORT_ERROR_MESSAGE
            }
          ]}
          name={[name, address, city]}
        />
      </Col>

      <Col md={8} xs={24}>
        <SelectStateFormItem
          rules={[
            {
              required: isPrimaryOwner,
              message: REQUIRED_FIELD_SHORT_ERROR_MESSAGE
            }
          ]}
          placeholder='Select'
          name={[name, address, usaStateType]}
          fullName={[ownersFieldName, name, address, usaStateType]}
        />
      </Col>

      <Col md={8} xs={24}>
        <ZipCodeFormItem
          rules={[
            {
              required: isPrimaryOwner,
              message: REQUIRED_FIELD_SHORT_ERROR_MESSAGE
            }
          ]}
          name={[name, address, zipCode]}
        />
      </Col>

      <Col span={24}>
        <SSNFormItem
          fullName={[ownersFieldName, name, socialSecurityNumber]}
          name={[name, socialSecurityNumber]}
          isPrimaryOwner={isPrimaryOwner}
        />
      </Col>
      <Col span={24}>
        <SelectFormItem
          rules={[{ required: false }]}
          label='What is your current marital status?'
          name={[name, maritalStatusType]}
          fullName={[ownersFieldName, name, maritalStatusType]}
          options={MARTIAL_STATUS_OPTIONS}
        />
      </Col>
    </Row>
  )
}

const getMaxOwnershipPercentageValidation = (
  totalPercentage: number,
  currentPercentage = 0
) => 100 - (totalPercentage - currentPercentage)

export default PrimaryInfoFields
