import React, { useEffect, useState } from 'react'

import { Button, Modal } from 'antd'

import FormUploadInput from '@/components/form/form-upload-input/FormUploadInput'
import Close from '@/components/icons/Close'
import NavigationControls from '@/components/navigation-controls/NavigationControls'
import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import { useTypedDispatch, useTypedSelector } from '@/hooks/redux'
import useCustomWatch from '@/hooks/useCustomWatch'
import useImmediateFileUpload from '@/hooks/useImmediateFileUpload'
import {
  setIsAgreedPrefill,
  setIsAtLeastOneDocumentUploaded
} from '@/redux/features/formStateSlice'
import { changeStep } from '@/redux/features/timeLineSlice'
import { IStepProps } from '@/types/types'

import './UploadFiles.scss'

const { businessTaxReturn, personalTaxReturn, governmentIdentity } =
  FORM_FIELD_NAMES_MAP

const UploadFiles: React.FC<IStepProps> = ({ form, prev }) => {
  const dispatch = useTypedDispatch()

  const [showLoading, setShowLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const { applicationId } = useTypedSelector(({ formState }) => ({
    applicationId: formState.applicationId
  }))

  const businessTaxFile = useCustomWatch(businessTaxReturn, form)
  const personalTaxFile = useCustomWatch(personalTaxReturn, form)
  const governmentIdentityFile = useCustomWatch(governmentIdentity, form)

  const { isLoading: businessTaxFileLoading } = useImmediateFileUpload(
    businessTaxFile,
    '/businessTaxReturn',
    applicationId
  )
  const { isLoading: personalTaxFileLoading } = useImmediateFileUpload(
    personalTaxFile,
    '/personalTaxReturn',
    applicationId
  )
  const { isLoading: governmentIdentityFileLoading } = useImmediateFileUpload(
    governmentIdentityFile,
    '/governmentIdentity',
    applicationId
  )

  const isFilesLoading =
    businessTaxFileLoading ||
    personalTaxFileLoading ||
    governmentIdentityFileLoading

  const atLeastOneFileAdded =
    !!businessTaxFile?.length ||
    !!personalTaxFile?.length ||
    !!governmentIdentityFile?.length

  const handleNext = (isSkipLoading: boolean) => {
    if (isSkipLoading) {
      setOpenModal(true)
    } else {
      setShowLoading(true)
      dispatch(setIsAgreedPrefill(true))
      dispatch(setIsAtLeastOneDocumentUploaded(true))
    }
  }

  useEffect(() => {
    if (showLoading && !isFilesLoading) {
      dispatch(changeStep({ subStep: 'ai-loading' }))
    }
  }, [showLoading, isFilesLoading])

  const uploadList = [
    {
      title: 'Business tax return​',
      subtitle: 'Please upload your most recently filed business tax return.',
      name: businessTaxReturn,
      fileList: businessTaxFile
    },
    {
      title: 'Personal tax return​',
      subtitle: 'Please upload your most recently filed personal tax return.',
      name: personalTaxReturn,
      fileList: personalTaxFile
    },
    {
      title: 'Government-issued identification​',
      subtitle: "Please upload your Driver's License or Passport.​",
      name: governmentIdentity,
      fileList: governmentIdentityFile
    }
  ]

  return (
    <div className={'save-time-ai'}>
      <div className={'save-time-ai__info'}>
        <h2 className={'save-time-ai__title'}>Upload Required Documents</h2>
        <p className={'save-time-ai__subtitle'}>
          To submit your loan for review,{' '}
          <b>tax returns and a government-issued ID</b> are required.
          <br /> <br />
          Uploading your documents now will <b>save you time</b> — our system
          will automatically pre-fill the remaining questions for you.
        </p>
        {uploadList.map(({ title, subtitle, name, fileList }) => (
          <div key={name} className={'upload-file'}>
            <span className={'upload-file__title'}>{title}</span>
            <span className={'upload-file__subtitle'}>{subtitle}</span>
            <FormUploadInput
              className={'upload-file__upload-wrapper'}
              name={name}
              fileList={fileList}
            />
          </div>
        ))}
        <NavigationControls
          isLoading={showLoading && isFilesLoading}
          onNext={() => handleNext(!atLeastOneFileAdded)}
          onPrev={() => prev?.()}
          nextBtnText={atLeastOneFileAdded ? 'Next' : 'Skip'}
        />
        <Modal
          open={openModal}
          onCancel={() => setOpenModal(false)}
          className='save-time-ai__modal'
          centered
          width={'100%'}
          closeIcon={<Close />}
          footer={
            <>
              <Button
                type={'default'}
                className='cancel-btn'
                onClick={() => {
                  setOpenModal(false)
                  dispatch(setIsAgreedPrefill(false))
                  dispatch(changeStep({ direction: 'forward' }))
                  window.scroll({ top: 0, behavior: 'smooth' })
                }}
              >
                Provide Later
              </Button>
              <Button
                type={'primary'}
                variant='outlined'
                className='confirm-btn'
                onClick={() => {
                  setOpenModal(false)
                }}
              >
                Upload Now
              </Button>
            </>
          }
        >
          If you skip this step, you'll still need to provide these documents
          later to complete your application.
        </Modal>
      </div>
    </div>
  )
}

export default UploadFiles
