import { Form } from 'antd'

import TimeLine from '@/components/time-line/TimeLine'
import { useTypedDispatch, useTypedSelector } from '@/hooks/redux'
import useCurrentNavItem from '@/hooks/useCurrentNavItem'
import useFormSteps from '@/hooks/useFormSteps'
import useTimeline from '@/hooks/useTimeline'
import { changeStep, setStepMessage } from '@/redux/features/timeLineSlice'
import { TFormSubStep } from '@/types/formSteps'

import AutoAnimateContainer from '../auto-animate-container/AutoAnimateContainer'
import DesktopSidebar from '../desktop-sidebar/DesktopSidebar'
import FormFooter from '../form-footer/FormFooter'
import FormMobileHeader from '../form-mobile-header/FormMobileHeader'
import Logo from '../logo/Logo'
import StepMessageFactory from '../step-message'
import { DesktopStepProgress, MobileStepProgress } from '../step-progress'
import SubStepWrapper from '../sub-step-wrapper/SubStepWrapper'
import VideoGuide from '../video-guide/VideoGuide'

import './MultiStepForm.scss'

const MultiStepForm = () => {
  const [form] = Form.useForm()
  const { currentStep, formSteps, currentSubStep, stepMessage } =
    useTypedSelector(({ timeLine }) => ({
      currentStep: timeLine.formStep,
      currentSubStep: timeLine.formSubStep,
      formSteps: timeLine.formSteps,
      stepMessage: timeLine.stepMessage
    }))

  const dispatch = useTypedDispatch()

  useCurrentNavItem()
  useTimeline(form)

  const withHideStepMessage = <T extends (...args: any[]) => any>(fn: T) => {
    return ((...args: Parameters<T>) => {
      if (stepMessage?.stepIndex === currentStep) {
        // hide message after 500ms to prevent flickering when changing steps
        setTimeout(() => dispatch(setStepMessage(null)), 500)
      }

      return fn(...args)
    }) as T
  }

  const next = withHideStepMessage(
    async (
      apiCallback: <T>(data: T) => Promise<void>,
      step?: number,
      subStep?: TFormSubStep
    ): Promise<void> => {
      const onStepChange = () => {
        window?.scroll({ top: 0, behavior: 'smooth' })
        dispatch(
          changeStep({
            currentStep: step,
            subStep: subStep,
            direction: 'forward'
          })
        )
      }

      await form
        .validateFields()
        .then(async (data) => {
          await apiCallback(data)
        })
        .then(onStepChange)
        .catch((e) => {
          if (!e?.errorFields?.length) {
            onStepChange()
          }

          // throw new Error(e?.response?.data?.message || e.message || 'Error!')
        })
    }
  )

  const prev = withHideStepMessage(() => {
    setTimeout(() => {
      window?.scroll({ top: 0, behavior: 'smooth' })
      dispatch(changeStep({ direction: 'backward' }))
    }, 100)
  })

  const getStepByKey = useFormSteps({
    form,
    next: next,
    prev: prev
  })
  return (
    <div
      style={{ fontFamily: `'Poppins', sans-serif` }}
      className={'multi-step-form'}
    >
      <DesktopSidebar>
        <Logo />
        <TimeLine />
      </DesktopSidebar>
      <AutoAnimateContainer className='form-header-container'>
        <FormMobileHeader />
        <MobileStepProgress />
        <StepMessageFactory />
      </AutoAnimateContainer>
      <div className={'form-container'}>
        <DesktopStepProgress />
        <Form
          preserve
          layout='vertical'
          form={form}
          validateTrigger={['onChange', 'onBlur']}
          initialValues={{
            // NOTE: this field is required for the form to work correctly (MG)
            owners: []
          }}
        >
          <AutoAnimateContainer>
            <SubStepWrapper form={form} subStep={currentSubStep}>
              {getStepByKey(formSteps[currentStep], currentSubStep)}
            </SubStepWrapper>
          </AutoAnimateContainer>
        </Form>
      </div>
      <VideoGuide />
      <FormFooter className='multi-step-form__footer' />
    </div>
  )
}

export default MultiStepForm
