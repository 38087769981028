import { FC, useMemo } from 'react'

import { Flex, Progress } from 'antd'

import { TimeLineItem } from '@/components/time-line'
import withDeviceDependent from '@/hocs/with-device-dependent/WithDeviceDependent'
import { timeLineSteps } from '@/utils/timeLineSteps'

import useProgress from '../useProgress'

import './MobileStepProgress.scss'

const MobileStepProgress: FC = () => {
  const { currentIndex, progressLength, currentTimelineStep } = useProgress()

  const { title, description, icon } = useMemo(() => {
    const currentStepIndex = timeLineSteps.findIndex(
      ({ key }) => key === currentTimelineStep
    )

    return {
      title: timeLineSteps[currentStepIndex].title,
      description: !!timeLineSteps[currentStepIndex + 1]
        ? `Next: ${timeLineSteps[currentStepIndex + 1]?.title}`
        : '',
      icon: timeLineSteps[currentStepIndex].activeDesktopIcon
    }
  }, [currentTimelineStep])

  if (currentTimelineStep === 'review') {
    return null
  }

  return (
    <div className='mobile-step-progress'>
      <Flex
        className='mobile-step-progress__content'
        justify={progressLength > 1 ? 'space-between' : 'flex-start'}
      >
        <TimeLineItem
          title={title}
          description={description}
          icon={icon}
          isActive={true}
        />
        {progressLength > 1 && (
          <Progress
            type='circle'
            percent={((currentIndex + 1) / progressLength) * 100}
            format={() => (
              <span className='mobile-step-progress__content__text'>{`${
                currentIndex + 1
              } of ${progressLength}`}</span>
            )}
            size={48}
            strokeColor={'var(--brand-primary)'}
            strokeWidth={8}
          />
        )}
      </Flex>
    </div>
  )
}

export default withDeviceDependent(MobileStepProgress, false)
