import React, { FC } from 'react'

import './CustomLabel.scss'

interface ICustomLabelProps {
  title?: string
  subtitle?: string
}

const CustomLabel: FC<ICustomLabelProps> = ({ title, subtitle = '' }) => {
  if (!title && !subtitle) {
    return null
  }

  return (
    <div className={'custom-label'}>
      <span className={'custom-label__title'}>{title}</span>
      <span className={'custom-label__subtitle'}>{subtitle}</span>
    </div>
  )
}

export default CustomLabel
