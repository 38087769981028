import { FC } from 'react'

import { useWatch } from 'antd/es/form/Form'

import { patchApplication } from '@/api/app.service'
import { uploadFormFiles } from '@/api/file.service'
import FormUploadInput from '@/components/form/form-upload-input/FormUploadInput'
import NavigationControls from '@/components/navigation-controls/NavigationControls'
import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import { useTypedSelector } from '@/hooks/redux'
import usePatchApplication from '@/hooks/usePatchApplication'
import { IStepProps } from '@/types/types'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import './StartBusinessDocumentsUpload.scss'

const { personalTaxReturn, governmentIdentity, businessPlans } =
  FORM_FIELD_NAMES_MAP

const StartBusinessDocumentsUpload: FC<IStepProps> = ({ form, next, prev }) => {
  const { applicationId } = useTypedSelector(({ formState }) => ({
    applicationId: formState.applicationId
  }))

  const businessPlanFiles = useWatch(businessPlans, form)
  const personalTaxFile = useWatch(personalTaxReturn, form)
  const governmentIdentityFile = useWatch(governmentIdentity, form)

  const atLeastOneFileAdded =
    businessPlanFiles?.length > 0 ||
    personalTaxFile?.length > 0 ||
    governmentIdentityFile?.length > 0

  const [patch, isLoading] = usePatchApplication(
    next,
    async ({
      businessPlans = [],
      personalTaxReturn = [],
      governmentIdentity = []
    }) => {
      if (!atLeastOneFileAdded) return

      await tryCatchWithMessage(async () => {
        const [
          uploadedBusinessPlan,
          uploadedPersonalTaxReturns,
          uploadedGovernmentIdentity
        ] = await Promise.all(
          [businessPlans, personalTaxReturn, governmentIdentity].map(
            async (files, index) => {
              if (!!files.length) {
                const res = await uploadFormFiles(files)
                return index === 0 ? res : res?.[0]
              }
              return null
            }
          )
        )

        const patchInput = [
          {
            path: '/businessPlans',
            value: uploadedBusinessPlan
          },
          {
            path: '/personalTaxReturn',
            value: uploadedPersonalTaxReturns
          },
          {
            path: '/governmentIdentity',
            value: uploadedGovernmentIdentity
          }
        ].filter(({ value }) => !!value)

        await patchApplication(applicationId, patchInput)
      })
    }
  )

  const uploadList = [
    {
      title: 'Do you have a business plan and financial projections?',
      subtitle: 'If yes, please attach here.',
      name: businessPlans,
      fileList: businessPlanFiles,
      maxCount: 5,
      allowedTypes: [
        'image/png',
        'image/jpeg',
        'application/pdf',
        'application/msword',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ],
      supportsLabel: 'PNG, JPG, PDF, DOC, XLS, DOCX'
    },
    {
      title: 'Personal tax return​',
      subtitle: 'Please upload your most recently filed personal tax return. ',
      name: personalTaxReturn,
      fileList: personalTaxFile,
      maxCount: 1
    },
    {
      title: 'Government-issued identification​',
      subtitle: "Please upload your Driver's License or Passport.​",
      name: governmentIdentity,
      fileList: governmentIdentityFile,
      maxCount: 1
    }
  ]

  return (
    <div className={'start-business-documents'}>
      <h2 className={'start-business-documents__title'}>Upload Documents</h2>
      <p className={'start-business-documents__subtitle'}>
        In order to complete your loan application, we will need the documents
        requested below. If you don't have these documents available to share
        with us now, we'll send you an email reminder so that you may upload
        them later.
      </p>
      <div className='start-business-documents__helper-text'>
        Business Plan and Financial Projections
      </div>
      {uploadList.map(
        ({
          title,
          subtitle,
          name,
          fileList,
          maxCount,
          allowedTypes,
          supportsLabel
        }) => (
          <div key={name} className={'upload-file'}>
            <span className={'upload-file__title'}>{title}</span>
            <span className={'upload-file__subtitle'}>{subtitle}</span>
            <FormUploadInput
              className={'upload-file__upload-wrapper'}
              name={name}
              fileList={fileList}
              filesMaxCount={maxCount}
              allowedTypes={allowedTypes}
              supportsLabel={supportsLabel}
            />
          </div>
        )
      )}
      <NavigationControls
        isLoading={isLoading}
        onNext={() => {
          patch()
        }}
        onPrev={() => prev()}
        nextBtnText={atLeastOneFileAdded ? 'Next' : 'Skip'}
      />
    </div>
  )
}

export default StartBusinessDocumentsUpload
