import { FC, useState } from 'react'

import { Form, Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib'

import NavigationControls from '@/components/navigation-controls/NavigationControls'
import { useTypedDispatch } from '@/hooks/redux'
import { changeStep } from '@/redux/features/timeLineSlice'

interface IProps {
  next: (isContactApproved: boolean) => void
}

const AlternativeFundingInfo: FC<IProps> = ({ next }) => {
  const dispatch = useTypedDispatch()

  const [isContactApproved, setIsContactApproved] = useState<boolean | null>(
    null
  )

  const handleContact = (e: RadioChangeEvent) => {
    setIsContactApproved(e.target?.value)
  }

  return (
    <div className={'alternative-funding'}>
      <h2 className={'alternative-funding__title'}>
        Alternative Funding Options Available
      </h2>
      <p className={'alternative-funding__subtitle'}>
        While we don&apos;t have a direct match for your needs at this time, we
        can connect you with other financing solutions that might work better
        for your business.
      </p>
      <p className={'alternative-funding__contact-label'}>
        Would you to like us to give you a call to discuss alternative options?
      </p>
      <Form.Item className={'alternative-funding__radio-group'}>
        <Radio.Group onChange={handleContact}>
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>
      <NavigationControls
        isLoading={false}
        onNext={() => next(isContactApproved!)}
        onPrev={() => dispatch(changeStep({ subStep: null }))}
        nextBtnDisabled={isContactApproved === null}
      />
    </div>
  )
}

export default AlternativeFundingInfo
