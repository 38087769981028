import { FC, ReactNode } from 'react'

import { CheckboxProps, Form } from 'antd'
import { Rule } from 'antd/es/form'
import { NamePath } from 'antd/es/form/interface'

import CustomCheckbox from './CustomCheckbox'

import './CheckboxFormItem.scss'

interface ICheckboxFormItemProps extends CheckboxProps {
  name: NamePath
  className?: string
  children: ReactNode
  rules?: Rule[],
  required?: boolean
}

const CheckboxFormItem: FC<ICheckboxFormItemProps> = ({
  name,
  rules,
  children,
  className = '',
  required = true,
  ...rest
}) => {
  return (
    <Form.Item
      valuePropName='checked'
      name={name}
      className={`checkbox ${className}`}
      rules={
        rules ?? [
          {
            validator: (_, value) => {
              if (required && !value) {
                return Promise.reject()
              }

              return Promise.resolve()
            }
          }
        ]
      }
    >
      <CustomCheckbox {...rest}>{children}</CustomCheckbox>
    </Form.Item>
  )
}

export default CheckboxFormItem
