import { Dispatch, FC, memo } from 'react'

import { Flex, Form, Tabs, TabsProps } from 'antd'

import InfoTooltip from '@/components/info-tooltip/InfoTooltip'
import useIsMobile from '@/hooks/useIsMobile'

import LegalInfoDetails from '../legal-info-details/LegalInfoDetails'
import PrimaryInfoFields from '../primary-info-fields/PrimaryInfoFields'

type TOwnerInfoTabsProps = {
  name: number
  currentOwnershipPercentage: number
  ownershipPercentage: number
  idx: number
  activeTab: number
  disabledNextTab: boolean
  setActiveTab: Dispatch<React.SetStateAction<number>>
}

const OwnerInfoTabs: FC<TOwnerInfoTabsProps> = memo(
  ({
    name,
    currentOwnershipPercentage,
    ownershipPercentage,
    disabledNextTab,
    idx,
    activeTab,
    setActiveTab
  }) => {
    const form = Form.useFormInstance()

    const isMobile = useIsMobile()

    const items: TabsProps['items'] = [
      {
        key: '0',
        label: 'Primary Details',
        children: (
          <PrimaryInfoFields
            form={form}
            name={name}
            currentOwnershipPercentage={currentOwnershipPercentage}
            ownershipPercentage={ownershipPercentage}
            idx={idx}
          />
        )
      },
      {
        key: '1',
        disabled: disabledNextTab,
        label: (
          <Flex align='center' gap={8}>
            {isMobile ? 'Disclosure' : 'Financial & Legal Disclosure'}{' '}
            <InfoTooltip />
          </Flex>
        ),
        children: <LegalInfoDetails name={name} isPrimaryOwner={idx === 0} />,
        forceRender: true
      }
    ]

    return (
      <Tabs
        defaultActiveKey='1'
        activeKey={activeTab.toString()}
        onChange={(value) => setActiveTab(Number(value))}
        items={items}
      />
    )
  }
)

export default OwnerInfoTabs
