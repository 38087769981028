import React, { useEffect, useRef, useState } from 'react'

import { Button, Progress } from 'antd'
import Lottie from 'lottie-react'

import { analysisProgress, getApplication } from '@/api/app.service'
import { useTypedDispatch, useTypedSelector } from '@/hooks/redux'
import usePatchApplication from '@/hooks/usePatchApplication'
import aiBackground from '@/images/ai-background.png'
import loadingAnimation from '@/images/loadingAnimation.json'
import { setPrefilledData } from '@/redux/features/formStateSlice'
import { setStepMessage } from '@/redux/features/timeLineSlice'
import { IStepProps } from '@/types/types'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import './AiLoading.scss'

const AiLoading: React.FC<IStepProps> = ({ next }) => {
  const [progress, setProgress] = useState(0)

  const pollingIntervalRef = useRef<NodeJS.Timer | null>(null)

  const dispatch = useTypedDispatch()

  const [isAtLeastOneDocumentUploaded, applicationId, formStep] =
    useTypedSelector(({ formState, timeLine }) => [
      formState.isAtLeastOneDocumentUploaded,
      formState.applicationId,
      timeLine.formStep
    ])

  const [patch, isLoading] = usePatchApplication(next, async () => {
    await tryCatchWithMessage(async () => {
      return new Promise((res) => setTimeout(res, 1))
    })
  })

  const startPolling = () => {
    pollingIntervalRef.current = setInterval(
      () =>
        analysisProgress(applicationId).then(({ isAIAnalysisCompleted }) => {
          if (isAIAnalysisCompleted) {
            clearInterval(pollingIntervalRef.current!)
            setProgress(100)

            getApplication(applicationId)
              .then((response) => {
                dispatch(setPrefilledData(response))
                patch()
              })
              .catch(() => {
                dispatch(
                  setStepMessage({
                    msgType: 'prefill-data-error',
                    stepIndex: formStep + 1
                  })
                )
                patch()
              })
          }
        }),
      1000
    )
  }

  useEffect(() => {
    if (isAtLeastOneDocumentUploaded) {
      startPolling()
    } else {
      patch()
    }

    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current)
      }
    }
  }, [])

  const handleCancelAutofill = () => {
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current)
    }
    patch()
  }

  useEffect(() => {
    window.document.body.classList.add('empty-page')

    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 95) {
          clearInterval(interval)
          return prev
        } else {
          return prev + Math.floor(Math.random() * 3)
        }
      })
    }, 780)

    return () => {
      clearInterval(interval)
      window.document.body.classList.remove('empty-page')
    }
  }, [])

  return (
    <div className={'ai-loading'}>
      <h2 className={'ai-loading__title'}>Processing Your Documents</h2>
      <p className={'ai-loading__subtitle'}>
        Please remain on this page and avoid using the back or refresh buttons
        to ensure successful completion.
      </p>
      <div className={'ai-loading__animation'}>
        <img
          src={aiBackground}
          alt={'Blue shadow'}
          className={'ai-loading__background'}
        />
        <Lottie animationData={loadingAnimation} loop={true} />
      </div>
      <div className='ai-loading__progressbar-wrapper'>
        <Progress
          percent={progress}
          showInfo={false}
          size={{
            width: 240,
            height: 4
          }}
          strokeColor={{
            '0%': '#C27DB9',
            '25%': '#B2B2E0',
            '50%': '#B9A0DF',
            '100%': '#8FACD8'
          }}
        />
      </div>
      <Button
        onClick={handleCancelAutofill}
        loading={isLoading}
        className={'ai-loading__cancel-btn'}
      >
        Cancel
      </Button>
    </div>
  )
}

export default AiLoading
