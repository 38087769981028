import { Form } from 'antd'
import { useWatch } from 'antd/es/form/Form'

import {
  BUSINESS_OVERVIEW_FIELD_NAMES_MAP,
  FORM_FIELD_NAMES_MAP,
  TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP
} from '@/constants/formFieldNames'
import { EBusinessEntity, EFundingPurposeType } from '@/types/formFieldTypes'

import useCustomWatch from './useCustomWatch'

const { businessOverview, fundingPurposeType, targetBusinessOverview } =
  FORM_FIELD_NAMES_MAP

const useIsSoleProprietor = () => {
  const form = Form.useFormInstance()

  const fundingPurpose = useWatch(fundingPurposeType, { form, preserve: true })
  const businessEntityTypeItemName =
    fundingPurpose === EFundingPurposeType.StartBusiness
      ? [
          targetBusinessOverview,
          TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP.businessEntityType
        ]
      : [businessOverview, BUSINESS_OVERVIEW_FIELD_NAMES_MAP.businessEntityType]

  const isSoleProprietor =
    useCustomWatch(businessEntityTypeItemName, form) ===
    EBusinessEntity.SoleProprietor

  return isSoleProprietor
}

export default useIsSoleProprietor
