import React, { FC, HTMLAttributes } from 'react'

const Play: FC<HTMLAttributes<SVGElement>> = (props) => {
  return (
    <svg
      width='38'
      height='38'
      viewBox='0 0 38 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19 33.25C20.8713 33.25 22.7243 32.8814 24.4532 32.1653C26.1821 31.4492 27.753 30.3995 29.0763 29.0763C30.3995 27.753 31.4492 26.1821 32.1653 24.4532C32.8814 22.7243 33.25 20.8713 33.25 19C33.25 17.1287 32.8814 15.2757 32.1653 13.5468C31.4492 11.8179 30.3995 10.247 29.0763 8.92373C27.753 7.60049 26.1821 6.55085 24.4532 5.83472C22.7243 5.11859 20.8713 4.75 19 4.75C15.2207 4.75 11.5961 6.25133 8.92373 8.92373C6.25134 11.5961 4.75 15.2207 4.75 19C4.75 22.7793 6.25134 26.4039 8.92373 29.0763C11.5961 31.7487 15.2207 33.25 19 33.25ZM17.0731 12.6508L26.0094 17.6162C26.2561 17.7533 26.4617 17.954 26.6048 18.1973C26.7479 18.4406 26.8234 18.7177 26.8234 19C26.8234 19.2823 26.7479 19.5594 26.6048 19.8027C26.4617 20.046 26.2561 20.2467 26.0094 20.3838L17.0731 25.3492C16.7837 25.51 16.4574 25.5924 16.1263 25.5883C15.7953 25.5842 15.4711 25.4937 15.1859 25.3257C14.9006 25.1577 14.6642 24.9181 14.5 24.6306C14.3359 24.3431 14.2497 24.0177 14.25 23.6867V14.3133C14.2497 13.9823 14.3359 13.6569 14.5 13.3694C14.6642 13.0819 14.9006 12.8423 15.1859 12.6743C15.4711 12.5063 15.7953 12.4158 16.1263 12.4117C16.4574 12.4076 16.7837 12.49 17.0731 12.6508Z'
        fill='#13A55B'
      />
    </svg>
  )
}

export default Play
