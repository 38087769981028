import { FC } from 'react'

import { Col, Form, Row } from 'antd'
import { useWatch } from 'antd/es/form/Form'

import { patchApplication } from '@/api/app.service'
import CityFormItem from '@/components/form/form-items/CityFormItem'
import SelectStateFormItem from '@/components/form/form-items/SelectStateFormItem'
import ZipCodeFormItem from '@/components/form/form-items/ZipCodeFormItem'
import AddressFormItem from '@/components/form/form-items/address-form-item/AddressFormItem'
import { NumberInputFormItem } from '@/components/form/form-items/default-items/input-form-item'
import RadioFormItem from '@/components/form/form-items/default-items/radio-form-item/RadioFormItem'
import NavigationControls from '@/components/navigation-controls/NavigationControls'
import {
  ADDRESS_FIELD_NAMES_MAP,
  COMMERCIAL_REAL_ESTATE_FIELD_NAMES_MAP,
  FORM_FIELD_NAMES_MAP
} from '@/constants/formFieldNames'
import {
  REAL_ESTATE_OPTIONS,
  YES_OR_NO_TYPE_OPTIONS
} from '@/constants/formFieldOptions'
import { useTypedSelector } from '@/hooks/redux'
import usePatchApplication from '@/hooks/usePatchApplication'
import { IStepProps } from '@/types/types'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import './RealEstateAcquisitionInfo.scss'

const { commercialRealEstate } = FORM_FIELD_NAMES_MAP
const { price, address, propertyType } = COMMERCIAL_REAL_ESTATE_FIELD_NAMES_MAP
const { city, street, usaStateType, zipCode } = ADDRESS_FIELD_NAMES_MAP

const RealEstateAcquisitionInfo: FC<IStepProps> = ({ next, prev }) => {
  const form = Form.useFormInstance()

  const formatter = (value?: number) => {
    return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
  }

  const applicationId = useTypedSelector(
    ({ formState }) => formState.applicationId
  )

  const realEstateChosenValue = useWatch('commercialRealEstateChosen', form)

  const [patch, isLoading] = usePatchApplication(
    next,
    async ({ commercialRealEstate: commercialRealEstateValue }) => {
      if (!commercialRealEstateValue) {
        return
      }

      const values = [
        {
          path: '/commercialRealEstate',
          value: commercialRealEstateValue
        }
      ]

      await tryCatchWithMessage(async () => {
        await patchApplication(applicationId, values)
      })
    }
  )

  return (
    <div className='real-estate-acquisition'>
      <h2 className={'real-estate-acquisition__title'}>
        Have you chosen the commercial real estate you intend to acquire? 
      </h2>
      <p className={'real-estate-acquisition__subtitle'}>
        Please select answer below:
      </p>
      <RadioFormItem
        name={'commercialRealEstateChosen'}
        options={YES_OR_NO_TYPE_OPTIONS}
      />
      {(realEstateChosenValue ?? false) && (
        <>
          <Row gutter={16}>
            <Col span={24}>
              <NumberInputFormItem
                label='Asking Price'
                placeholder='Enter Amount'
                suffix={<>USD</>}
                min={0}
                maxLength={10}
                name={[commercialRealEstate, price]}
                controls={false}
                formatter={formatter}
                parser={(value) => Number(value?.replace(/,/g, ''))}
                rules={[
                  {
                    required: false
                  }
                ]}
              />
            </Col>
            <Col span={24}>
              <p className='real-estate-acquisition__helper-text'>
                Please select answer below:
              </p>
            </Col>
            <Col span={24}>
              <RadioFormItem
                label='Is it an existing property or is this for new construction?'
                options={REAL_ESTATE_OPTIONS}
                name={[commercialRealEstate, propertyType]}
                rules={[{ required: false }]}
              />
            </Col>
            <Col span={24}>
              <p className='real-estate-acquisition__helper-text'>
                Please provide the address:
              </p>
            </Col>
            <Col span={24}>
              <AddressFormItem
                name={[commercialRealEstate, address, street]}
                rules={[{ required: false }]}
              />
            </Col>
            <Col xs={24} md={8}>
              <CityFormItem
                name={[commercialRealEstate, address, city]}
                rules={[{ required: false }]}
              />
            </Col>
            <Col xs={24} md={8}>
              <SelectStateFormItem
                placeholder='Select'
                name={[commercialRealEstate, address, usaStateType]}
                rules={[{ required: false }]}
              />
            </Col>
            <Col xs={24} md={8}>
              <ZipCodeFormItem
                name={[commercialRealEstate, address, zipCode]}
                rules={[{ required: false }]}
              />
            </Col>
          </Row>
        </>
      )}
      <NavigationControls
        isLoading={isLoading}
        onNext={() => patch()}
        onPrev={() => prev()}
      />
    </div>
  )
}

export default RealEstateAcquisitionInfo
