import { FC } from 'react'

import { Form, Input } from 'antd'
import { Rule } from 'antd/es/form'
import { NamePath } from 'antd/es/form/interface'
import { TextAreaProps } from 'antd/lib/input'

import './TextAreaFormInput.scss'

interface ITextAreaFormInputProps extends TextAreaProps {
  name: NamePath
  maxLength?: number
  label?: string
  placeholder?: string
  rules?: Rule[]
}

const TextAreaFormInput: FC<ITextAreaFormInputProps> = ({
  name,
  maxLength = 300,
  label,
  rules,
  ...rest
}) => {
  return (
    <div className='textarea-wrapper'>
      <Form.Item
        name={name}
        label={label}
        rules={rules ?? [{ required: true }]}
      >
        <Input.TextArea maxLength={maxLength} {...rest} />
      </Form.Item>
    </div>
  )
}

export default TextAreaFormInput
