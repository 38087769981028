import { GenericAbortSignal } from 'axios'

import { CloudFileDto } from '@/generated-types'
import companyLogo from '@/images/loanbud-logo.svg'
import { TFormFile } from '@/utils/formFileList'

import APIinstance from './config'

export const uploadFormFiles = async (
  files: TFormFile[],
  signal?: GenericAbortSignal
): Promise<CloudFileDto[]> => {
  const formData = new FormData()

  files.forEach((file) => {
    file.originFileObj && formData.append('files', file.originFileObj)
  })

  const data = await uploadFiles(formData, signal)

  return data
}

export const uploadFiles = async (
  formData: FormData,
  signal?: GenericAbortSignal
): Promise<CloudFileDto[]> => {
  const { data } = await APIinstance.post<CloudFileDto[]>(
    'cloud-files',
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data'
      },
      signal
    }
  )

  return data
}

export const deleteFile = async (fileId: string): Promise<void> => {
  await APIinstance.delete(`cloud-files/${fileId}`)
}

export const getFile = async (
  fileId: string
): Promise<CloudFileDto | ArrayBuffer> => {
  const { data } = await APIinstance.get(`cloud-files/${fileId}`, {
    responseType: 'arraybuffer'
  })

  return data
}

export const getLogo = async (logoId?: string): Promise<string> => {
  if (!logoId) {
    return companyLogo
  }

  const logoData = (await getFile(logoId)) as ArrayBuffer

  if (!logoData) {
    return companyLogo
  }

  const blob = new Blob([logoData], { type: 'image/png' })
  const url = URL.createObjectURL(blob)

  return url
}
