import { FC } from 'react'

import withDeviceDependent from '@/hocs/with-device-dependent/WithDeviceDependent'

import useProgress from '../useProgress'

import './DesktopStepProgress.scss'

const DesktopStepProgress: FC = () => {
  const { currentIndex, progressLength, currentTimelineStep } = useProgress()

  if (currentTimelineStep === 'review') {
    return null
  }

  return (
    <div className='desktop-step-progress'>
      <span className='desktop-step-progress__steps-info'>
        Step {`${currentIndex + 1}/${progressLength}`}
      </span>
      <div className='desktop-step-progress__progress'>
        {Array(progressLength)
          .fill(null)
          .map((_, index) => (
            <div
              key={index}
              className={`desktop-step-progress__step ${
                index <= currentIndex
                  ? 'desktop-step-progress__step-active'
                  : ''
              }`}
            />
          ))}
      </div>
    </div>
  )
}

export default withDeviceDependent(DesktopStepProgress, true)
