import { FC } from 'react'

import { Col, Flex, Row } from 'antd'

import { patchApplication } from '@/api/app.service'
import BusinessEinFormItem from '@/components/form/form-items/BusinessEinFormItem'
import BusinessEntityFormItem from '@/components/form/form-items/BusinessEntityFormItem'
import IndustryFormItem from '@/components/form/form-items/IndustryFormItem'
import SelectStateFormItem from '@/components/form/form-items/SelectStateFormItem'
import BusinessActivityCode from '@/components/form/form-items/business-activity-code/BusinessActivityCode'
import DatePickerFormItem from '@/components/form/form-items/default-items/date-picker-form-item/DatePickerFormItem'
import InputFormItem from '@/components/form/form-items/default-items/input-form-item'
import RadioFormItem from '@/components/form/form-items/default-items/radio-form-item/RadioFormItem'
import NavigationControls from '@/components/navigation-controls/NavigationControls'
import {
  BUSINESS_OVERVIEW_FIELD_NAMES_MAP,
  FORM_FIELD_NAMES_MAP,
  OWNER_INFO_FIELD_NAMES_MAP
} from '@/constants/formFieldNames'
import { YES_OR_NO_TYPE_OPTIONS } from '@/constants/formFieldOptions'
import { ENTITY_AND_EIN_INFO_TEXT } from '@/constants/texts'
import { useTypedSelector } from '@/hooks/redux'
import useCustomWatch from '@/hooks/useCustomWatch'
import usePatchApplication from '@/hooks/usePatchApplication'
import useSetPrefilledData from '@/hooks/useSetPrefilledData'
import { EBusinessEntity } from '@/types/formFieldTypes'
import { IStepProps } from '@/types/types'
import getFormattedPhoneNumber from '@/utils/getFormattedPhoneNumber'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import './BusinessEntityAndEin.scss'

const { businessOverview, owners } = FORM_FIELD_NAMES_MAP
const {
  formationState,
  businessEIN,
  businessEntityType,
  businessActivityCode,
  businessStartedAt,
  isFranchise,
  industryType,
  franchiseName
} = BUSINESS_OVERVIEW_FIELD_NAMES_MAP

const { businessWeight } = OWNER_INFO_FIELD_NAMES_MAP

const DefaultFields: FC<IStepProps> = ({ next, form, prev }) => {
  const applicationId = useTypedSelector(
    ({ formState }) => formState.applicationId
  )

  const isItFranchiseValue = useCustomWatch(
    [businessOverview, isFranchise],
    form
  )

  useSetPrefilledData(
    form,
    [
      formationState,
      businessEntityType,
      businessEIN,
      businessStartedAt,
      businessActivityCode,
      isFranchise,
      franchiseName
    ],
    'businessOverview'
  )

  const [patch, isLoading] = usePatchApplication(next, async () => {
    const businessOverviewValue = form.getFieldValue(businessOverview)

    if (
      businessOverviewValue.businessEntityType ===
      EBusinessEntity.SoleProprietor
    ) {
      form.setFieldValue([owners, '0', businessWeight], 100)
    }

    await tryCatchWithMessage(async () => {
      await patchApplication(applicationId, [
        {
          path: '/businessOverview',
          value: {
            ...businessOverviewValue,
            isBusinessEntityFormed: !(
              businessOverviewValue.isBusinessEntityFormed ?? true
            ),
            phoneNumber: getFormattedPhoneNumber(
              businessOverviewValue.phoneNumber
            ),
            businessEIN: businessOverviewValue.businessEIN?.replace(/-/g, ''),
            isFranchise: !!businessOverviewValue.isFranchise
          }
        }
      ])
    })
  })

  return (
    <div className={'business-info'}>
      <h2 className={'business-info__title'}>
        {ENTITY_AND_EIN_INFO_TEXT.title}
      </h2>
      <p className={'business-info__subtitle'}>
        {ENTITY_AND_EIN_INFO_TEXT.subtitle}
      </p>
      <Row gutter={16}>
        <Col span={24}>
          <IndustryFormItem name={[businessOverview, industryType]} />
        </Col>
        <Col span={24}>
          <BusinessEntityFormItem
            name={[businessOverview, businessEntityType]}
          />
        </Col>
        <Col span={24}>
          <SelectStateFormItem
            label='State of Formation'
            name={[businessOverview, formationState]}
          />
        </Col>
        <Col span={24}>
          <DatePickerFormItem
            label='Date of Business Formation'
            name={[businessOverview, businessStartedAt]}
          />
        </Col>
        <Col span={24}>
          <BusinessEinFormItem name={[businessOverview, businessEIN]} />
        </Col>
        <Col span={24}>
          <BusinessActivityCode
            name={[businessOverview, businessActivityCode]}
          />
        </Col>
        <Col span={24}>
          <Flex vertical>
            <RadioFormItem
              label='Is it a franchise?'
              name={[businessOverview, isFranchise]}
              options={YES_OR_NO_TYPE_OPTIONS}
              rules={[{ required: false }]}
            />
          </Flex>
        </Col>
        {(isItFranchiseValue ?? false) && (
          <Col span={24}>
            <InputFormItem
              rules={[{ required: false }]}
              name={[businessOverview, franchiseName]}
              label='Name of Franchise'
              placeholder='Enter name'
            />
          </Col>
        )}
      </Row>
      <NavigationControls
        isLoading={isLoading}
        onNext={() => {
          patch()
        }}
        onPrev={() => prev()}
      />
    </div>
  )
}

export default DefaultFields
