import { FC } from 'react'

import { Form } from 'antd'
import { NamePath } from 'antd/es/form/interface'

import { CITIZENSHIP_STATUS_OPTIONS } from '@/constants/formFieldOptions'
import useCustomWatch from '@/hooks/useCustomWatch'
import { ECitizenshipStatus } from '@/types/formFieldTypes'

import SelectFormItem from './default-items/select-form-item/SelectFormItem'
import TextAreaFormInput from './default-items/textarea-form-input/TextAreaFormInput'

interface ICitizenshipFormItemProps {
  statusName: NamePath
  statusExplanationName: NamePath
  statusFullName?: NamePath
  required?: boolean
}

const CitizenshipFormItem: FC<ICitizenshipFormItemProps> = ({
  statusExplanationName,
  statusName,
  statusFullName,
  required
}) => {
  const form = Form.useFormInstance()
  const citizenshipValue = useCustomWatch(statusFullName ?? statusName, form)

  return (
    <>
      <SelectFormItem
        name={statusName}
        options={CITIZENSHIP_STATUS_OPTIONS}
        label='Select Citizenship Status'
        placeholder='Select from the list'
        rules={[{ required }]}
      />
      {citizenshipValue === ECitizenshipStatus.Other && (
        <TextAreaFormInput
          name={statusExplanationName}
          label='Please explain'
          placeholder='Enter text'
          autoSize={{ maxRows: 2 }}
          rules={[{ required }]}
        />
      )}
    </>
  )
}

export default CitizenshipFormItem
