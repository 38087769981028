import React, { FC } from 'react'

import { InputNumberProps } from 'antd'
import { Rule } from 'antd/es/form'
import { NamePath } from 'antd/es/form/interface'

import { NumberInputFormItem } from './default-items/input-form-item'

interface IMoneyAmountFormItemProps extends InputNumberProps<number> {
  name: NamePath
  label?: string
  placeholder?: string
  rules?: Rule[]
}

const MoneyAmountFormItem: FC<IMoneyAmountFormItemProps> = ({
  label,
  name,
  placeholder = 'Enter Amount',
  rules,
  ...rest
}) => {
  const formatter = (value?: number) => {
    return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
  }

  return (
    <NumberInputFormItem
      label={label}
      placeholder={placeholder}
      suffix={<>USD</>}
      min={0}
      maxLength={10}
      name={name}
      controls={false}
      formatter={formatter}
      parser={(value) => Number(value?.replace(/,/g, ''))}
      rules={rules}
      {...rest}
    />
  )
}

export default MoneyAmountFormItem
