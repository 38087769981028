import { FC, ReactNode, useEffect, useState } from 'react'

import { Button, Drawer, Flex, Form, FormInstance, Modal } from 'antd'
import { NamePath } from 'antd/es/form/interface'

import useIsMobile from '@/hooks/useIsMobile'

import './EditModal.scss'

interface IEditModalProps {
  open: boolean
  title: string
  subtitle: string
  timelineStepTitle: string
  namePath: NamePath
  onSave: (newValue: any) => void
  onClose: () => void
  children: ReactNode
  mainForm: FormInstance
}

const EditModal: FC<IEditModalProps> = ({
  open,
  title,
  subtitle,
  timelineStepTitle,
  namePath,
  onSave,
  onClose,
  children,
  mainForm
}) => {
  const isMobile = useIsMobile()

  const [isValid, setIsValid] = useState(true)

  const [form] = Form.useForm()

  const value = Form.useWatch((values) => values, form)

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => {
        setIsValid(true)
      })
      .catch((err) => {
        !err.outOfDate && setIsValid(false)
      })
  }, [value, form])

  const getInitialValue = (namePath: NamePath) => {
    if (!namePath) {
      return null
    }

    if (Array.isArray(namePath)) {
      if (!Array.isArray(namePath[0])) {
        const key = namePath.at(-1)

        return { [key]: mainForm.getFieldValue(namePath) }
      }

      return namePath.reduce((acc, namePathItem) => {
        const key = Array.isArray(namePathItem)
          ? namePathItem.at(-1)
          : namePathItem

        const initialValue = { [key]: mainForm.getFieldValue(namePathItem) }

        return { ...acc, ...initialValue }
      }, {})
    }

    return {
      [namePath]: mainForm.getFieldValue(namePath)
    }
  }

  useEffect(() => {
    const initValues = getInitialValue(namePath)

    if (!initValues) {
      return
    }

    form.setFieldsValue(initValues)
  }, [namePath])

  return (
    <>
      {isMobile ? (
        <Drawer
          open={open}
          onClose={() => {
            form.resetFields()
            onClose()
          }}
          classNames={{
            content: 'edit-drawer__content',
            body: 'edit-drawer__body',
            header: 'edit-drawer__header'
          }}
          placement='bottom'
          height='unset'
        >
          <Form form={form} layout='vertical' onFinish={onSave}>
            <Flex vertical>
              <p className='timeline-step'>{timelineStepTitle}</p>
              <h2 className='title'>{title}</h2>
              <p className='subtitle'>{subtitle}</p>
              {children}
              <Flex vertical={false} gap={16} style={{ marginTop: 32 }}>
                <Button
                  onClick={() => {
                    form.resetFields()
                    onClose()
                  }}
                  type={'default'}
                  className='edit-drawer__cancel-btn'
                >
                  Cancel
                </Button>
                <Button
                  htmlType='submit'
                  type={'primary'}
                  variant='outlined'
                  className='edit-drawer__submit-btn'
                >
                  Save
                </Button>
              </Flex>
            </Flex>
          </Form>
        </Drawer>
      ) : (
        <Modal
          open={open}
          onCancel={() => {
            form.resetFields()
            onClose()
          }}
          onOk={() => form.submit()}
          closeIcon={null}
          cancelButtonProps={{
            style: { color: 'var(--brand-primary)' },
            size: 'large'
          }}
          okButtonProps={{
            size: 'large',
            disabled: !isValid
          }}
          okText='Save'
          centered
          classNames={{
            content: 'edit-modal__content',
            footer: 'edit-modal__footer'
          }}
          width={612}
          destroyOnClose
        >
          <Form form={form} layout='vertical' onFinish={onSave}>
            <Flex vertical>
              <p className='timeline-step'>{timelineStepTitle}</p>
              <h2 className='title'>{title}</h2>
              <p className='subtitle'>{subtitle}</p>
              {children}
            </Flex>
          </Form>
        </Modal>
      )}
    </>
  )
}

export default EditModal
