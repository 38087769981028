import { FC } from 'react'

import { LoadingOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Spin } from 'antd'
import { Link } from 'react-router-dom'

import { partnersQueries } from '@/api/partners.service'
import { PartnerDto } from '@/generated-types'
import { useGetLogo } from '@/hooks/useGetLogo'

import './Logo.scss'

const defaultCompanyUrl = 'https://loanbud.com/'

interface ILogoProps {
  className?: string
}

const Logo: FC<ILogoProps> = ({ className = '' }) => {
  const queryClient = useQueryClient()

  const data = queryClient.getQueryData<Required<PartnerDto>>(
    partnersQueries.partner.me.queryKey
  )

  const { data: logo, isLoading: isLogoLoading } = useGetLogo(data?.logo?.id)

  const companyUrl = data?.url ?? defaultCompanyUrl

  return (
    <Link
      to={companyUrl}
      className={`company-link ${className}`}
      target={'_blank'}
      rel='noreferrer'
    >
      {isLogoLoading || !logo ? (
        <Spin
          indicator={
            <LoadingOutlined
              spin
              style={{ fontSize: 38, color: 'var(--brand-primary)' }}
            />
          }
        />
      ) : (
        <img src={logo} alt={'company logo'} className={'company-logo'} />
      )}
    </Link>
  )
}

export default Logo
