import { FC } from 'react'

import { Rule } from 'antd/es/form'
import { NamePath } from 'antd/es/form/interface'

import DatePickerFormItem from './default-items/date-picker-form-item/DatePickerFormItem'

interface IBusinessStartedFormItemProps {
  name: NamePath
  rules?: Rule[]
}

const BusinessStartDateFormItem: FC<IBusinessStartedFormItemProps> = ({
  name,
  rules
}) => {
  return (
    <DatePickerFormItem
      rules={rules}
      name={name}
      label='Date Business Started'
    />
  )
}

export default BusinessStartDateFormItem
