import { FC, useEffect } from 'react'

import { Col, Form, Input, Radio, Row } from 'antd'

import { patchApplication } from '@/api/app.service'
import AnnualProfitFormItem from '@/components/form/form-items/AnnualProfitFormItem'
import AnnualRevenueFormItem from '@/components/form/form-items/AnnualRevenueFormItem'
import BusinessNameFormItem from '@/components/form/form-items/BusinessNameFormItem'
import CityFormItem from '@/components/form/form-items/CityFormItem'
import EmployeesNumberFormItem from '@/components/form/form-items/EmployeesNumberFormItem'
import SelectStateFormItem from '@/components/form/form-items/SelectStateFormItem'
import ZipCodeFormItem from '@/components/form/form-items/ZipCodeFormItem'
import AddressFormItem from '@/components/form/form-items/address-form-item/AddressFormItem'
import CheckboxFormItem from '@/components/form/form-items/default-items/checkbox-form-item/CheckboxFormItem'
import RadioFormItem from '@/components/form/form-items/default-items/radio-form-item/RadioFormItem'
import PhoneInputFormItem from '@/components/form/form-items/phone-input-form-item/PhoneInputFormItem'
import NavigationControls from '@/components/navigation-controls/NavigationControls'
import {
  ADDRESS_FIELD_NAMES_MAP,
  BUSINESS_OVERVIEW_FIELD_NAMES_MAP,
  FORM_FIELD_NAMES_MAP
} from '@/constants/formFieldNames'
import { YES_OR_NO_TYPE_OPTIONS } from '@/constants/formFieldOptions'
import {
  BUSINESS_INFO_TEXT,
  REQUIRED_FIELD_SHORT_ERROR_MESSAGE
} from '@/constants/texts'
import { useTypedSelector } from '@/hooks/redux'
import useCustomWatch from '@/hooks/useCustomWatch'
import usePatchApplication from '@/hooks/usePatchApplication'
import useSetPrefilledData from '@/hooks/useSetPrefilledData'
import { IStepProps } from '@/types/types'
import getFormattedPhoneNumber from '@/utils/getFormattedPhoneNumber'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import './BusinessMainInfo.scss'

const { businessOverview } = FORM_FIELD_NAMES_MAP
const {
  isDBA,
  legalAddress,
  physicalAddress,
  legalAddressSameAsPhysical,
  name,
  dbaName,
  phoneNumber,
  businessStartedAt,
  employeesCount,
  annualRevenue,
  annualRevenueYear,
  annualProfit,
  annualProfitYear
} = BUSINESS_OVERVIEW_FIELD_NAMES_MAP
const { city, zipCode, street, usaStateType } = ADDRESS_FIELD_NAMES_MAP

const BusinessMainInfo: FC<IStepProps> = ({ form, next, prev }) => {
  const businessAsDbaValue = useCustomWatch([businessOverview, isDBA], form)
  const legalAddressSameAsPhysicalValue = useCustomWatch(
    [businessOverview, legalAddressSameAsPhysical],
    form
  )

  useSetPrefilledData(
    form,
    [
      name,
      isDBA,
      dbaName,
      legalAddress,
      physicalAddress,
      businessStartedAt,
      annualRevenue,
      annualProfit,
      annualProfitYear,
      annualRevenueYear,
      legalAddressSameAsPhysical
    ],
    'businessOverview'
  )

  const applicationId = useTypedSelector(
    ({ formState }) => formState.applicationId
  )

  const [patch, isLoading] = usePatchApplication(next, async () => {
    const businessOverviewValue = form.getFieldValue(businessOverview)

    if (businessOverviewValue.legalAddressSameAsPhysical) {
      form.setFieldValue(
        [businessOverview, physicalAddress],
        businessOverviewValue.legalAddress
      )
    }

    const formattedBusinessActivityCodeValue =
      businessOverviewValue.legalAddressSameAsPhysical
        ? {
            ...businessOverviewValue,
            physicalAddress: businessOverviewValue.legalAddress
          }
        : businessOverviewValue

    await tryCatchWithMessage(async () => {
      await patchApplication(applicationId, [
        {
          path: '/businessOverview',
          value: {
            ...formattedBusinessActivityCodeValue,
            phoneNumber: getFormattedPhoneNumber(
              formattedBusinessActivityCodeValue.phoneNumber
            )
          }
        }
      ])
    })
  })

  useEffect(() => {
    if (legalAddressSameAsPhysicalValue) {
      form.setFieldValue([businessOverview, physicalAddress], undefined)
    }
  }, [legalAddressSameAsPhysicalValue, form])

  return (
    <div className={'business-info'}>
      <h2 className={'business-info__title'}>{BUSINESS_INFO_TEXT.title}</h2>
      <p className={'business-info__subtitle'}>{BUSINESS_INFO_TEXT.subtitle}</p>
      <Row gutter={16}>
        <Col span={24}>
          <BusinessNameFormItem name={[businessOverview, name]} />
        </Col>
        <Col span={24}>
          <CheckboxFormItem
            name={[businessOverview, isDBA]}
            rules={[{ required: false }]}
          >
            Are you doing business as a DBA?
          </CheckboxFormItem>
        </Col>
        {businessAsDbaValue && (
          <Col span={24}>
            <Form.Item
              name={[businessOverview, dbaName]}
              label={`Name You're Doing Business As`}
              className={'business-info__input'}
              rules={[
                {
                  required: true,
                  message: 'Please enter a Business Name',
                  whitespace: true
                }
              ]}
            >
              <Input placeholder='Enter Business Name' />
            </Form.Item>
          </Col>
        )}

        <Col span={24}>
          <AddressFormItem
            label='Business Address'
            name={[businessOverview, legalAddress, street]}
          />
        </Col>
        <Col xs={24} md={8}>
          <CityFormItem
            name={[businessOverview, legalAddress, city]}
            rules={[
              {
                required: true,
                whitespace: true,
                message: REQUIRED_FIELD_SHORT_ERROR_MESSAGE
              }
            ]}
          />
        </Col>
        <Col xs={24} md={8}>
          <SelectStateFormItem
            placeholder='Select'
            name={[businessOverview, legalAddress, usaStateType]}
            rules={[
              {
                required: true,
                whitespace: true,
                message: REQUIRED_FIELD_SHORT_ERROR_MESSAGE
              }
            ]}
          />
        </Col>
        <Col xs={24} md={8}>
          <ZipCodeFormItem
            name={[businessOverview, legalAddress, zipCode]}
            rules={[
              {
                required: true,
                whitespace: true,
                message: REQUIRED_FIELD_SHORT_ERROR_MESSAGE
              }
            ]}
          />
        </Col>
        <Col span={24}>
          <PhoneInputFormItem
            name={[businessOverview, phoneNumber]}
            label='Business Phone Number'
          />
        </Col>
        <Col span={24}>
          <RadioFormItem
            name={[businessOverview, legalAddressSameAsPhysical]}
            rules={[{ required: true, message: 'Please select an answer' }]}
            options={YES_OR_NO_TYPE_OPTIONS}
            label='Is the mailing address the same as the business address?'
          />
        </Col>
        {!(legalAddressSameAsPhysicalValue ?? true) && (
          <>
            <Col span={24}>
              <AddressFormItem
                label='Mailing Address'
                name={[businessOverview, physicalAddress, street]}
              />
            </Col>
            <Col xs={24} md={8}>
              <CityFormItem name={[businessOverview, physicalAddress, city]} />
            </Col>
            <Col xs={24} md={8}>
              <SelectStateFormItem
                placeholder='Select'
                name={[businessOverview, physicalAddress, usaStateType]}
              />
            </Col>
            <Col xs={24} md={8}>
              <ZipCodeFormItem
                name={[businessOverview, physicalAddress, zipCode]}
              />
            </Col>
          </>
        )}
        <Col span={24}>
          <p className={'business-info__helper-text'}>
            Please share the annual revenue and profit of the business for the
            most recent year.
          </p>
        </Col>
        <Col span={24}>
          <AnnualRevenueFormItem
            revenueYearName={[businessOverview, annualRevenueYear]}
            revenueName={[businessOverview, annualRevenue]}
          />
        </Col>
        <Col span={24}>
          <AnnualProfitFormItem
            profitYearName={[businessOverview, annualProfitYear]}
            profitName={[businessOverview, annualProfit]}
          />
        </Col>
        <Col span={24}>
          <EmployeesNumberFormItem name={[businessOverview, employeesCount]} />
        </Col>
      </Row>

      <NavigationControls
        isLoading={isLoading}
        onNext={() => {
          patch()
        }}
        onPrev={() => prev()}
      />
    </div>
  )
}

export default BusinessMainInfo
