import {
  AddressDto,
  AnnualAmountDto,
  ApplicationDto,
  BorrowerSignatureDto,
  BusinessAcquisitionDto,
  BusinessOverviewDto,
  CommercialRealEstateDto,
  OwnerDto,
  TargetBusinessOverviewDto
} from '@/generated-types'

type TOwnerKey = keyof Omit<OwnerDto, 'id'>
export type TFormKey = keyof Omit<ApplicationDto, 'id' | 'isDraft'>

type TTargetBusinessOverviewKey = keyof Omit<TargetBusinessOverviewDto, 'id'>
type TAddressOverviewKey = keyof Omit<AddressDto, 'id'>
type TBusinessOverviewKey = keyof Omit<BusinessOverviewDto, 'id'>
type TBusinessAcquisitionKey = keyof Omit<BusinessAcquisitionDto, 'id'>
type TBorrowerSignatureKey = keyof Omit<BorrowerSignatureDto, 'id'>
type TCommercialRealEstateKey = keyof Omit<CommercialRealEstateDto, 'id'>
type TAnnualAmountKey = keyof Omit<AnnualAmountDto, 'id'>

const FORM_FIELD_NAMES_MAP: Record<TFormKey, TFormKey> = {
  ficoScoreType: 'ficoScoreType',
  totalFunding: 'totalFunding',
  fundingPurposeType: 'fundingPurposeType',
  fundingPurpose: 'fundingPurpose',
  targetBusinessOverview: 'targetBusinessOverview',
  timeframeType: 'timeframeType',
  owners: 'owners',
  businessOverview: 'businessOverview',
  businessTaxReturn: 'businessTaxReturn',
  personalTaxReturn: 'personalTaxReturn',
  governmentIdentity: 'governmentIdentity',
  borrowerSignature: 'borrowerSignature',
  businessAcquisition: 'businessAcquisition',
  partnerId: 'partnerId',
  commercialRealEstate: 'commercialRealEstate',
  isCallRequested: 'isCallRequested',
  businessPlans: 'businessPlans',
}

const OWNER_INFO_FIELD_NAMES_MAP: Record<TOwnerKey, TOwnerKey> = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  phoneNumber: 'phoneNumber',
  birthDate: 'birthDate',
  businessWeight: 'businessWeight',
  isPrimary: 'isPrimary',
  address: 'address',
  socialSecurityNumber: 'socialSecurityNumber',
  maritalStatusType: 'maritalStatusType',
  hasFelonyConviction: 'hasFelonyConviction',
  hasFiledBankruptcy: 'hasFiledBankruptcy',
  annualDebts: 'annualDebts',
  hasTaxDebt: 'hasTaxDebt',
  bankruptcyFillingDate: 'bankruptcyFillingDate',
  felonyConvictionDate: 'felonyConvictionDate',
  citizenshipStatusExplanation: 'citizenshipStatusExplanation',
  citizenshipStatusType: 'citizenshipStatusType',
  hasCollateralRealEstate: 'hasCollateralRealEstate',
  hasOtherBusinesses: 'hasOtherBusinesses',
  hasPendingLawsuits: 'hasPendingLawsuits',
  hasUnsatisfiedJudgments: 'hasUnsatisfiedJudgments',
  mortgageDebt: 'mortgageDebt',
  pendingLawsuitsExplanation: 'pendingLawsuitsExplanation',
  unsatisfiedJudgmentsExplanation: 'unsatisfiedJudgmentsExplanation',
  realEstatePrice: 'realEstatePrice'
}

const LOCAL_TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP = {
  hasBusinessName: 'hasBusinessName',
  isAddressUnknown: 'isAddressUnknown',
  isOnlyOneEmployee: 'isOnlyOneEmployee'
}

const TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP: Record<
  TTargetBusinessOverviewKey,
  TTargetBusinessOverviewKey
> = {
  name: 'name',
  industryType: 'industryType',
  formationState: 'formationState',
  businessStartedAt: 'businessStartedAt',
  isFranchise: 'isFranchise',
  businessActivityCode: 'businessActivityCode',
  businessEIN: 'businessEIN',
  businessEntityType: 'businessEntityType',
  franchiseName: 'franchiseName',
  employeesCount: 'employeesCount',
  isDBA: 'isDBA',
  legalAddress: 'legalAddress',
  legalAddressSameAsPhysical: 'legalAddressSameAsPhysical',
  phoneNumber: 'phoneNumber',
  physicalAddress: 'physicalAddress',
  dbaName: 'dbaName'
}

const BUSINESS_OVERVIEW_FIELD_NAMES_MAP: Record<
  TBusinessOverviewKey,
  TBusinessOverviewKey
> = {
  name: 'name',
  isDBA: 'isDBA',
  dbaName: 'dbaName',
  phoneNumber: 'phoneNumber',
  legalAddress: 'legalAddress',
  physicalAddress: 'physicalAddress',
  businessActivityCode: 'businessActivityCode',
  employeesCount: 'employeesCount',
  legalAddressSameAsPhysical: 'legalAddressSameAsPhysical',
  businessStartedAt: 'businessStartedAt',
  businessEntityType: 'businessEntityType',
  formationState: 'formationState',
  businessEIN: 'businessEIN',
  annualProfit: 'annualProfit',
  annualProfitYear: 'annualProfitYear',
  annualRevenue: 'annualRevenue',
  annualRevenueYear: 'annualRevenueYear',
  franchiseName: 'franchiseName',
  industryType: 'industryType',
  isFranchise: 'isFranchise'
}

const BUSINESS_ACQUISITION_FIELD_NAMES_MAP: Record<
  TBusinessAcquisitionKey,
  TBusinessAcquisitionKey
> = {
  additionalInformation: 'additionalInformation',
  financialDocuments: 'financialDocuments',
  hasBusinessInformation: 'hasBusinessInformation',
  isBusinessChosen: 'isBusinessChosen'
}

const BORROWERS_SIGNATURE_FIELD_NAMES_MAP: Record<
  TBorrowerSignatureKey,
  TBorrowerSignatureKey
> = {
  signature: 'signature',
  signerName: 'signerName'
}

const ADDRESS_FIELD_NAMES_MAP: Record<
  TAddressOverviewKey,
  TAddressOverviewKey
> = {
  street: 'street',
  city: 'city',
  zipCode: 'zipCode',
  usaStateType: 'usaStateType'
}

const COMMERCIAL_REAL_ESTATE_FIELD_NAMES_MAP: Record<
  TCommercialRealEstateKey,
  TCommercialRealEstateKey
> = {
  price: 'price',
  address: 'address',
  propertyType: 'propertyType'
}

const ANNUAL_AMOUNT_FIELD_NAMES_MAP: Record<
  TAnnualAmountKey,
  TAnnualAmountKey
> = {
  amount: 'amount',
  year: 'year'
}

export {
  FORM_FIELD_NAMES_MAP,
  OWNER_INFO_FIELD_NAMES_MAP,
  TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP,
  LOCAL_TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP,
  BUSINESS_OVERVIEW_FIELD_NAMES_MAP,
  ADDRESS_FIELD_NAMES_MAP,
  BUSINESS_ACQUISITION_FIELD_NAMES_MAP,
  BORROWERS_SIGNATURE_FIELD_NAMES_MAP,
  COMMERCIAL_REAL_ESTATE_FIELD_NAMES_MAP,
  ANNUAL_AMOUNT_FIELD_NAMES_MAP,
}
