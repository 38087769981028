import { FC, ReactNode } from 'react'

import { FormInstance } from 'antd'

import { TFormSubStep } from '@/types/formSteps'

import AlternativeFunding from '../steps/alternative-funding'
import PartiallyApprovedView from '../steps/partially-approved/PartiallyApprovedView'

interface ISubStepWrapperProps {
  children: ReactNode
  subStep: TFormSubStep
  form: FormInstance
}

const SubStepWrapper: FC<ISubStepWrapperProps> = ({
  children,
  subStep,
  form
}) => {
  const subStepFactory = (subStep: TFormSubStep): ReactNode => {
    switch (subStep) {
      case 'partially-approved':
        return <PartiallyApprovedView form={form} />
      case 'alternative-funding':
        return <AlternativeFunding />
      default:
        return children
    }
  }

  return <>{subStep ? subStepFactory(subStep) : children}</>
}

export default SubStepWrapper
