import { FC } from 'react'

import { Flex } from 'antd'

import ArrowTopRight from '@/components/icons/ArrowTopRight'

import './ShowUncompletedFields.scss'

interface IShowUncompletedFieldsProps {
  onShowUncompletedFields: () => void
}

const ShowUncompletedFields: FC<IShowUncompletedFieldsProps> = ({
  onShowUncompletedFields
}) => {
  return (
    <div className='uncompleted-fields'>
      Please complete all required fields to move forward
      <Flex
        className='uncompleted-fields__warn-text'
        gap={4}
        align='center'
        onClick={onShowUncompletedFields}
      >
        See incomplete fields
        <ArrowTopRight />
      </Flex>
    </div>
  )
}

export default ShowUncompletedFields
