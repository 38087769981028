import { FC } from 'react'

import { NamePath } from 'antd/es/form/interface'

import SecureFormItem from './SecureFormItem'

interface IBusinessEinFormItemProps {
  name: NamePath
  fullName?: NamePath
  required?: boolean
}

const BusinessEinFormItem: FC<IBusinessEinFormItemProps> = ({
  name,
  fullName,
  required = true
}) => {
  const formatValue = (value: string, partFormat = (part: string) => part) => {
    if (!value) return ''

    const sanitized = value.replace(/[^\d-]/g, '')
    const digits = sanitized.replace(/-/g, '')

    const formatted = digits.replace(
      /^(\d{0,2})(\d{0,7})$/,
      (_match, p1, p2) => {
        let res = partFormat(p1)

        if (p2) res += `-${partFormat(p2)}`

        return res
      }
    )

    // check if there is last char - "-" and if it is a suitable place for it
    if (sanitized.endsWith('-') && digits.length == 3) {
      return formatted + '-'
    }

    return formatted
  }

  const validator = (value: string) => {
    if (!value) {
      return Promise.resolve()
    }

    const isValid = /^\d{2}-\d{7}$/.test(value)

    return isValid
      ? Promise.resolve()
      : Promise.reject(
          new Error(
            'Please enter a valid EIN to ensure proper business identification'
          )
        )
  }

  return (
    <SecureFormItem
      name={name}
      fullName={fullName}
      rules={[{ required }]}
      label='Business EIN'
      placeholder='Enter EIN'
      formatRawValue={formatValue}
      validator={validator}
      maxLength={10}
      inputMode='numeric'
    />
  )
}

export default BusinessEinFormItem
