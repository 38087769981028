import { FC } from 'react'

import { Rule } from 'antd/es/form'
import { NamePath } from 'antd/es/form/interface'

import InputFormItem from './default-items/input-form-item'

interface IZipCodeFormItemProps {
  name: NamePath
  rules?: Rule[]
}

const ZipCodeFormItem: FC<IZipCodeFormItemProps> = ({ name, rules }) => {
  return (
    <InputFormItem
      name={name}
      label='Zip Code'
      placeholder='Enter Zip Code'
      autoComplete='postal-code'
      rules={[
        ...(rules ?? [
          {
            required: true,
            message: 'Please enter a Zip Code',
            whitespace: true
          }
        ]),
        {
          validator: async (_, value) => {
            if (!value) {
              return Promise.resolve()
            }

            const isValid = /^\d{5}(-?\d{4})?$/.test(value)

            return isValid
              ? Promise.resolve()
              : Promise.reject(new Error('Zip Code is incorrect'))
          }
        }
      ]}
    />
  )
}

export default ZipCodeFormItem
