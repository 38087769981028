import { FC, HTMLAttributes } from 'react'

const Delete: FC<HTMLAttributes<SVGElement>> = (props) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M9.3032 2L10.8824 2.004C11.5048 2.0792 12.0504 2.372 12.5 2.8664C12.8672 3.2696 13.06 3.7384 13.0712 4.2488H17.4512C17.5975 4.24965 17.7374 4.30851 17.8403 4.41245C17.9432 4.5164 18.0006 4.65694 18 4.8032C18.0004 4.94932 17.9429 5.08965 17.84 5.19343C17.7371 5.2972 17.5973 5.35595 17.4512 5.3568L15.9824 5.356V14.6576C15.9824 16.692 15.2232 18 13.5496 18H6.3352C4.6616 18 3.9144 16.6992 3.9144 14.6576V5.356H2.5488C2.40296 5.35515 2.26337 5.29662 2.16054 5.1932C2.05772 5.08977 2 4.94985 2 4.804C2 4.4976 2.2456 4.2504 2.5488 4.2504H6.9248C6.936 3.8392 7.0888 3.4224 7.368 3.0104C7.784 2.396 8.4336 2.0592 9.3032 2ZM14.8848 5.356H5.0112V14.6576C5.0112 16.168 5.4272 16.8928 6.3352 16.8928H13.5496C14.4608 16.8928 14.8856 16.1616 14.8856 14.6576L14.8848 5.356ZM7.3728 7.072C7.6752 7.072 7.9208 7.32 7.9208 7.6256V14.0656C7.92122 14.2117 7.86369 14.3521 7.76082 14.4558C7.65795 14.5596 7.51812 14.6184 7.372 14.6192C7.22602 14.6181 7.0864 14.5593 6.98369 14.4555C6.88099 14.3518 6.82357 14.2116 6.824 14.0656V7.6256C6.824 7.32 7.0704 7.072 7.3728 7.072ZM9.5528 7.072C9.8568 7.072 10.1016 7.32 10.1016 7.6256V14.0656C10.102 14.2117 10.0445 14.3521 9.94162 14.4558C9.83874 14.5596 9.69892 14.6184 9.5528 14.6192C9.40682 14.6181 9.2672 14.5593 9.16449 14.4555C9.06179 14.3518 9.00437 14.2116 9.0048 14.0656V7.6256C9.0048 7.32 9.2504 7.072 9.5528 7.072ZM11.7352 7.072C12.0376 7.072 12.2832 7.32 12.2832 7.6256V14.0656C12.2836 14.2116 12.2262 14.3518 12.1235 14.4555C12.0208 14.5593 11.8812 14.6181 11.7352 14.6192C11.5891 14.6184 11.4493 14.5596 11.3464 14.4558C11.2435 14.3521 11.186 14.2117 11.1864 14.0656V7.6256C11.186 7.47948 11.2435 7.33915 11.3464 7.23537C11.4493 7.1316 11.5891 7.07285 11.7352 7.072ZM9.3408 3.1056C8.8272 3.1416 8.4888 3.3168 8.2736 3.6352C8.1152 3.868 8.036 4.0696 8.0232 4.2496L11.9736 4.2488C11.9624 4.016 11.8704 3.8112 11.692 3.6152C11.4152 3.3112 11.108 3.1464 10.8176 3.1072L9.3408 3.1056Z'
        fill='#E10004'
      />
    </svg>
  )
}

export default Delete
