import React, { ReactElement, useEffect, useState } from 'react'

import { useTypedDispatch, useTypedSelector } from '@/hooks/redux'
import { changeStep } from '@/redux/features/timeLineSlice'
import { TStepStatus, TStepStatuses } from '@/types/stepStatuses'
import { timeLineSteps } from '@/utils/timeLineSteps'

import TimeLineItem from './time-line-item/TimeLineItem'

import './TimeLine.scss'

interface ITimeLineProps {
  onChangeStep?: () => void
}

const TimeLine: React.FC<ITimeLineProps> = ({ onChangeStep }) => {
  const dispatch = useTypedDispatch()

  const { currentStep, stepIndexes } = useTypedSelector(({ timeLine }) => ({
    currentStep: timeLine.timelineStep,
    stepIndexes: timeLine.stepIndexes
  }))
  const [stepStatuses, setStepStatuses] = useState<TStepStatuses>({
    'basic-information': 'active',
    'business-information': 'not-active',
    'owner-information': 'not-active',
    review: 'not-active'
  })

  useEffect(() => {
    if (['active', 'completed'].includes(stepStatuses[currentStep])) {
      return
    }

    switch (currentStep) {
      case 'business-information':
        setStepStatuses({
          'basic-information': 'completed',
          'business-information': 'active',
          'owner-information': 'not-active',
          review: 'not-active'
        })
        break
      case 'owner-information':
        setStepStatuses({
          'basic-information': 'completed',
          'business-information': 'completed',
          'owner-information': 'active',
          review: 'not-active'
        })
        break
      case 'review':
        setStepStatuses({
          'basic-information': 'completed',
          'business-information': 'completed',
          'owner-information': 'completed',
          review: 'active'
        })
        break
      default:
        setStepStatuses({
          'basic-information': 'active',
          'owner-information': 'not-active',
          'business-information': 'not-active',
          review: 'not-active'
        })
    }
  }, [currentStep])

  const handleChangeStep = (step: keyof TStepStatuses) => () => {
    switch (step) {
      case 'basic-information':
        dispatch(changeStep({ currentStep: stepIndexes.basicInfo[0] }))
        break
      case 'business-information':
        dispatch(changeStep({ currentStep: stepIndexes.businessInfo[0] }))
        break
      case 'owner-information':
        dispatch(changeStep({ currentStep: stepIndexes.ownerInfo[0] }))
        break
      case 'review':
        dispatch(changeStep({ currentStep: stepIndexes.reviewSteps[0] }))
        break
      default:
        break
    }
    onChangeStep?.()
  }

  return (
    <div className={'time-line-desktop__content'}>
      <div className={'time-line-desktop__steps'}>
        {timeLineSteps.map((step, index, array) => {
          const stepStatus: TStepStatus = stepStatuses[step.key]
          let icon: ReactElement

          switch (stepStatus) {
            case 'active':
              icon = step.activeDesktopIcon
              break
            case 'not-active':
              icon = step.notActiveDesktopIcon
              break
            case 'completed':
              icon = step.completedDesktopIcon
              break
            default:
              icon = step.activeDesktopIcon
          }

          return (
            <React.Fragment key={step.key}>
              <TimeLineItem
                description={step.description}
                icon={icon}
                isActive={['active', 'completed'].includes(stepStatus)}
                title={step.title}
                onClick={handleChangeStep(step.key)}
              />

              {index !== array.length - 1 && (
                <div
                  className={`time-line-desktop__divider ${
                    stepStatus === 'completed' ? 'green' : ''
                  }`}
                ></div>
              )}
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default TimeLine
