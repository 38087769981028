import { FC, useRef, useState } from 'react'

import { Form, Select, SelectProps } from 'antd'
import { Rule } from 'antd/es/form'
import { NamePath } from 'antd/es/form/interface'

import { ArrowDown } from '@/components/icons/ArrowDown'
import { getArticle } from '@/utils/textUtils'

import './SelectFormItem.scss'

interface ISelectFormItemProps extends SelectProps {
  name: NamePath
  fullName?: NamePath
  label?: string
  rules?: Rule[]
  options: { label: string; value: any }[]
  defaultValue?: any
}

const SelectFormItem: FC<ISelectFormItemProps> = ({
  name,
  fullName,
  options,
  rules,
  defaultValue,
  label = '',
  placeholder = 'Select from the list',
  ...rest
}) => {
  const form = Form.useFormInstance()

  const [open, setOpen] = useState(false)

  const selectedByKeyValue = useRef('')

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (rest.showSearch) {
      return
    }

    if (e.key === 'Enter' && selectedByKeyValue.current) {
      e.stopPropagation()

      form.setFieldValue(fullName ?? name, selectedByKeyValue.current)

      setOpen(false)
      return
    }

    const selectedOption = options.find((option) =>
      option.label.toLowerCase().startsWith(e.key.toLowerCase())
    )

    if (!selectedOption) {
      selectedByKeyValue.current = ''
      return
    }

    form.setFieldValue(fullName ?? name, selectedOption.value)

    const activeElement = document.querySelector(
      `.ant-select-dropdown .ant-select-item[title="${selectedOption.label}"]`
    ) as HTMLElement

    selectedByKeyValue.current = selectedOption.value

    document
      .querySelector('.ant-select-dropdown .ant-select-item-option-active')
      ?.classList.remove('ant-select-item-option-active')

    activeElement?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    activeElement?.classList.add('ant-select-item-option-active')
  }

  return (
    <Form.Item
      name={name}
      className={'select'}
      label={label}
      rules={
        rules ?? [
          {
            required: true,
            message: `Please select ${getArticle(label)} ${label}`
          }
        ]
      }
      initialValue={defaultValue}
    >
      <Select
        open={open}
        virtual={false}
        listItemHeight={45.14}
        options={options}
        suffixIcon={<ArrowDown />}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        onDropdownVisibleChange={(value) => setOpen(value)}
        popupClassName='select-popup'
        {...rest}
      />
    </Form.Item>
  )
}

export default SelectFormItem
