import { FormInstance } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import { NamePath } from 'antd/es/form/interface'

// This hook is used to watch the value of a form field and return it if it exists, otherwise it returns the value of the field from the form
// very useful with animations because returns value immediately
const useCustomWatch = (
  namePath: NamePath,
  options: FormInstance | { form: FormInstance; preserve: boolean }
) => {
  return (
    useWatch(namePath, options) ??
    ('form' in options ? options.form : options).getFieldValue(namePath)
  )
}

export default useCustomWatch
