import { ReactElement, useEffect, useRef, useState } from 'react'

import { Button, Collapse, CollapseProps, Flex, Space } from 'antd'
import { useNavigate } from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'

import { patchApplication } from '@/api/app.service'
import { uploadFiles } from '@/api/file.service'
import BorrowerNameFormItem from '@/components/form/form-items/borrower-name-fom-item/BorrowerNameFormItem'
import NavigationControls from '@/components/navigation-controls/NavigationControls'
import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import { useTypedDispatch, useTypedSelector } from '@/hooks/redux'
import useFieldEdit, { TEditFieldKey } from '@/hooks/useFieldEdit'
import usePatchApplication from '@/hooks/usePatchApplication'
import expandIcon from '@/images/icons/expand-icon.svg'
import { setIsSignatureValid } from '@/redux/features/formStateSlice'
import { changeStep } from '@/redux/features/timeLineSlice'
import EAppRoute from '@/types/appRoutes'
import { EFundingPurposeType } from '@/types/formFieldTypes'
import { IStepProps } from '@/types/types'
import { base64ToBlob } from '@/utils/base64ImageToBlob'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import BasicInformationDescriptions from './BasicInformationDescriptions'
import BusinessOwnersDescriptions from './BusinessOwnersDescriptions'
import BusinessInfoDescription from './business-info-description'
import EditModal from './edit-modal/EditModal'
import TermsAndConditions from './terms-and-conditions/TermsAndConditions'
import { IEditFieldState } from './types'

import './ReviewStep.scss'

const DEFAULT_EDITING_FIELD_STATE = {
  key: null,
  pathModifier: null,
  additionalValue: null,
  timelineStepTitle: null
}

const { borrowerSignature } = FORM_FIELD_NAMES_MAP

const ReviewStep = ({ form, next, prev }: IStepProps): ReactElement => {
  const [fieldToEdit, setFieldToEdit] = useState<IEditFieldState>(
    DEFAULT_EDITING_FIELD_STATE
  )

  const navigate = useNavigate()
  const { applicationId, isSignatureValid } = useTypedSelector(
    ({ formState }) => ({
      applicationId: formState.applicationId,
      isSignatureValid: formState.isSignatureValid
    })
  )

  const {
    input: Input,
    namePath,
    onSave,
    subtitle,
    title
  } = useFieldEdit(fieldToEdit?.key)

  const dispatch = useTypedDispatch()

  const uploadSign = async () => {
    const signBlob = base64ToBlob(
      signRef.current?.getTrimmedCanvas().toDataURL('image/png')!
    )

    const formData = new FormData()
    formData.append('files', signBlob)

    const uploadedSign = await uploadFiles(formData)
    return uploadedSign ? uploadedSign[0] : uploadedSign
  }

  const [patch, isLoading] = usePatchApplication(
    next,
    async ({ borrowerSignature }) => {
      await tryCatchWithMessage(async () => {
        const uploadedSign = await uploadSign()

        await patchApplication(applicationId, [
          {
            path: '/borrowerSignature',
            value: {
              signature: uploadedSign,
              signerName: borrowerSignature.signerName
            }
          },
          {
            path: '/isDraft',
            value: false
          }
        ])

        navigate(EAppRoute.Thanks, { replace: true })
        form.resetFields()
        dispatch(changeStep({ currentStep: 0 }))
      })
    }
  )

  const getEditingFieldNamePath = () => {
    if (!fieldToEdit.pathModifier) {
      return namePath
    }

    if (Array.isArray(fieldToEdit.pathModifier) && Array.isArray(namePath)) {
      return Array.isArray(namePath[0])
        ? namePath.map((path) => fieldToEdit.pathModifier!.concat(path))
        : fieldToEdit.pathModifier.concat(namePath)
    }

    if (
      [fieldToEdit.pathModifier, namePath].every((el) => typeof el === 'string')
    ) {
      return [fieldToEdit.pathModifier, namePath]
    }

    if (Array.isArray(fieldToEdit.pathModifier)) {
      return [...fieldToEdit.pathModifier, namePath]
    }

    if (Array.isArray(namePath)) {
      return [fieldToEdit.pathModifier, ...namePath]
    }
  }

  const signRef = useRef<SignatureCanvas | null>(null)

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: (
        <h2 className='review-step__description-label'>Basic Information</h2>
      ),
      className: 'review-step__description-item',
      children: (
        <BasicInformationDescriptions
          form={form}
          onEditField={(state) =>
            setFieldToEdit({ timelineStepTitle: 'Basic information', ...state })
          }
        />
      )
    },
    {
      key: '3',
      label: (
        <h2 className='review-step__description-label'>Business Information</h2>
      ),
      className: 'review-step__description-item',
      children: (
        <BusinessInfoDescription
          form={form}
          onEditField={(state) =>
            setFieldToEdit({
              timelineStepTitle: 'Business Information',
              ...state
            })
          }
        />
      )
    },
    {
      key: '4',
      label: (
        <h2 className='review-step__description-label'>
          Business Ownership Information
        </h2>
      ),
      className: 'review-step__description-item',
      children: (
        <BusinessOwnersDescriptions
          form={form}
          onEditField={(state) =>
            setFieldToEdit({
              timelineStepTitle: 'Business Ownership Information',
              ...state
            })
          }
        />
      )
    }
  ]

  useEffect(() => {
    if (!signRef.current) {
      return
    }

    const signData = form.getFieldValue([borrowerSignature, 'data'])
    signData && signRef.current.fromDataURL(signData)
  }, [signRef.current])

  return (
    <>
      <div className={'review-step'}>
        <h2 className={'review-step__title'}>Review</h2>
        <p className={'review-step__subtitle'}>
          Take a moment to review everything before submitting.
        </p>
        <Collapse
          items={items}
          bordered={false}
          expandIcon={({ isActive }): ReactElement => (
            <img
              src={expandIcon}
              alt='expand-icon'
              style={{
                transition: 'transform 0.3s ease',
                transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)'
              }}
            />
          )}
          expandIconPosition='end'
        />
        <Space direction='vertical' size={24}>
          <TermsAndConditions />
          <BorrowerNameFormItem />
        </Space>
        <Flex vertical gap={8} className='review-step__sign-container'>
          <Flex justify='space-between' align='center'>
            Signature
            <Button
              onClick={() => {
                dispatch(setIsSignatureValid(false))
                signRef.current?.clear()
              }}
              type={'default'}
            >
              Clear
            </Button>
          </Flex>
          <SignatureCanvas
            ref={(ref) => (signRef.current = ref)}
            onEnd={() => {
              const signData = signRef.current?.toData()
              const signDataLength = signData?.flat(1).length ?? 0

              form.setFieldValue(
                [borrowerSignature, 'data'],
                signRef.current?.getCanvas().toDataURL('image/png')
              )
              dispatch(setIsSignatureValid(signDataLength > 10))
            }}
          />
        </Flex>

        <div className='review-step__nav-controls'>
          <NavigationControls
            isLoading={isLoading}
            onNext={() => {
              patch()
            }}
            onPrev={() => prev()}
            nextBtnText='Submit'
            nextBtnDisabled={!isSignatureValid}
          />
        </div>
      </div>
      <EditModal
        timelineStepTitle={fieldToEdit.timelineStepTitle!}
        title={title}
        subtitle={subtitle}
        namePath={getEditingFieldNamePath()}
        onClose={() => setFieldToEdit(DEFAULT_EDITING_FIELD_STATE)}
        onSave={(newValue) => {
          onSave(newValue, fieldToEdit.additionalValue).finally(() => {
            setFieldToEdit(DEFAULT_EDITING_FIELD_STATE)
          })
        }}
        open={!!fieldToEdit.key}
        mainForm={form}
      >
        <Input {...(fieldToEdit.inputProps ?? {})} />
      </EditModal>
    </>
  )
}

export default ReviewStep
