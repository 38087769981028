import { FC, HTMLAttributes } from 'react'

const DoubleArrow: FC<HTMLAttributes<SVGElement>> = (props) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_5991_499054)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M19 11.9967C18.9999 11.7315 18.8945 11.4772 18.707 11.2897L13.707 6.28966C13.5184 6.1075 13.2658 6.00671 13.0036 6.00899C12.7414 6.01127 12.4906 6.11644 12.3052 6.30184C12.1198 6.48725 12.0146 6.73807 12.0123 7.00026C12.01 7.26246 12.1108 7.51506 12.293 7.70366L16.586 11.9967L12.293 16.2897C12.1108 16.4783 12.01 16.7309 12.0123 16.9931C12.0146 17.2553 12.1198 17.5061 12.3052 17.6915C12.4906 17.8769 12.7414 17.9821 13.0036 17.9843C13.2658 17.9866 13.5184 17.8858 13.707 17.7037L18.707 12.7037C18.8945 12.5162 18.9999 12.2619 19 11.9967ZM13 11.9967C12.9999 11.7315 12.8945 11.4772 12.707 11.2897L7.707 6.28966C7.5184 6.1075 7.2658 6.00671 7.0036 6.00899C6.7414 6.01127 6.49059 6.11644 6.30518 6.30184C6.11977 6.48725 6.0146 6.73807 6.01233 7.00026C6.01005 7.26246 6.11084 7.51506 6.293 7.70366L10.586 11.9967L6.293 16.2897C6.11084 16.4783 6.01005 16.7309 6.01233 16.9931C6.0146 17.2553 6.11977 17.5061 6.30518 17.6915C6.49059 17.8769 6.7414 17.9821 7.0036 17.9843C7.2658 17.9866 7.5184 17.8858 7.707 17.7037L12.707 12.7037C12.8945 12.5162 12.9999 12.2619 13 11.9967Z'
          fill='#ADADAD'
        />
      </g>
      <defs>
        <clipPath id='clip0_5991_499054'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='matrix(0 -1 -1 0 24 24)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default DoubleArrow
