import React, { FC } from 'react'

import { Form } from 'antd'
import { NamePath } from 'antd/es/form/interface'

import { YES_OR_NO_TYPE_OPTIONS } from '@/constants/formFieldOptions'
import useCustomWatch from '@/hooks/useCustomWatch'

import RadioFormItem from './default-items/radio-form-item/RadioFormItem'

interface IOptionalFormItemProps {
  radioName: NamePath
  radioLabel?: string
  children: React.ReactNode
}

const OptionalFormItem: FC<IOptionalFormItemProps> = ({
  radioName,
  radioLabel,
  children
}) => {
  const form = Form.useFormInstance()

  const showOptionalValue = useCustomWatch([radioName], form)

  return (
    <>
      <RadioFormItem
        name={radioName}
        options={YES_OR_NO_TYPE_OPTIONS}
        label={radioLabel}
      />
      {showOptionalValue && children}
    </>
  )
}

export default OptionalFormItem
