import { FC, useState } from 'react'

import { Button, Flex, Form } from 'antd'
import { NamePath } from 'antd/es/form/interface'

import { useTypedSelector } from '@/hooks/redux'
import useValidateStep from '@/hooks/useValidateStep'
import { TFormStep } from '@/types/formSteps'
import { getValueFromObject, isFieldEmpty } from '@/utils/formValidation'

import ShowUncompletedFields from './show-uncompleted-fields/ShowUncompletedFields'

import './NavigationControls.scss'

const STEPS_WITH_HELPER: TFormStep[] = [
  'primary-contact-details',
  'business-info',
  'business-entity-and-ein',
  'business-owner-info'
]

interface INavigationControlsProps {
  onNext: () => void
  onPrev: () => void
  onCatchUncompletedFields?: (err: any) => NamePath
  handleCatchError?: (err: any) => void
  onSuccessValidation?: () => void
  isLoading: boolean
  nextBtnDisabled?: boolean | ((valid: boolean) => boolean)
  nextBtnText?: string
  showPrevBtn?: boolean
}

const NavigationControls: FC<INavigationControlsProps> = ({
  onNext,
  onPrev,
  onCatchUncompletedFields,
  handleCatchError,
  onSuccessValidation,
  isLoading,
  nextBtnDisabled,
  nextBtnText = 'Next',
  showPrevBtn = true
}) => {
  const { currentStep, formSteps } = useTypedSelector(({ timeLine }) => ({
    currentStep: timeLine.formStep,
    formSteps: timeLine.formSteps
  }))

  const [emptyFields, setEmptyFields] = useState(false)

  const form = Form.useFormInstance()

  const handleSuccess = () => {
    setEmptyFields(false)
    onSuccessValidation?.()
  }

  const handleError = (err: any) => {
    const emptyFields = err.errorFields.some(({ name }: { name: NamePath }) => {
      const value = getValueFromObject(name, err.values)

      return isFieldEmpty(value, name)
    })
    setEmptyFields(emptyFields)
    handleCatchError?.(err)
  }

  const isValid = useValidateStep(handleError, handleSuccess)

  const handleShowUncompletedFields = () => {
    form
      .validateFields()
      .then(() => {})
      .catch((err) => {
        let emptyFieldName: NamePath | undefined

        if (onCatchUncompletedFields) {
          emptyFieldName = onCatchUncompletedFields(err)
        } else {
          emptyFieldName = err.errorFields.find(({ name }: { name: NamePath }) => {
            const value = getValueFromObject(name, err.values)
            return isFieldEmpty(value, name)
          })?.name
        }

        setTimeout(() => {
          emptyFieldName &&
            form.scrollToField(emptyFieldName, {
              behavior: 'smooth',
              block: 'center'
            })
        }, 100)
      })
  }

  const showUncompletedFieldsHelper =
    STEPS_WITH_HELPER.includes(formSteps[currentStep]) && emptyFields

  const getIsDisabled = () => {
    if (typeof nextBtnDisabled === 'function') {
      return nextBtnDisabled(isValid)
    }

    return nextBtnDisabled || !isValid
  }

  return (
    <Flex vertical align='center' className='navigation' gap={16}>
      {showUncompletedFieldsHelper && (
        <ShowUncompletedFields
          onShowUncompletedFields={handleShowUncompletedFields}
        />
      )}
      <Flex className='navigation-controls' gap={showPrevBtn ? 14 : 0}>
        {showPrevBtn && (
          <Button
            type={'default'}
            onClick={onPrev}
            className={'navigation-controls__back-btn'}
            loading={isLoading}
          >
            Back
          </Button>
        )}
        <Button
          type={'primary'}
          variant='outlined'
          loading={isLoading}
          onClick={onNext}
          className={'navigation-controls__next-btn'}
          disabled={getIsDisabled()}
        >
          {nextBtnText}
        </Button>
      </Flex>
    </Flex>
  )
}

export default NavigationControls
