import { FC } from 'react'

import { Rule } from 'antd/es/form'
import { NamePath } from 'antd/es/form/interface'

import InputFormItem from './default-items/input-form-item/InputFormItem'

interface IBusinessNameFormItemProps {
  name: NamePath
  rules?: Rule[]
}

const BusinessNameFormItem: FC<IBusinessNameFormItemProps> = ({
  name,
  rules
}) => {
  return (
    <InputFormItem
      name={name}
      label='Business Name'
      placeholder='Enter business name'
      rules={rules}
    />
  )
}

export default BusinessNameFormItem
