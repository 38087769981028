import React from 'react'

import { Col, Form, Row } from 'antd'
import { useWatch } from 'antd/es/form/Form'

import { patchApplication, submitApplication } from '@/api/app.service'
import FundingAmountFormItem from '@/components/form/form-items/FundingAmountFormItem'
import NavigationControls from '@/components/navigation-controls/NavigationControls'
import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import { TOTAL_FUNDING_TEXT } from '@/constants/texts'
import { useTypedDispatch, useTypedSelector } from '@/hooks/redux'
import usePatchApplication from '@/hooks/usePatchApplication'
import { setApplicationId } from '@/redux/features/formStateSlice'
import { IStepProps } from '@/types/types'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import './FundingAmount.scss'

const { totalFunding } = FORM_FIELD_NAMES_MAP

const FundingAmount: React.FC<Omit<IStepProps, 'prev'>> = ({ next }) => {
  const form = Form.useFormInstance()

  const fundingAmount = useWatch(totalFunding, form)
  const applicationId = useTypedSelector(
    ({ formState }) => formState.applicationId
  )
  const dispatch = useTypedDispatch()

  const [patch, isLoading] = usePatchApplication(next, async () => {
    await tryCatchWithMessage(async () => {
      if (!applicationId) {
        const { id } = await submitApplication({
          isDraft: true,
          totalFunding: fundingAmount
        })

        id && dispatch(setApplicationId(id))
      } else {
        await patchApplication(applicationId, [
          {
            path: '/totalFunding',
            value: fundingAmount
          }
        ])
      }
    })
  })

  return (
    <div className={'funding-amount'}>
      <h2 className={'funding-amount__title'}>{TOTAL_FUNDING_TEXT.title}</h2>
      <p className={'funding-amount__subtitle'}>
        {TOTAL_FUNDING_TEXT.subtitle}
      </p>
      <Row style={{ width: '100%' }}>
        <Col span={24}>
          <FundingAmountFormItem />
        </Col>
      </Row>
      <NavigationControls
        isLoading={isLoading}
        onNext={() => {
          patch()
        }}
        showPrevBtn={false}
        onPrev={() => {}}
      />
    </div>
  )
}

export default FundingAmount
