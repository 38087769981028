import { FC } from 'react'

import { Rule } from 'antd/es/form'
import { NamePath } from 'antd/es/form/interface'

import InputFormItem from './default-items/input-form-item/InputFormItem'

interface ICityFormItemProps {
  name: NamePath
  rules?: Rule[]
}

const CityFormItem: FC<ICityFormItemProps> = ({ name, rules }) => {
  return (
    <InputFormItem
      name={name}
      label='City'
      placeholder='Enter Address'
      rules={[
        ...(rules ?? [
          {
            required: true,
            message: 'Please enter a City',
            whitespace: true
          }
        ]),
      ]}
    />
  )
}

export default CityFormItem
