import { FC } from 'react'

import { Flex, Form, Radio, RadioGroupProps } from 'antd'
import { Rule } from 'antd/es/form'
import { NamePath } from 'antd/es/form/interface'

import './RadioFormItem.scss'

interface IRadioFormItemProps extends RadioGroupProps {
  name: NamePath
  options: {
    label: string
    value: any
  }[]
  vertical?: boolean
  label?: string
  rules?: Rule[]
}

const RadioFormItem: FC<IRadioFormItemProps> = ({
  name,
  options,
  label,
  vertical = false,
  rules,
  ...rest
}) => {
  return (
    <Form.Item
      name={name}
      rules={rules ?? [{ required: true }]}
      className={`radio-group ${vertical ? 'radio-group-vertical' : ''}`}
      label={label}
    >
      <Radio.Group {...rest}>
        <Flex style={{ width: '100%' }} wrap='wrap'>
          {options.map(({ label, value }) => (
            <Radio value={value} key={value}>
              {label}
            </Radio>
          ))}
        </Flex>
      </Radio.Group>
    </Form.Item>
  )
}

export default RadioFormItem
