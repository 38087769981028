import { FC, HTMLAttributes } from 'react'

const ImageFile: FC<HTMLAttributes<SVGElement>> = (props) => {
  return (
    <svg
      width='34'
      height='34'
      viewBox='0 0 34 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        opacity='0.5'
        d='M7.08463 2.83594H26.918C28.044 2.83967 29.1228 3.28864 29.919 4.08486C30.7153 4.88108 31.1642 5.95992 31.168 7.08594V19.6376L25.6713 14.1409C24.8614 13.3701 23.7861 12.9401 22.668 12.9401C21.5499 12.9401 20.4745 13.3701 19.6646 14.1409L15.5974 18.2309L14.3394 16.9729C13.5406 16.1796 12.4604 15.7343 11.3346 15.7343C10.2088 15.7343 9.1287 16.1796 8.32988 16.9729L2.83463 22.4709V7.08594C2.83837 5.95992 3.28733 4.88108 4.08356 4.08486C4.87978 3.28864 5.95861 2.83967 7.08463 2.83594Z'
        fill='#E5E5E5'
      />
      <path
        d='M19.6646 14.1383C20.4745 13.3674 21.5499 12.9375 22.668 12.9375C23.7861 12.9375 24.8614 13.3674 25.6713 14.1383L31.168 19.635V26.9166C31.1642 28.0426 30.7153 29.1215 29.919 29.9177C29.1228 30.7139 28.044 31.1629 26.918 31.1666H7.08464C6.51316 31.1663 5.94762 31.0507 5.42184 30.8267C4.89607 30.6028 4.42086 30.2751 4.02464 29.8633L19.6646 14.1383Z'
        fill='#13A55B'
      />
      <path
        d='M2.831 22.4681L8.32625 16.9729C9.12507 16.1796 10.2052 15.7344 11.331 15.7344C12.4568 15.7344 13.5369 16.1796 14.3358 16.9729L15.5938 18.2309L4.02667 29.8603C3.25883 29.0741 2.82958 28.0186 2.831 26.9193V22.4681Z'
        fill='#DBDDE0'
      />
      <circle cx='10.1459' cy='9.59904' r='1.91935' fill='#13A55B' />
    </svg>
  )
}

export default ImageFile
