import React, { FC, HTMLAttributes } from 'react'

interface ISlashedEyeProps extends HTMLAttributes<SVGElement> {
  isSlashed?: boolean
}

const SlashedEye: FC<ISlashedEyeProps> = ({ isSlashed = true, ...rest }) => {
  return (
    <>
      {isSlashed ? (
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          {...rest}
        >
          <g clipPath='url(#clip0_1307_48129)'>
            <path
              d='M14.1083 9.8916L9.8916 14.1083C9.34994 13.5666 9.0166 12.8249 9.0166 11.9999C9.0166 10.3499 10.3499 9.0166 11.9999 9.0166C12.8249 9.0166 13.5666 9.34994 14.1083 9.8916Z'
              stroke='#9B9B9B'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M16.8499 6.80815C15.3915 5.70815 13.7249 5.10815 11.9999 5.10815C9.0582 5.10815 6.31654 6.84149 4.4082 9.84149C3.6582 11.0165 3.6582 12.9915 4.4082 14.1665C5.06654 15.1998 5.8332 16.0915 6.66654 16.8082'
              stroke='#9B9B9B'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M9.0166 18.2753C9.9666 18.6753 10.9749 18.892 11.9999 18.892C14.9416 18.892 17.6833 17.1586 19.5916 14.1586C20.3416 12.9836 20.3416 11.0086 19.5916 9.83364C19.3166 9.40031 19.0166 8.99198 18.7083 8.60864'
              stroke='#9B9B9B'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M14.925 12.5835C14.7083 13.7585 13.75 14.7168 12.575 14.9335'
              stroke='#9B9B9B'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M9.89169 14.1082L3.66669 20.3332'
              stroke='#9B9B9B'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M20.3332 3.6665L14.1082 9.8915'
              stroke='#9B9B9B'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </g>
          <defs>
            <clipPath id='clip0_1307_48129'>
              <rect width='24' height='24' fill='white' />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          {...rest}
        >
          <g clipPath='url(#clip0_1307_48128)'>
            <path
              d='M14.9833 11.9999C14.9833 13.6499 13.6499 14.9833 11.9999 14.9833C10.3499 14.9833 9.0166 13.6499 9.0166 11.9999C9.0166 10.3499 10.3499 9.0166 11.9999 9.0166C13.6499 9.0166 14.9833 10.3499 14.9833 11.9999Z'
              stroke='#9B9B9B'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M11.9999 18.892C14.9415 18.892 17.6832 17.1587 19.5915 14.1587C20.3415 12.9837 20.3415 11.0087 19.5915 9.83368C17.6832 6.83367 14.9415 5.10034 11.9999 5.10034C9.0582 5.10034 6.31654 6.83367 4.4082 9.83368C3.6582 11.0087 3.6582 12.9837 4.4082 14.1587C6.31654 17.1587 9.0582 18.892 11.9999 18.892Z'
              stroke='#9B9B9B'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </g>
          <defs>
            <clipPath id='clip0_1307_48128'>
              <rect width='24' height='24' fill='white' />
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  )
}

export default SlashedEye
