import { FC, ReactElement } from 'react'

import { Modal, ModalProps, Typography } from 'antd'

import EmailParser from '../email-parser/EmailParser'

interface ITermsModalProps extends ModalProps {
  content?: string
}

const TermsModal: FC<ITermsModalProps> = ({ content, ...modalProps }) => {
  return (
    <Modal
      width='100%'
      style={{ maxWidth: '700px' }}
      title={<h3 className='modal__title'>Terms of Service</h3>}
      {...modalProps}
      className='modal'
    >
      <div className='with-custom-scrollbar' style={{ maxHeight: '60vh' }}>
        {content?.split(/\r?\n\r?\n/).map((paragraph, index) => (
          <Typography.Paragraph key={index}>
            <EmailParser text={paragraph} />
          </Typography.Paragraph>
        ))}
        {!content && (
          <>
            <Typography.Paragraph>
              The following Terms of Use govern your use of our website and
              services. By using our website, you agree to be bound by these
              Terms of Use.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Eligibility: To use our services, you must be at least 18 years
              old and a resident of the United States. By using our website, you
              represent and warrant that you meet these eligibility
              requirements.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Loan Application: Our website allows you to apply for a loan by
              submitting personal information, including financial information,
              through our online application. By submitting an application, you
              authorize us to verify your information and run a credit check.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Loan Approval: LoanBud is not a lender. We do not guarantee loan
              approval. We do not control the loan application evaluation
              process of the SBA and non-SBA lenders with whom we work. These
              lenders retain full control of the loan underwriting process. We
              have no authority to bind either a lender or the SBA.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Loan Requirements Subject to Change: The guidelines, credit
              parameters, and loan programs contained on this website are
              subject to change, and therefore, the information contained herein
              is subject to change. Additional requirements or changes may be
              imposed by a lender or the SBA. Client is encouraged to seek the
              advice of its own legal counsel before and during the entirety of
              the loan application process.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Fees and Charges: Our services are free to use. However, if you
              are approved for a loan, the lender may charge fees and interest
              in accordance with their terms and conditions.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Intellectual Property: Our website and its content, including
              text, graphics, logos, and software, are the property of
              LoanBud.com and are protected by U.S. and international copyright
              laws. You may not copy, modify, distribute, or reproduce any of
              our content without our prior written consent.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Prohibited Activities: You agree not to use our website for any
              unlawful or fraudulent purposes. You also agree not to:
            </Typography.Paragraph>
            <Typography.Paragraph>
              Use our website to harass, defame, or harm any person or entity.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Introduce viruses, worms, or other harmful code into our website.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Interfere with the functioning of our website or its servers.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Disclaimer of Warranties: We provide our website and services on
              an “as is” and “as available” basis. We do not make any
              representations or warranties regarding the accuracy, reliability,
              or completeness of our content or services.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Limitation of Liability: We will not be liable for any damages
              arising out of your use of our website or services, including but
              not limited to direct, indirect, incidental, punitive, or
              consequential damages.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Indemnification: You agree to indemnify and hold us harmless from
              any claims, damages, or losses arising out of your use of our
              website or services or your violation of these Terms of Use.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Governing Law: These Terms of Use will be governed by and
              construed in accordance with the laws of the State of Florida,
              without giving effect to any principles of conflicts of law.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Changes to these Terms of Use: We may update these Terms of Use
              from time to time. Any changes will be posted on our website, and
              your continued use of our website and services after the changes
              have been posted will constitute your acceptance of the revised
              Terms of Use.
            </Typography.Paragraph>
            <Typography.Paragraph>
              Contact Us: If you have any questions or concerns about these
              Terms of Use, please contact us at{' '}
              <a
                href='mailto:social@loanbud.com'
                target='_blank'
                rel='noreferrer'
              >
                social@loanbud.com
              </a>
              .
            </Typography.Paragraph>
          </>
        )}
      </div>
    </Modal>
  )
}

export default TermsModal
