import { FC } from 'react'

import { Flex } from 'antd'

import Edit from '@/components/icons/Edit'

import { IEditItemProps } from '../types'

import './EditItem.scss'

const EditItem: FC<IEditItemProps> = ({
  children,
  onEdit,
  editable = true
}) => {
  return (
    <Flex
      gap={8}
      justify='flex-end'
      align='center'
      className={`edit-item ${editable ? 'edit-item-editable' : ''}`}
    >
      {children}
      {editable && <Edit className='edit-item__icon' onClick={onEdit} />}
    </Flex>
  )
}

export default EditItem
