import { patchApplication } from '@/api/app.service'
import FIcoScoreItem from '@/components/form/form-items/FicoScoreFormItem'
import FundingAmountFormItem from '@/components/form/form-items/FundingAmountFormItem'
import FundingPurposeInput from '@/components/form/form-items/FundingPurposeFormItem'
import RadioFormItem from '@/components/form/form-items/default-items/radio-form-item/RadioFormItem'
import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import { TIMEFRAME_TYPE_OPTIONS } from '@/constants/formFieldOptions'
import {
  FICO_SCORE_TEXT,
  FUNDING_PURPOSE_TEXT,
  FUNDS_ACCESS_TIME_TEXT,
  TOTAL_FUNDING_TEXT
} from '@/constants/texts'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import { TGetFieldByKey, TUseFieldEdit } from './types'

const {
  totalFunding,
  fundingPurposeType,
  fundingPurpose,
  ficoScoreType,
  timeframeType
} = FORM_FIELD_NAMES_MAP

const useBasicInfoFieldEdit: TUseFieldEdit = (applicationId, form) => {
  const getSelectedField: TGetFieldByKey = (selectedKey) => {
    switch (selectedKey) {
      case 'total-funding':
        return {
          title: TOTAL_FUNDING_TEXT.title,
          subtitle: TOTAL_FUNDING_TEXT.subtitle,
          input: FundingAmountFormItem,
          namePath: totalFunding,
          onSave: async ({
            totalFunding: totalFundingValue
          }: {
            totalFunding: string
          }) => {
            const rawFundingAmount = Number(totalFundingValue.replace(/,/g, ''))
            await patchApplication(applicationId, [
              {
                path: '/totalFunding',
                value: rawFundingAmount
              }
            ]).then(() => form.setFieldValue(totalFunding, totalFundingValue))
          }
        }
      case 'funding-purpose':
        return {
          title: FUNDING_PURPOSE_TEXT.title,
          subtitle: FUNDING_PURPOSE_TEXT.subtitle,
          input: FundingPurposeInput,
          namePath: [[fundingPurposeType], [fundingPurpose]],
          onSave: async ({
            fundingPurposeType,
            fundingPurpose
          }: {
            fundingPurposeType: string
            fundingPurpose: string
          }) => {
            const values = [
              {
                path: '/fundingPurposeType',
                value: fundingPurposeType
              },
              {
                path: '/fundingPurpose',
                value: fundingPurpose
              }
            ]

            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, values)
            }).then(() =>
              form.setFieldsValue({ fundingPurposeType, fundingPurpose })
            )
          }
        }
      case 'fico-score':
        return {
          title: FICO_SCORE_TEXT.title,
          subtitle: FICO_SCORE_TEXT.subtitle,
          input: FIcoScoreItem,
          namePath: ficoScoreType,
          onSave: async ({ ficoScoreType }: { ficoScoreType: string }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: '/ficoScoreType',
                  value: ficoScoreType
                }
              ]).then(() => form.setFieldsValue({ ficoScoreType }))
            })
          }
        }
      case 'funds-access-time':
        return {
          title: FUNDS_ACCESS_TIME_TEXT.title,
          subtitle: FUNDS_ACCESS_TIME_TEXT.subtitle,
          input: () => (
            <RadioFormItem
              name={timeframeType}
              options={TIMEFRAME_TYPE_OPTIONS}
            />
          ),
          namePath: timeframeType,
          onSave: async ({ timeframeType: timeframeTypeValue }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                { path: '/timeframeType', value: timeframeTypeValue }
              ])
            })
          }
        }
      default:
        return null
    }
  }

  return getSelectedField
}

export default useBasicInfoFieldEdit
