import { useRef, useState } from 'react'

import { patchApplication } from '@/api/app.service'
import { uploadFormFiles } from '@/api/file.service'
import { TFormFile } from '@/utils/formFileList'

import useUpdateEffect from './useUpdateEffect'

const useImmediateFileUpload = (
  files: TFormFile[] | undefined,
  patchPath: string,
  applicationId: string
) => {
  const [isLoading, setIsLoading] = useState(false)

  const uploadedFileAbortControllerRef = useRef(new AbortController())
  const patchAppAbortControllerRef = useRef(new AbortController())

  const uploadFile = async (files: TFormFile[] | undefined) => {
    try {
      let uploadedFile = null

      if (!!files?.length) {
        uploadedFile = (
          await uploadFormFiles(
            files,
            uploadedFileAbortControllerRef.current.signal
          )
        )?.[0]
      }

      const patchInput = [
        {
          path: patchPath,
          value: uploadedFile
        }
      ]

      await patchApplication(
        applicationId,
        patchInput,
        patchAppAbortControllerRef.current.signal
      )
    } catch (error) {
      console.error('error', error)
    }
  }

  useUpdateEffect(() => {
    setIsLoading(true)
    uploadFile(files).finally(() => setIsLoading(false))

    return () => {
      uploadedFileAbortControllerRef.current.abort()
      patchAppAbortControllerRef.current.abort()

      uploadedFileAbortControllerRef.current = new AbortController()
      patchAppAbortControllerRef.current = new AbortController()
    }
  }, [files])

  return { isLoading }
}

export default useImmediateFileUpload
