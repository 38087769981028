import { Dispatch, FC, ReactElement, memo } from 'react'

import { Button, Collapse, CollapseProps, Flex, Form } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import classNames from 'classnames'

import PlusIcon from '@/components/icons/PlusIcon'
import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import expandIcon from '@/images/icons/expand-icon.svg'

import AccordionItemLabel from '../../AccordionItemLabel'
import DeleteOwnerButton from '../../DeleteOwnerButton'
import { IError } from '../../useOwnersValidation'
import OwnerInfoTabs from '../owner-info-tabs/OwnerInfoTabs'

const { owners: ownersFieldName } = FORM_FIELD_NAMES_MAP
interface IOwnershipFieldsProps {
  activeTab: number
  setActiveTab: Dispatch<React.SetStateAction<number>>
  activeAccordionKey: string
  setActiveAccordionKey: Dispatch<React.SetStateAction<string>>
  isSoleProprietor: boolean
  uncompletedOwners: IError[]
}

const OwnershipFields: FC<IOwnershipFieldsProps> = memo(
  ({
    activeTab,
    setActiveTab,
    activeAccordionKey,
    setActiveAccordionKey,
    isSoleProprietor,
    uncompletedOwners
  }) => {
    const owners = useWatch(ownersFieldName) || []

    const ownershipPercentage = owners.reduce(
      (acc: number, val?: { businessWeight?: number }) =>
        acc + (val?.businessWeight ?? 0),
      0
    )

    const isNextTabDisabled = () => {
      const uncompletedOwner = uncompletedOwners.find(
        ({ ownerIndex }) => ownerIndex === Number(activeAccordionKey)
      )

      return !!uncompletedOwner ? !uncompletedOwner.isTabsValid.first : false
    }

    const isOwnershipValid = ownershipPercentage === 100
    return (
      <Form.List name={ownersFieldName}>
        {(fields, { add, remove }) => {
          const items: CollapseProps['items'] = fields.map(
            ({ key, name }, idx) => ({
              key,
              label: <AccordionItemLabel name={name} />,
              className: classNames('business-info__accordion-item', {
                'business-info__uncompleted-error':
                uncompletedOwners.some(
                    ({ ownerIndex }) => ownerIndex === idx
                  ) && Number(activeAccordionKey) !== idx
              }),
              forceRender: true,
              children: (
                <Flex vertical>
                  <OwnerInfoTabs
                    name={name}
                    currentOwnershipPercentage={
                      owners[idx]?.businessWeight ?? 0
                    }
                    disabledNextTab={isNextTabDisabled()}
                    ownershipPercentage={ownershipPercentage}
                    idx={idx}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />

                  {idx > 0 && (
                    <DeleteOwnerButton
                      name={name}
                      onRemove={() => {
                        remove(name)
                        setActiveAccordionKey(fields.at(-2)!.key.toString())
                        setActiveTab(0)
                      }}
                    />
                  )}
                </Flex>
              )
            })
          )

          const addOwner = () => {
            const newKey = fields.at(-1)!.key + 1
            add(newKey)
            setActiveAccordionKey(newKey.toString())
            setActiveTab(0)
          }

          return (
            <>
              {isSoleProprietor ? (
                fields.map(({ name }, idx) => (
                  <OwnerInfoTabs
                    key={idx}
                    name={name}
                    disabledNextTab={isNextTabDisabled()}
                    currentOwnershipPercentage={
                      owners[idx]?.businessWeight ?? 0
                    }
                    ownershipPercentage={ownershipPercentage}
                    idx={idx}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                ))
              ) : (
                <Collapse
                  items={items}
                  activeKey={activeAccordionKey}
                  onChange={(key: string[]) => {
                    setActiveTab(0)
                    setActiveAccordionKey(key[0])
                  }}
                  accordion
                  expandIcon={({ isActive }): ReactElement => (
                    <img
                      src={expandIcon}
                      alt='expand-icon'
                      style={{
                        transition: 'transform 0.3s ease',
                        transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)'
                      }}
                    />
                  )}
                  expandIconPosition='end'
                />
              )}
              {!isSoleProprietor && (
                <>
                  <div className={'business-info__total-ownership-container'}>
                    <h4
                      className={'business-info__total-ownership'}
                    >{`Total: ${ownershipPercentage}% ownership`}</h4>

                    {!isOwnershipValid && (
                      <p className={'business-info__total-ownership-warning'}>
                        Please ensure the total ownership equals 100%
                      </p>
                    )}
                  </div>
                  {!!ownershipPercentage && !isOwnershipValid && (
                    <Form.Item style={{ marginBottom: 0 }}>
                      <Button
                        type='dashed'
                        onClick={addOwner}
                        block
                        color='primary'
                        style={{
                          background: 'var(--brand-secondary)'
                        }}
                        size='large'
                        className={'ownership__add-owner'}
                        icon={<PlusIcon disabled={isOwnershipValid} />}
                        disabled={isOwnershipValid}
                      >
                        Add additional owners
                      </Button>
                    </Form.Item>
                  )}
                </>
              )}
            </>
          )
        }}
      </Form.List>
    )
  }
)

export default OwnershipFields
