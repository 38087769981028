import { FC } from 'react'

const UploadIcon: FC = () => {
  return (
    <svg
      width='53'
      height='52'
      viewBox='0 0 53 52'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M26.5 6.5C19.805 6.5 14.54 11.5863 13.8039 18.0781C12.3882 18.3071 11.0607 18.9142 9.96153 19.8352C8.86233 20.7562 8.03227 21.9569 7.559 23.3106C3.5615 24.4627 0.5 28.028 0.5 32.5C0.5 37.9015 4.8485 42.25 10.25 42.25H42.75C48.1515 42.25 52.5 37.9015 52.5 32.5C52.5 29.64 51.1106 27.079 49.0972 25.2882C48.7202 19.578 44.1654 15.0215 38.434 14.7258C36.4775 9.96613 31.9892 6.5 26.5 6.5ZM26.5 9.75C30.9882 9.75 34.5762 12.6262 35.8438 16.705L36.2012 17.875H37.875C42.3519 17.875 46 21.5231 46 26V26.8125L46.6597 27.3211C47.456 27.9314 48.103 28.7148 48.5518 29.612C49.0005 30.5093 49.2393 31.4968 49.25 32.5C49.25 36.1985 46.4485 39 42.75 39H10.25C6.5515 39 3.75 36.1985 3.75 32.5C3.75 29.2175 6.10625 26.6695 9.08 26.1527L10.1476 25.9496L10.3507 24.8804C10.8382 22.6915 12.7785 21.125 15.125 21.125H16.75V19.5C16.75 14.0238 21.0238 9.75 26.5 9.75ZM27.67 19.9534C27.0149 19.3301 25.9842 19.3324 25.33 19.9566L19.9995 25.2871C19.3536 25.933 19.3536 26.9802 19.9995 27.6261C20.6458 28.2724 21.6938 28.2719 22.3395 27.6251L24.875 25.0851V34.125C24.875 35.0225 25.6025 35.75 26.5 35.75C27.3975 35.75 28.125 35.0225 28.125 34.125V25.0851L30.6603 27.6226C31.3062 28.2691 32.354 28.2693 33.0003 27.6231C33.6463 26.9771 33.6463 25.9297 33.0003 25.2836L27.67 19.9534Z'
        fill='var(--brand-primary)'
      />
    </svg>
  )
}

export default UploadIcon
