import { FC, HTMLAttributes } from 'react'

const Close: FC<HTMLAttributes<SVGElement>> = (props) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ minWidth: 24 }}
      {...props}
    >
      <path
        d='M5 5L19 19M5.00003 19L12 12L19 5'
        stroke='var(--tertiary-gray)'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default Close
