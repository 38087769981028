import { FC } from 'react'

import { Form } from 'antd'
import { NamePath } from 'antd/es/form/interface'

import useCustomWatch from '@/hooks/useCustomWatch'

import CheckboxFormItem from './default-items/checkbox-form-item/CheckboxFormItem'
import InputFormItem from './default-items/input-form-item'

interface IBusinessAsDBAFormItemProps {
  isDbaItemName: NamePath
  dbaNameItemName: NamePath
  required?: boolean
}

const BusinessAsDBAFormItem: FC<IBusinessAsDBAFormItemProps> = ({
  dbaNameItemName,
  isDbaItemName,
  required = true
}) => {
  const form = Form.useFormInstance()

  const businessAsDbaValue = useCustomWatch(isDbaItemName, form)

  return (
    <>
      <CheckboxFormItem
        name={isDbaItemName}
        onChange={(e) =>
          !e.target.checked && form.setFieldValue(dbaNameItemName, undefined)
        }
        rules={[{ required: false }]}
      >
        Are you doing business as a DBA?
      </CheckboxFormItem>
      {businessAsDbaValue && (
        <InputFormItem
          name={dbaNameItemName}
          label={`Name You're Doing Business As`}
          placeholder='Enter Business Name'
          rules={[{ required }]}
        />
      )}
    </>
  )
}

export default BusinessAsDBAFormItem
