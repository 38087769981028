import { FC } from 'react'

import { Form, Input, InputProps } from 'antd'
import { NamePath } from 'antd/es/form/interface'

import mailIcon from '@/images/icons/mail-icon.svg'

import './EmailInputFormItem.scss'

interface IEmailInputFormItemProps extends InputProps {
  name: NamePath
}

const EmailInputFormItem: FC<IEmailInputFormItemProps> = ({ name, ...rest }) => {
  return (
    <Form.Item
      name={name}
      label='Email'
      className={'email-input'}
      rules={[
        {
          required: true
        },
        {
          // custom email validation to match backend validation
          validator: async (_, value) => {
            if (!value) {
              return Promise.resolve()
            }

            const isValid =
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)

            return isValid
              ? Promise.resolve()
              : Promise.reject(
                  new Error('The email should include “@” and “.”')
                )
          }
        }
      ]}
    >
      <Input
        placeholder='name@email.com'
        autoComplete='email'
        type='email'
        inputMode='email'
        prefix={<img src={mailIcon} alt={'mail-icon'} />}
        {...rest}
      />
    </Form.Item>
  )
}

export default EmailInputFormItem
