import { FC } from 'react'

import { Form, Input } from 'antd'

import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import dollarIcon from '@/images/icons/dollar.svg'

import MoneyAmountFormItem from './MoneyAmountFormItem'

const { totalFunding } = FORM_FIELD_NAMES_MAP

const FundingAmountFormItem: FC = () => {
  return (
    <MoneyAmountFormItem
      name={totalFunding}
      maxLength={11}
      rules={[
        {
          required: true
        },
        {
          pattern: /^[0-9,]+$/,
          message: 'The amount must be a valid number with optional commas'
        },
        {
          validator: (_, value: number) => {
            const sanitizedValue = value
              ? Number(value.toString().replace(/,/g, ''))
              : 0
            if (
              !value ||
              (sanitizedValue >= 50000 && sanitizedValue <= 20000000)
            ) {
              return Promise.resolve()
            }
            return Promise.reject(
              new Error(
                sanitizedValue < 50000
                  ? 'Minimum amount should exceed $50,000'
                  : 'Maximum amount should not exceed $20,000,000.'
              )
            )
          }
        }
      ]}
      placeholder='100,000'
      type='text'
      prefix={
        <img src={dollarIcon} alt='dollar' className='funding-amount__prefix' />
      }
    />
  )
}

export default FundingAmountFormItem
