import { FC } from 'react'

import { Button, Col, Form, RadioChangeEvent, Row } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import dayjs from 'dayjs'

import CitizenshipFormItem from '@/components/form/form-items/CitizenshipFormItem'
import MoneyAmountFormItem from '@/components/form/form-items/MoneyAmountFormItem'
import DatePickerFormItem from '@/components/form/form-items/default-items/date-picker-form-item/DatePickerFormItem'
import { NumberInputFormItem } from '@/components/form/form-items/default-items/input-form-item'
import RadioFormItem from '@/components/form/form-items/default-items/radio-form-item/RadioFormItem'
import TextAreaFormInput from '@/components/form/form-items/default-items/textarea-form-input/TextAreaFormInput'
import Close from '@/components/icons/Close'
import PlusIcon from '@/components/icons/PlusIcon'
import {
  ANNUAL_AMOUNT_FIELD_NAMES_MAP,
  FORM_FIELD_NAMES_MAP,
  OWNER_INFO_FIELD_NAMES_MAP
} from '@/constants/formFieldNames'
import { YES_OR_NO_TYPE_OPTIONS } from '@/constants/formFieldOptions'
import useCustomWatch from '@/hooks/useCustomWatch'
import { EFundingPurposeType } from '@/types/formFieldTypes'

import './LegalInfoDetails.scss'

interface IPrimaryInfoFieldsProps {
  name: number
  isPrimaryOwner: boolean
}

const {
  hasFiledBankruptcy,
  hasFelonyConviction,
  bankruptcyFillingDate,
  felonyConvictionDate,
  citizenshipStatusType,
  citizenshipStatusExplanation,
  hasCollateralRealEstate,
  mortgageDebt,
  realEstatePrice,
  annualDebts,
  hasPendingLawsuits,
  pendingLawsuitsExplanation,
  hasUnsatisfiedJudgments,
  unsatisfiedJudgmentsExplanation,
  hasOtherBusinesses,
  hasTaxDebt
} = OWNER_INFO_FIELD_NAMES_MAP

const { amount, year } = ANNUAL_AMOUNT_FIELD_NAMES_MAP

const { owners: ownersFieldName, fundingPurposeType } = FORM_FIELD_NAMES_MAP

const LegalInfoDetails: FC<IPrimaryInfoFieldsProps> = ({
  name,
  isPrimaryOwner
}) => {
  const form = Form.useFormInstance()

  const hasFiledBankruptcyValue = useWatch(
    [ownersFieldName, name, hasFiledBankruptcy],
    form
  )
  const hasFelonyConvictionValue = useWatch(
    [ownersFieldName, name, hasFelonyConviction],
    form
  )

  const hasTaxDebtValue = useWatch([ownersFieldName, name, hasTaxDebt], form)

  const hasPendingLawsuitsValue = useWatch(
    [ownersFieldName, name, hasPendingLawsuits],
    form
  )
  const hasUnsatisfiedJudgmentsValue = useWatch(
    [ownersFieldName, name, hasUnsatisfiedJudgments],
    form
  )

  const hasCollateralRealEstateValue = useWatch(
    [ownersFieldName, name, hasCollateralRealEstate],
    form
  )

  const fundingPurposeValue = useCustomWatch(fundingPurposeType, {
    form,
    preserve: true
  })

  const withRestoreValue =
    (callback: () => void, triggerValue = false) =>
    (e: RadioChangeEvent) => {
      if (e.target.value === triggerValue) {
        callback()
      }
    }

  return (
    <Row>
      <Col span={24}>
        <CitizenshipFormItem
          statusName={[name, citizenshipStatusType]}
          statusFullName={[ownersFieldName, name, citizenshipStatusType]}
          statusExplanationName={[name, citizenshipStatusExplanation]}
          required={isPrimaryOwner}
        />
      </Col>
      {fundingPurposeValue === EFundingPurposeType.StartBusiness && (
        <Col span={24}>
          <RadioFormItem
            name={[name, hasCollateralRealEstate]}
            options={YES_OR_NO_TYPE_OPTIONS}
            label='Do you own any real estate that can be pledged as collateral?'
            rules={[{ required: isPrimaryOwner }]}
            onChange={withRestoreValue(() => {
              form.setFieldValue(
                [ownersFieldName, name, realEstatePrice],
                undefined
              )
              form.setFieldValue(
                [ownersFieldName, name, mortgageDebt],
                undefined
              )
            })}
          />
          {hasCollateralRealEstateValue && (
            <>
              <MoneyAmountFormItem
                label='How much is it worth?'
                rules={[{ required: isPrimaryOwner }]}
                name={[name, realEstatePrice]}
              />
              <MoneyAmountFormItem
                label='If you have a mortgage, how much do you owe?'
                name={[name, mortgageDebt]}
                rules={[{ required: false }]}
              />
            </>
          )}
        </Col>
      )}
      <Col span={24}>
        <RadioFormItem
          name={[name, hasFiledBankruptcy]}
          options={YES_OR_NO_TYPE_OPTIONS}
          label='Have you ever filed bankruptcy?'
          rules={[{ required: isPrimaryOwner }]}
          onChange={withRestoreValue(() => {
            form.setFieldValue(
              [ownersFieldName, name, bankruptcyFillingDate],
              undefined
            )
          })}
        />
      </Col>
      {(hasFiledBankruptcyValue ?? false) && (
        <Col span={24}>
          <DatePickerFormItem
            name={[name, bankruptcyFillingDate]}
            label='Specify the date of bankruptcy filing'
            rules={[{ required: isPrimaryOwner }]}
          />
        </Col>
      )}
      <Col span={24}>
        <RadioFormItem
          name={[name, hasFelonyConviction]}
          options={YES_OR_NO_TYPE_OPTIONS}
          label='Have you ever been convicted of a felony?'
          rules={[{ required: isPrimaryOwner }]}
          onChange={withRestoreValue(() => {
            form.setFieldValue(
              [ownersFieldName, name, felonyConvictionDate],
              undefined
            )
          })}
        />
      </Col>
      <Col span={24}>
        {(hasFelonyConvictionValue ?? false) && (
          <DatePickerFormItem
            name={[name, felonyConvictionDate]}
            label='Specify the date of conviction'
            rules={[{ required: isPrimaryOwner }]}
          />
        )}
      </Col>
      <Col span={24}>
        <RadioFormItem
          name={[name, hasTaxDebt]}
          options={YES_OR_NO_TYPE_OPTIONS}
          label='Do you owe any money to the IRS, for business taxes or personal taxes, including if you are on a payment plan?'
          rules={[{ required: isPrimaryOwner }]}
          onChange={withRestoreValue(() => {
            form.setFieldValue(
              [ownersFieldName, name, annualDebts],
              [{ amount: undefined, year: undefined }]
            )
          })}
        />
      </Col>
      {hasTaxDebtValue && (
        <Col span={24}>
          <div className='legal-info__helper-text'>
            Please list the amount owed and for which years.
          </div>
          <Form.List
            initialValue={[{ amount: undefined, year: undefined }]}
            name={[name, annualDebts]}
          >
            {(fields, { add, remove }) => {
              const handleRemove = (key: number) => () => {
                if (fields.length === 1) {
                  form.setFieldValue([ownersFieldName, name, hasTaxDebt], false)
                  form.setFieldValue(
                    [ownersFieldName, name, annualDebts],
                    [{ amount: undefined, year: undefined }]
                  )
                }
                remove(key)
              }

              return (
                <>
                  {fields.map(({ key, name }) => (
                    <div key={key} className='annual-debt'>
                      <MoneyAmountFormItem
                        name={[name, amount]}
                        label='Amount'
                        rules={[{ required: isPrimaryOwner }]}
                        placeholder='Enter Amount'
                      />
                      <NumberInputFormItem
                        label='Year'
                        placeholder='Enter Year'
                        max={Number(dayjs().format('YYYY'))}
                        controls={false}
                        name={[name, year]}
                        rules={[{ required: isPrimaryOwner }]}
                      />
                      <Close
                        className='annual-debt__close-icon'
                        onClick={handleRemove(key)}
                      />
                    </div>
                  ))}
                  <Button
                    type='dashed'
                    color='primary'
                    size='small'
                    className='legal-info__add-btn'
                    icon={<PlusIcon />}
                    onClick={() => add({ amount: undefined, year: undefined })}
                  >
                    Add Another Year
                  </Button>
                </>
              )
            }}
          </Form.List>
        </Col>
      )}
      <Col span={24}>
        <div className='legal-info__helper-text'>
          Are the business or any owners currently involved in:
        </div>
        <RadioFormItem
          name={[name, hasPendingLawsuits]}
          options={YES_OR_NO_TYPE_OPTIONS}
          label='Any pending lawsuits or legal proceedings?'
          rules={[{ required: isPrimaryOwner }]}
          onChange={withRestoreValue(() => {
            form.setFieldValue(
              [ownersFieldName, name, pendingLawsuitsExplanation],
              undefined
            )
          })}
        />
        {hasPendingLawsuitsValue && (
          <TextAreaFormInput
            name={[name, pendingLawsuitsExplanation]}
            label='Please explain'
            placeholder='Enter text'
            autoSize={{ maxRows: 2 }}
            rules={[{ required: isPrimaryOwner }]}
          />
        )}

        <RadioFormItem
          name={[name, hasUnsatisfiedJudgments]}
          options={YES_OR_NO_TYPE_OPTIONS}
          label='Any unsatisfied judgments or court orders?'
          rules={[{ required: isPrimaryOwner }]}
          onChange={withRestoreValue(() => {
            form.setFieldValue(
              [ownersFieldName, name, unsatisfiedJudgmentsExplanation],
              undefined
            )
          })}
        />
        {hasUnsatisfiedJudgmentsValue && (
          <TextAreaFormInput
            name={[name, unsatisfiedJudgmentsExplanation]}
            label='Please explain'
            placeholder='Enter text'
            autoSize={{ maxRows: 2 }}
            rules={[{ required: isPrimaryOwner }]}
          />
        )}
      </Col>
      <Col span={24}>
        <RadioFormItem
          name={[name, hasOtherBusinesses]}
          options={YES_OR_NO_TYPE_OPTIONS}
          label='Do you have a 20% or greater ownership stake in any other businesses?'
          rules={[{ required: isPrimaryOwner }]}
        />
      </Col>
    </Row>
  )
}

export default LegalInfoDetails
