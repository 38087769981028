import { FC } from 'react'

import { Col, Form, Row } from 'antd'

import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import { FUNDING_PURPOSE_OPTIONS } from '@/constants/formFieldOptions'
import useCustomWatch from '@/hooks/useCustomWatch'
import { EFundingPurposeType } from '@/types/formFieldTypes'

import CustomLabel from '../custom-label/CustomLabel'

import SelectFormItem from './default-items/select-form-item/SelectFormItem'
import TextAreaFormInput from './default-items/textarea-form-input/TextAreaFormInput'

const { fundingPurposeType, fundingPurpose } = FORM_FIELD_NAMES_MAP

const FundingPurposeFormItem: FC = () => {
  const form = Form.useFormInstance()

  const fundPurpose = useCustomWatch(fundingPurposeType, form)

  return (
    <Row style={{ width: '100%' }}>
      <Col span={24}>
        <SelectFormItem
          name={fundingPurposeType}
          options={FUNDING_PURPOSE_OPTIONS}
          placeholder='Select from the list'
          rules={[{ required: true, message: 'Please select the loan purpose' }]}
        />
      </Col>
      {[
        EFundingPurposeType.Other,
        EFundingPurposeType.DebtRefinancing
      ].includes(fundPurpose) && (
        <Col span={24}>
          <CustomLabel title='Please use the space below to describe how you will use this loan.' />
          <TextAreaFormInput
            showCount={true}
            name={fundingPurpose}
            placeholder='Enter Additional Information'
            rules={[
              {
                required: true,
                message: 'Please enter Additional Information',
                whitespace: true
              }
            ]}
          />
        </Col>
      )}
    </Row>
  )
}

export default FundingPurposeFormItem
