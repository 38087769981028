import { ReactElement } from 'react'

import { Descriptions, DescriptionsProps, Flex } from 'antd'
import { FormInstance } from 'antd/lib'
import dayjs from 'dayjs'
import { getFormattedNumber } from 'react-phone-hooks'

import DEFAULT_DATE_FORMAT from '@/constants/defaultDateFormat'
import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import {
  CITIZENSHIP_STATUS_OPTIONS_MAP,
  MARTIAL_STATUS_OPTIONS_MAP
} from '@/constants/formFieldOptions'
import { EFundingPurposeType, TFormOwnerField } from '@/types/formFieldTypes'
import getFormattedAddress from '@/utils/getFormattedAddress'
import getFormattedPhoneNumber from '@/utils/getFormattedPhoneNumber'
import { formatMoneyValue, optionalItem } from '@/utils/reviewUtils'

import { EditItem, RichEditItem } from './edit-item'
import SectionLabel from './section-label/SectionLabel'
import { IEditFieldState } from './types'

const { owners: ownersFieldName, fundingPurposeType } = FORM_FIELD_NAMES_MAP

interface IBusinessOwnersDescriptionsProps {
  form: FormInstance
  onEditField: (state: IEditFieldState) => void
}

const BusinessOwnersDescriptions = ({
  form,
  onEditField
}: IBusinessOwnersDescriptionsProps): ReactElement => {
  const owners: TFormOwnerField[] = form.getFieldValue(ownersFieldName) || []
  const fundingPurpose = form.getFieldValue(fundingPurposeType)
  const items: DescriptionsProps['items'] = []

  const getYesOrNoLabel = (value?: boolean) => {
    if (value == null) {
      return ''
    }

    return value ? 'Yes' : 'No'
  }

  owners.forEach(
    (
      {
        firstName,
        lastName,
        businessWeight,
        email,
        phoneNumber,
        birthDate,
        address,
        socialSecurityNumber,
        hasFiledBankruptcy,
        bankruptcyFillingDate,
        hasFelonyConviction,
        felonyConvictionDate,
        maritalStatusType = '',
        citizenshipStatusType = '',
        hasTaxDebt,
        annualDebts = [],
        hasPendingLawsuits,
        pendingLawsuitsExplanation,
        hasUnsatisfiedJudgments,
        unsatisfiedJudgmentsExplanation,
        hasOtherBusinesses,
        hasCollateralRealEstate = false,
        realEstatePrice
      },
      idx
    ) => {
      const ownerNumber = idx + 1

      const handleEdit = (key: IEditFieldState['key']) => () => {
        onEditField({
          key: key,
          pathModifier: [ownersFieldName, idx.toString()],
          additionalValue: { index: idx },
          inputProps: { index: idx }
        })
      }

      items.push(
        {
          key: `${idx}-0`,
          label: `Business Owner ${owners.length > 1 ? ownerNumber : ''}`,
          children: undefined,
          className: 'owner-title'
        },
        {
          key: `${idx}=primary-details-label`,
          className: 'review-step__section-label',
          children: <SectionLabel>Primary Details</SectionLabel>
        },
        {
          key: `${idx}-1`,
          label: 'First Name',
          children: (
            <EditItem onEdit={handleEdit('owner-info-first-name')}>
              {firstName}
            </EditItem>
          )
        },
        {
          key: `${idx}-2`,
          label: 'Last Name',
          children: (
            <EditItem onEdit={handleEdit('owner-info-last-name')}>
              {lastName}
            </EditItem>
          )
        },
        {
          key: `${idx}-3`,
          label: 'Ownership Percentage',
          children: <EditItem editable={false}>{`${businessWeight}%`}</EditItem>
        },
        {
          key: `${idx}-4`,
          label: 'Email',
          children: (
            <EditItem onEdit={handleEdit('owner-info-email')}>{email}</EditItem>
          )
        },
        ...optionalItem(idx !== 0, {
          key: `${idx}-5`,
          label: 'Phone',
          children: (
            <EditItem onEdit={handleEdit('owner-info-phone')}>
              {getFormattedNumber(getFormattedPhoneNumber(phoneNumber))}
            </EditItem>
          )
        }),
        ...optionalItem(idx !== 0, {
          key: `${idx}-6`,
          label: 'Date of Birth',
          children: (
            <EditItem onEdit={handleEdit('owner-info-birth-date')}>
              {birthDate
                ? dayjs(birthDate).format(DEFAULT_DATE_FORMAT.format)
                : ''}
            </EditItem>
          )
        }),
        {
          key: `${idx}-7`,
          label: 'Home Address',
          children: (
            <EditItem onEdit={handleEdit('owner-info-address')}>
              {getFormattedAddress(address)}
            </EditItem>
          )
        },
        {
          key: `${idx}-ssn`,
          label: 'SSN',
          children: (
            <EditItem onEdit={handleEdit('owner-info-ssn')}>
              {socialSecurityNumber?.replace(/\d/g, '*')}
            </EditItem>
          )
        },
        {
          key: `${idx}-martial-status`,
          label: 'Current marital status',
          children: (
            <EditItem onEdit={handleEdit('owner-info-martial-status')}>
              {MARTIAL_STATUS_OPTIONS_MAP[maritalStatusType]}
            </EditItem>
          )
        },
        {
          key: `${idx}-disclosure-label`,
          className: 'review-step__section-label',
          children: <SectionLabel>Financial & Legal Disclosure</SectionLabel>
        },
        {
          key: `${idx}-owner-info-citizenship-status`,
          label: 'Citizenship Status',
          children: (
            <EditItem onEdit={handleEdit('owner-info-citizenship-status')}>
              {CITIZENSHIP_STATUS_OPTIONS_MAP[citizenshipStatusType]}
            </EditItem>
          )
        },
        ...optionalItem(fundingPurpose === EFundingPurposeType.StartBusiness, {
          key: `${idx}-owner-info-has-collateral-real-estate`,
          label: 'Real estate that can be pledged as collateral',
          children: (
            <EditItem
              onEdit={handleEdit('owner-info-has-collateral-real-estate')}
            >
              {hasCollateralRealEstate ? 'Yes' : 'No'}
            </EditItem>
          )
        }),
        ...optionalItem(
          fundingPurpose === EFundingPurposeType.StartBusiness &&
            hasCollateralRealEstate,
          {
            key: `${idx}-owner-info-real-estate-price`,
            label: 'Price',
            children: (
              <EditItem onEdit={handleEdit('owner-info-real-estate-price')}>
                {realEstatePrice ?  `$${formatMoneyValue(realEstatePrice)}` : ''}
              </EditItem>
            )
          }
        ),
        {
          key: `${idx}-convicted-felony`,
          label: 'Conviction of a felony',
          children: (
            <EditItem onEdit={handleEdit('owner-info-convicted-felony')}>
              {`${hasFelonyConviction ? 'Yes' : 'No'} ${
                hasFelonyConviction
                  ? `- ${dayjs(felonyConvictionDate).format(
                      DEFAULT_DATE_FORMAT.format
                    )}`
                  : ''
              }`}
            </EditItem>
          )
        },
        {
          key: `${idx}-filled-bankruptcy`,
          label: 'Bankruptcy has been filed',
          children: (
            <EditItem onEdit={handleEdit('owner-info-filled-bankruptcy')}>
              {`${hasFiledBankruptcy ? 'Yes' : 'No'} ${
                hasFiledBankruptcy
                  ? `- ${dayjs(bankruptcyFillingDate).format(
                      DEFAULT_DATE_FORMAT.format
                    )}`
                  : ''
              }`}
            </EditItem>
          )
        },
        {
          key: `${idx}-owner-info-tax-debt`,
          label:
            'Owed Taxes to IRS (Business or Personal, including Payment Plan)',
          children: (
            <EditItem onEdit={handleEdit('owner-info-tax-debt')}>
              <Flex vertical wrap>
                <span>{getYesOrNoLabel(hasTaxDebt)}</span>
                {annualDebts.map(({ amount, year }) => (
                  <span>
                    {amount && (
                      <>
                        <span>{`$${formatMoneyValue(amount)} `}</span>
                        <span>{`for ${year}`}</span>
                      </>
                    )}
                  </span>
                ))}
              </Flex>
            </EditItem>
          )
        },
        {
          key: `${idx}-owner-info-has-pending-lawsuit`,
          label: 'Pending lawsuits or legal proceedings',
          className: 'review-step__column-description',
          children: (
            <RichEditItem
              label={hasPendingLawsuits ? 'Yes' : 'No'}
              onEdit={handleEdit('owner-info-has-pending-lawsuit')}
              hideContent={!hasPendingLawsuits}
            >
              {pendingLawsuitsExplanation ?? ''}
            </RichEditItem>
          )
        },
        {
          key: `${idx}-owner-info-has-judgement`,
          label: 'Unsatisfied judgments or court orders',
          className: 'review-step__column-description',
          children: (
            <RichEditItem
              label={hasUnsatisfiedJudgments ? 'Yes' : 'No'}
              onEdit={handleEdit('owner-info-has-judgement')}
              hideContent={!hasUnsatisfiedJudgments}
            >
              {unsatisfiedJudgmentsExplanation ?? ''}
            </RichEditItem>
          )
        },
        {
          key: `${idx}-owner-info-has-other-business`,
          label: 'Ownership Stake of 20% or Greater in Any Other Businesses',
          children: (
            <EditItem onEdit={handleEdit('owner-info-has-other-business')}>
              {hasOtherBusinesses ? 'Yes' : 'No'}
            </EditItem>
          )
        }
      )
    }
  )

  return (
    <>
      <Descriptions
        className='review-step__descriptions'
        column={1}
        colon={false}
        items={items}
      />
    </>
  )
}

export default BusinessOwnersDescriptions
