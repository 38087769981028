import { FC, HTMLAttributes } from 'react'

const PdfFile: FC<HTMLAttributes<SVGElement>> = (props) => {
  return (
    <svg
      width='34'
      height='34'
      viewBox='0 0 34 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect
        x='-0.5'
        y='0.5'
        width='33'
        height='33'
        rx='4.5'
        transform='matrix(-1 0 0 1 33 0)'
        fill='#FBEEED'
      />
      <rect
        x='-0.5'
        y='0.5'
        width='33'
        height='33'
        rx='4.5'
        transform='matrix(-1 0 0 1 33 0)'
        stroke='#F3CAC8'
      />
      <path
        d='M8.55107 27.3047C11.4923 25.2734 13.4586 22.1697 14.9048 18.3672C16.3673 14.5809 17.9273 11.3309 17.2448 7.5934C17.1473 7.0084 16.3186 6.40715 15.8961 6.4559C14.2386 6.5534 14.3523 8.42215 14.5148 9.5434C14.9048 12.3222 17.0498 15.5559 19.1298 18.5784C21.1936 21.6172 22.8998 22.0884 25.2723 22.2509C26.0848 22.2997 27.3036 22.0234 27.4986 21.3084C28.7498 16.7584 12.8411 20.4309 7.54357 24.6884C6.89357 25.2247 6.14607 26.3134 6.56857 27.0609C6.89357 27.6459 7.98232 27.7109 8.56732 27.3047H8.55107Z'
        stroke='#E17572'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default PdfFile
