import { ReactNode } from 'react'

import BusinessAcquisitionInfo from '@/components/steps/business-acquisition-info/BusinessAcquisitionInfo'
import BusinessEntityAndEin from '@/components/steps/business-entity-and-ein'
import BusinessMainInfo from '@/components/steps/business-main-info'
import BusinessOwnerInfo from '@/components/steps/business-owner-info/BusinessOwnerInfo'
import FicoScore from '@/components/steps/fico-score/FicoScore'
import FundingAmount from '@/components/steps/funding-amount/FundingAmount'
import FundsAccessTime from '@/components/steps/funds-access-time/FundsAccessTime'
import FundsFor from '@/components/steps/funds-for/FundsFor'
import PrimaryContactDetails from '@/components/steps/primary-contact-details'
import RealEstateAcquisitionInfo from '@/components/steps/real-estate-acquisition-info/RealEstateAcquisitionInfo'
import ReviewStep from '@/components/steps/review-step/ReviewStep'
import SaveTimeAi from '@/components/steps/save-time-ai/SaveTimeAi'
import StartBusinessDocumentsUpload from '@/components/steps/start-business-documents-upload/StartBusinessDocumentsUpload'
import { TFormStep, TFormSubStep } from '@/types/formSteps'
import { IStepProps } from '@/types/types'

const useFormSteps = ({
  form,
  next,
  prev
}: IStepProps): ((
  key: TFormStep,
  subStep: TFormSubStep | null
) => ReactNode) => {
  const getStepByKey = (key: TFormStep, subStep: TFormSubStep | null) => {
    switch (key) {
      case 'funding-amount':
        return <FundingAmount form={form} next={next} />
      case 'loan-purpose':
        return <FundsFor form={form} next={next} prev={prev} />
      case 'funds-access-time':
        return <FundsAccessTime form={form} next={next} prev={prev} />
      case 'fico-score':
        return <FicoScore form={form} next={next} prev={prev} />
      case 'save-time-ai':
        return (
          <SaveTimeAi form={form} next={next} prev={prev} subStep={subStep} />
        )
      case 'primary-contact-details':
        return <PrimaryContactDetails form={form} next={next} prev={prev} />
      case 'business-info':
        return <BusinessMainInfo form={form} next={next} prev={prev} />
      case 'business-entity-and-ein':
        return <BusinessEntityAndEin form={form} next={next} prev={prev} />
      case 'business-owner-info':
        return <BusinessOwnerInfo form={form} next={next} prev={prev} />
      case 'business-acquisition-info':
        return <BusinessAcquisitionInfo form={form} next={next} prev={prev} />
      case 'real-estate-acquisition-info':
        return <RealEstateAcquisitionInfo form={form} next={next} prev={prev} />
      case 'start-business-documents-upload':
        return (
          <StartBusinessDocumentsUpload form={form} next={next} prev={prev} />
        )
      case 'review':
        return <ReviewStep form={form} next={next} prev={prev} />
    }
  }

  return getStepByKey
}

export default useFormSteps
