import { ReactElement } from 'react'

interface ISecurityIconProps {
  className?: string
}

const SecurityIcon = ({ className = '' }: ISecurityIconProps): ReactElement => {
  return (
    <svg
      width='111'
      height='110'
      viewBox='0 0 111 110'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        opacity='0.4'
        d='M103.946 50.1878C103.946 76.8517 84.6327 101.825 58.2475 109.132C56.4522 109.623 54.4932 109.623 52.6979 109.132C26.3129 101.825 7 76.8517 7 50.1878V26.2505C7 21.7793 10.3731 16.7083 14.5621 15.0179L44.8639 2.58568C51.6642 -0.195225 59.335 -0.195225 66.1353 2.58568L96.4375 15.0179C100.572 16.7083 104 21.7793 104 26.2505L103.946 50.1878Z'
        fill='#13A55B'
      />
      <path
        d='M69 47.1364C69 39.6091 62.952 33.5 55.5 33.5C48.048 33.5 42 39.6091 42 47.1364C42 53.2455 45.996 58.3182 51.45 60.0636V74.4091C51.45 76.6455 53.286 78.5 55.5 78.5C57.714 78.5 59.55 76.6455 59.55 74.4091V60.0636C65.004 58.3182 69 53.2455 69 47.1364Z'
        fill='#13A55B'
      />
    </svg>
  )
}

export default SecurityIcon
