import { FC } from 'react'

interface IMenuIconProps {
  onClick?: () => void
  className?: string
}

const MenuIcon: FC<IMenuIconProps> = ({ onClick, className }) => {
  return (
    <svg
      width='36'
      height='36'
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      className={className}
    >
      <path
        d='M31.7812 5.625H4.21875C4.06406 5.625 3.9375 5.75156 3.9375 5.90625V8.15625C3.9375 8.31094 4.06406 8.4375 4.21875 8.4375H31.7812C31.9359 8.4375 32.0625 8.31094 32.0625 8.15625V5.90625C32.0625 5.75156 31.9359 5.625 31.7812 5.625ZM31.7812 27.5625H4.21875C4.06406 27.5625 3.9375 27.6891 3.9375 27.8438V30.0938C3.9375 30.2484 4.06406 30.375 4.21875 30.375H31.7812C31.9359 30.375 32.0625 30.2484 32.0625 30.0938V27.8438C32.0625 27.6891 31.9359 27.5625 31.7812 27.5625ZM31.7812 16.5938H4.21875C4.06406 16.5938 3.9375 16.7203 3.9375 16.875V19.125C3.9375 19.2797 4.06406 19.4062 4.21875 19.4062H31.7812C31.9359 19.4062 32.0625 19.2797 32.0625 19.125V16.875C32.0625 16.7203 31.9359 16.5938 31.7812 16.5938Z'
        fill='var(--brand-primary)'
      />
    </svg>
  )
}

export default MenuIcon
