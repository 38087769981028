import { FC } from 'react'

import { Flex, Typography } from 'antd'

import Close from '@/components/icons/Close'
import Warning from '@/components/icons/Warning'

import './PrefillDataError.scss'

interface IPrefillDataErrorProps {
  onClose: () => void
}

const PrefillDataError: FC<IPrefillDataErrorProps> = ({ onClose }) => {
  return (
    <div className='prefill-data-error'>
      <Flex align='center' gap={18}>
        <Warning className='prefill-data-error__warning-icon' />
        <Typography.Text className='prefill-data-error__text'>
          Your documents were not uploaded on the previous screen, or our tool
          was unable to process them correctly. Please fill in the information
          below.
        </Typography.Text>
        <Close className='prefill-data-error__close-icon' onClick={onClose} />
      </Flex>
    </div>
  )
}

export default PrefillDataError
