import React from 'react'

import { IStepWithSubStepProps } from '@/types/types'

import AiLoading from './components/ai-loading/AiLoading'
import UploadFiles from './components/upload-files/UploadFiles'

const SaveTimeAi: React.FC<IStepWithSubStepProps> = ({
  form,
  next,
  prev,
  subStep
}) => {
  return subStep === 'ai-loading' ? (
    <AiLoading form={form} next={next} prev={prev} />
  ) : (
    <UploadFiles form={form} next={next} prev={prev} />
  )
}

export default SaveTimeAi
