import { FC, HTMLAttributes } from 'react'

export const ArrowDown: FC<HTMLAttributes<SVGElement>> = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.9181 8.95312L13.3981 15.4731C12.6281 16.2431 11.3681 16.2431 10.5981 15.4731L4.07812 8.95312'
        stroke='#9B9B9B'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
