import { FC } from 'react'

import Edit from '@/components/icons/Edit'

import { IEditItemProps } from '../types'

import './RichEditItem.scss'

interface IRichEditItemProps extends IEditItemProps {
  label?: string
  hideContent?: boolean
}

const RichEditItem: FC<IRichEditItemProps> = ({
  children,
  label = '',
  onEdit,
  editable = true,
  hideContent = false
}) => {
  return (
    <div
      className={`rich-edit-item ${
        hideContent ? 'rich-edit-item-hide-content' : ''
      }`}
    >
      <div className='rich-edit-item__content'>{children}</div>
      <div className='rich-edit-item__label'>
        {label}
        {editable && <Edit className='rich-edit-item__icon' onClick={onEdit} />}
      </div>
    </div>
  )
}
export default RichEditItem
