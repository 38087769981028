import { ReactElement } from 'react'

import { Descriptions, DescriptionsProps, FormInstance } from 'antd'
import dayjs from 'dayjs'

import DEFAULT_DATE_FORMAT from '@/constants/defaultDateFormat'
import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import {
  FICO_SCORE_TYPE_OPTIONS_MAP,
  FUNDING_PURPOSE_OPTIONS_MAP,
  TIMEFRAME_TYPE_OPTIONS_MAP
} from '@/constants/formFieldOptions'
import { EFundingPurposeType, TFormOwnerField } from '@/types/formFieldTypes'
import getFormattedPhoneNumber from '@/utils/getFormattedPhoneNumber'

import { EditItem } from './edit-item'
import SectionLabel from './section-label/SectionLabel'
import { IEditFieldState } from './types'
import { getFormattedNumber } from 'react-phone-hooks'

const {
  fundingPurpose,
  fundingPurposeType,
  totalFunding,
  ficoScoreType,
  timeframeType,
  owners: ownersFieldName
} = FORM_FIELD_NAMES_MAP

interface IBasicInformationDescriptionsProps {
  form: FormInstance
  onEditField: (state: IEditFieldState) => void
}

const BasicInformationDescriptions = ({
  form,
  onEditField
}: IBasicInformationDescriptionsProps): ReactElement => {
  const loanPurposeType = form.getFieldValue(fundingPurposeType)
  const loanPurpose = form.getFieldValue(fundingPurpose)
  const loanAmount = form.getFieldValue(totalFunding)
  const ficoScore = form.getFieldValue(ficoScoreType)
  const fundsAccessTime = form.getFieldValue(timeframeType)

  const primaryOwner: TFormOwnerField = form.getFieldValue([
    ownersFieldName,
    '0'
  ])

  const showFundsAccessTime = [
    EFundingPurposeType.WorkingCapital,
    EFundingPurposeType.DebtRefinancing,
    EFundingPurposeType.Other
  ].includes(loanPurposeType)

  const handleOwnerEdit = (key: IEditFieldState['key']) => () => {
    onEditField({
      key,
      additionalValue: { index: 0 },
      pathModifier: [ownersFieldName, '0']
    })
  }

  const items: DescriptionsProps['items'] = [
    {
      key: 'loan-amount',
      label: 'Loan Amount',
      children: (
        <EditItem onEdit={() => onEditField({ key: 'total-funding' })}>
          {loanAmount ? `$${loanAmount}` : ''}
        </EditItem>
      )
    },
    {
      key: 'loan-purpose',
      label: 'Loan Purpose',
      children: (
        <EditItem
          onEdit={() => onEditField({ key: 'funding-purpose' })}
          editable={false}
        >
          {loanPurpose ?? FUNDING_PURPOSE_OPTIONS_MAP[loanPurposeType]}
        </EditItem>
      )
    },
    ...(showFundsAccessTime
      ? [
          {
            key: 'funds-access-time',
            label: 'Preferred timeline for accessing the funds',
            children: (
              <EditItem
                onEdit={() => onEditField({ key: 'funds-access-time' })}
              >
                {TIMEFRAME_TYPE_OPTIONS_MAP[fundsAccessTime]}
              </EditItem>
            )
          }
        ]
      : []),
    {
      key: 'fico-score',
      label: 'FICO Score',
      children: (
        <EditItem onEdit={() => onEditField({ key: 'fico-score' })}>
          {FICO_SCORE_TYPE_OPTIONS_MAP[ficoScore]}
        </EditItem>
      )
    },
    {
      key: `divider`,
      className: 'review-step__section-label',
      children: <SectionLabel>Personal Information</SectionLabel>
    },
    {
      key: 'first-name',
      label: 'First Name',
      children: (
        <EditItem onEdit={handleOwnerEdit('owner-info-first-name')}>
          {primaryOwner.firstName}
        </EditItem>
      )
    },
    {
      key: 'last-name',
      label: 'Last Name',
      children: (
        <EditItem onEdit={handleOwnerEdit('owner-info-last-name')}>
          {primaryOwner.lastName}
        </EditItem>
      )
    },
    {
      key: 'email',
      label: 'Email',
      children: (
        <EditItem onEdit={handleOwnerEdit('owner-info-email')}>
          {primaryOwner.email}
        </EditItem>
      )
    },
    {
      key: 'phone-number',
      label: 'Mobile Phone Number',
      children: (
        <EditItem onEdit={handleOwnerEdit('owner-info-phone')}>
          {getFormattedNumber(getFormattedPhoneNumber(primaryOwner.phoneNumber))}
        </EditItem>
      )
    },
    {
      key: 'birth-date',
      label: 'Date of Birth',
      children: (
        <EditItem onEdit={handleOwnerEdit('owner-info-birth-date')}>
          {primaryOwner.birthDate
            ? dayjs(primaryOwner.birthDate).format(DEFAULT_DATE_FORMAT.format)
            : ''}
        </EditItem>
      )
    }
  ]

  return (
    <>
      <Descriptions
        className='review-step__descriptions'
        colon={false}
        column={1}
        items={items}
      />
    </>
  )
}

export default BasicInformationDescriptions
