import { Dispatch, useState } from 'react'

import { NamePath } from 'antd/es/form/interface'

import { OWNER_INFO_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import { getValueFromObject, isFieldEmpty } from '@/utils/formValidation'

export interface IError {
  ownerIndex: number
  isTabsValid: {
    first: boolean
    second: boolean
  }
}

const {
  firstName,
  lastName,
  email,
  phoneNumber,
  birthDate,
  businessWeight,
  address,
  socialSecurityNumber,
  maritalStatusType,
  hasFiledBankruptcy,
  hasFelonyConviction,
  bankruptcyFillingDate,
  felonyConvictionDate,
  citizenshipStatusType,
  citizenshipStatusExplanation,
  hasCollateralRealEstate,
  mortgageDebt,
  realEstatePrice,
  annualDebts,
  hasPendingLawsuits,
  pendingLawsuitsExplanation,
  hasUnsatisfiedJudgments,
  unsatisfiedJudgmentsExplanation,
  hasOtherBusinesses,
  hasTaxDebt
} = OWNER_INFO_FIELD_NAMES_MAP

const FIRST_TAB_FIELDS = [
  firstName,
  lastName,
  email,
  phoneNumber,
  birthDate,
  businessWeight,
  address,
  socialSecurityNumber,
  maritalStatusType
]

const SECOND_TAB_FIELDS = [
  hasFiledBankruptcy,
  hasFelonyConviction,
  bankruptcyFillingDate,
  felonyConvictionDate,
  citizenshipStatusType,
  citizenshipStatusExplanation,
  hasCollateralRealEstate,
  mortgageDebt,
  realEstatePrice,
  annualDebts,
  hasPendingLawsuits,
  pendingLawsuitsExplanation,
  hasUnsatisfiedJudgments,
  unsatisfiedJudgmentsExplanation,
  hasOtherBusinesses,
  hasTaxDebt
]

const useOwnersValidation = (
  activeOwnerIndex: number,
  setActiveTab: Dispatch<React.SetStateAction<number>>,
  setActiveOwnerIndex: Dispatch<React.SetStateAction<string>>
) => {
  const [uncompletedOwners, setUncompletedOwners] = useState<
    IError[]
  >([])

  const getEmptyFieldName = (err: any, uncompletedOwners: IError[]): string => {
    const activeOwnerUnCompleted = uncompletedOwners.find(
      ({ ownerIndex }) => ownerIndex === activeOwnerIndex
    )

    if (activeOwnerUnCompleted) {
      const emptyField = err.errorFields.find(
        ({ name }: { name: NamePath }) => {
          if (name[1] !== activeOwnerIndex) {
            return false
          }

          const value = getValueFromObject(name, err.values)
          const isEmpty = isFieldEmpty(value, name)
          return isEmpty
        }
      )

      setActiveTab(!activeOwnerUnCompleted.isTabsValid.first ? 0 : 1)
      return emptyField?.name
    }

    const emptyField = err.errorFields.find(({ name }: { name: NamePath }) => {
      const value = getValueFromObject(name, err.values)
      return isFieldEmpty(value, name)
    })

    const ownerIndex = emptyField?.name[1] as number

    const uncompletedOwner = uncompletedOwners.find(
      ({ ownerIndex: currentOwnerIndex }) => currentOwnerIndex === ownerIndex
    )!

    setActiveOwnerIndex(ownerIndex.toString())
    setActiveTab(!uncompletedOwner.isTabsValid.first ? 0 : 1)

    return emptyField?.name
  }

  const handleCatchError = (err: any): IError[] => {
    const newUncompletedOwners = err.errorFields.reduce(
      (acc: IError[], { name }: { name: NamePath }) => {
        const ownerIndex: number = name[1]
        const isTabsValid: Partial<IError['isTabsValid']> = {}

        const currentOwnerIndex = acc.findIndex(
          ({ ownerIndex: idx }) => idx === ownerIndex
        )

        if (FIRST_TAB_FIELDS.includes(name[2])) {
          isTabsValid.first = false
        } else if (SECOND_TAB_FIELDS.includes(name[2])) {
          isTabsValid.second = false
        }

        if (currentOwnerIndex === -1) {
          const uncompletedOwner = {
            ownerIndex,
            isTabsValid: {
              first: true,
              second: true,
              ...isTabsValid
            }
          }
          return [...acc, uncompletedOwner]
        }

        acc[currentOwnerIndex] = {
          ownerIndex,
          isTabsValid: {
            ...acc[currentOwnerIndex].isTabsValid,
            ...isTabsValid
          }
        }

        return acc
      },
      []
    )

    setUncompletedOwners(newUncompletedOwners)

    return newUncompletedOwners
  }

  const handleCatchUncompletedFields = (err: any): NamePath => {
    const uncompletedOwners = handleCatchError(err)

    return getEmptyFieldName(err, uncompletedOwners)
  }

  const handleValidationSuccess = () => {
    setUncompletedOwners([])
  }

  return {
    uncompletedOwners,
    handleCatchUncompletedFields,
    handleCatchError,
    handleValidationSuccess
  }
}

export default useOwnersValidation
