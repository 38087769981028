import { useEffect } from 'react'

import { setTimelineStep } from '@/redux/features/timeLineSlice'

import { useTypedDispatch, useTypedSelector } from './redux'

const useCurrentNavItem = () => {
  const { currentStep, stepIndexes, currentTimeLineStep } = useTypedSelector(
    ({ timeLine }) => ({
      currentStep: timeLine.formStep,
      stepIndexes: timeLine.stepIndexes,
      currentTimeLineStep: timeLine.timelineStep
    })
  )
  const dispatch = useTypedDispatch()

  useEffect(() => {
    if (stepIndexes.basicInfo.includes(currentStep)) {
      dispatch(setTimelineStep('basic-information'))
      return
    }
    if (stepIndexes.businessInfo.includes(currentStep)) {
      dispatch(setTimelineStep('business-information'))
      return
    }
    if (stepIndexes.ownerInfo.includes(currentStep)) {
      dispatch(setTimelineStep('owner-information'))
      return
    }
    if (stepIndexes.reviewSteps.includes(currentStep)) {
      dispatch(setTimelineStep('review'))
    }
  }, [stepIndexes, currentStep, currentTimeLineStep, dispatch])
}

export default useCurrentNavItem
