import { PhoneNumber } from 'antd-phone-input'

const getFormattedPhoneNumber = (value: PhoneNumber | string): string => {
  if (typeof value === 'string') {
    return value
  }

  const { countryCode, phoneNumber, areaCode } = value || {}
  return countryCode && phoneNumber
    ? `${countryCode}${areaCode ?? ''}${phoneNumber}`
    : ''
}

export default getFormattedPhoneNumber
