import { FC, HTMLAttributes, ReactNode } from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

interface IAutoAnimateContainerProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  duration?: number
}

const AutoAnimateContainer: FC<IAutoAnimateContainerProps> = ({
  children,
  duration = 350,
  ...rest
}) => {
  const [parent] = useAutoAnimate({ duration })

  return (
    <div ref={parent} {...rest}>
      {children}
    </div>
  )
}

export default AutoAnimateContainer
