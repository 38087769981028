import { FC } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { Flex } from 'antd'

import { partnersQueries } from '@/api/partners.service'
import { PartnerDto } from '@/generated-types'

import Lock from '../icons/Lock'
import UserAgreement from '../user-agreement/UserAgreement'

import './FormFooter.scss'

interface IFormFooterProps {
  className?: string
}

const FormFooter: FC<IFormFooterProps> = ({ className = '' }) => {
  const queryClient = useQueryClient()

  const data = queryClient.getQueryData<Required<PartnerDto>>(
    partnersQueries.partner.me.queryKey
  )
  const partnerName = data?.name

  return (
    <Flex
      className={`form-footer ${className}`}
      vertical
      align='center'
      gap={8}
    >
      &copy; {`2025 ${partnerName}`}
      <Flex gap={10} align='center'>
        <Lock />
        We use 128-bit SSL and strict encryption standards to protect your
        information.
      </Flex>
      <UserAgreement />
    </Flex>
  )
}

export default FormFooter
