import { NamePath } from 'antd/es/form/interface'

export const isFieldEmpty = (
  value: any,
  name: NamePath,
) => {
  if (name.at(-1) === 'phoneNumber') {
    return !value?.areaCode && !value?.phoneNumber
  }

  return !value
}

export const getValueFromObject = (name: NamePath, values: any) =>
  name.reduce((acc: any, key: string) => acc?.[key], values)
