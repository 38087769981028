import { message } from 'antd'
import { Upload } from 'antd'
import { StoreValue } from 'antd/es/form/interface'
import { RcFile, UploadChangeParam, UploadFile } from 'antd/es/upload'

type TFormFile = UploadFile<RcFile> & { id?: string }

const getFilesFromEvent = (e: UploadChangeParam): StoreValue => {
  if (Array.isArray(e)) {
    return e
  }

  return e?.fileList
}

const beforeUpload =
  (
    allowedTypes = ['image/png', 'image/jpeg', 'application/pdf'],
    filesMaxCount: number,
    fileList: TFormFile[],
    fileSizeLimit = 50,
  ) =>
  (file: RcFile) => {
    if (filesMaxCount <= fileList.length) {
      const msgError =
        filesMaxCount === 1
          ? 'You can only upload one file'
          : `You can only upload up to ${filesMaxCount} files`

      message.error(msgError)
      return Upload.LIST_IGNORE
    }

    const totalFilesSize = [...fileList, file]?.reduce((acc, file) => {
      acc += file.size ?? 0
      return acc
    }, 0)

    if (fileList?.length && totalFilesSize / (1024 * 1024) > fileSizeLimit) {
      message.error(`The maximum allowed file size limit is ${fileSizeLimit}MB`)
      return Upload.LIST_IGNORE
    }

    const isAllowedType = allowedTypes.includes(file.type)
    if (!isAllowedType) {
      message.error(`${file.name} should be PNG, JPEG or PDF`)
      return Upload.LIST_IGNORE
    }

    // not use default server upload
    return false
  }

export { getFilesFromEvent, beforeUpload }
export type { TFormFile }
