import { createSlice } from '@reduxjs/toolkit'

import {
  IStepIndexes,
  TFormStep,
  TFormSubStep,
  TStepMessage
} from '@/types/formSteps'

interface ITimelineInitialState {
  timelineStep:
    | 'basic-information'
    | 'business-information'
    | 'owner-information'
    | 'review'
  formSteps: TFormStep[]
  stepIndexes: IStepIndexes
  formStep: number
  formSubStep: null | TFormSubStep
  stepMessage: {
    stepIndex: number
    msgType: TStepMessage
  } | null
}

const initialState: ITimelineInitialState = {
  timelineStep: 'basic-information',
  formStep: 0,
  formSteps: [],
  formSubStep: null,
  stepIndexes: {
    basicInfo: [],
    businessInfo: [],
    ownerInfo: [],
    reviewSteps: []
  },
  stepMessage: null
}

const timeLineSlice = createSlice({
  name: 'timeLine',
  initialState,
  reducers: {
    setTimelineStep: (
      state,
      action: { payload: ITimelineInitialState['timelineStep'] }
    ) => {
      state.timelineStep = action.payload
    },
    setFormSteps: (
      state,
      action: { payload: ITimelineInitialState['formSteps'] }
    ) => {
      state.formSteps = action.payload
    },
    setStepIndexes: (
      state,
      action: { payload: ITimelineInitialState['stepIndexes'] }
    ) => {
      state.stepIndexes = action.payload
    },
    setStepMessage: (
      state,
      action: { payload: { msgType: TStepMessage; stepIndex?: number } | null }
    ) => {
      if (!action.payload) {
        state.stepMessage = action.payload
        return
      }

      state.stepMessage = {
        msgType: action.payload.msgType,
        stepIndex: action.payload.stepIndex ?? state.formStep
      }
    },
    changeStep: (
      state,
      action: {
        payload:
          | {
              currentStep?: number
              subStep?: TFormSubStep
              direction?: 'forward' | 'backward'
            }
          | undefined
      }
    ) => {
      const isForwardDirection = action.payload?.direction === 'forward'
      const isBackwardDirection = action.payload?.direction === 'backward'
      const currentSubStep = action.payload?.subStep ?? null
      const currentStep = action.payload?.currentStep ?? state.formStep

      state.formSubStep = currentSubStep

      if (action.payload?.currentStep || !action.payload?.direction) {
        state.formStep = currentStep
        return
      }

      const nextStep = currentStep + 1
      const prevStep = currentStep - 1

      if (isForwardDirection && nextStep < state.formSteps.length) {
        state.formStep = nextStep
      } else if (isBackwardDirection && prevStep >= 0) {
        state.formStep = prevStep
      }
    }
  }
})

export const {
  setTimelineStep,
  setFormSteps,
  setStepIndexes,
  setStepMessage,
  changeStep
} = timeLineSlice.actions
export default timeLineSlice.reducer
