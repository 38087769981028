import { FC, ReactNode } from 'react'

import withDeviceDependent from '@/hocs/with-device-dependent/WithDeviceDependent'

import './DesktopSidebar.scss'

interface IDesktopSidebarProps {
  children: ReactNode
}

const DesktopSidebar: FC<IDesktopSidebarProps> = ({ children }) => {
  return <section className='sidebar'>{children}</section>
}

export default withDeviceDependent(DesktopSidebar, true)
