import React, { FC } from 'react'

interface IEmailParserProps {
  text: string
}

const EmailParser: FC<IEmailParserProps> = ({ text }) => {
  const emailRegex = /[\w.-]+@[\w.-]+\.\w+/g

  const parts = text.split(emailRegex)
  const emails = text.match(emailRegex) || []

  return (
    <>
      {parts.map((part, index) => (
        <React.Fragment key={index}>
          {part}
          {emails[index] && (
            <a
              href={`mailto:${emails[index]}`}
              target='_blank'
              rel='noreferrer'
            >
              {emails[index]}
            </a>
          )}
        </React.Fragment>
      ))}
    </>
  )
}

export default EmailParser
