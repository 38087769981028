import { FC, ReactElement } from 'react'

import './TimeLineItem.scss'

interface ITimeLineItemProps {
  icon: ReactElement
  isActive: boolean
  title: string
  description: string
  onClick?: () => void
}

export const TimeLineItem: FC<ITimeLineItemProps> = ({
  icon,
  isActive,
  title,
  description,
  onClick
}) => {
  return (
    <div
      className={`time-line-item ${isActive ? 'active' : ''}`}
      onClick={() => isActive && onClick?.()}
    >
      {icon}
      <div className={'time-line-item__text'}>
        <p className={'time-line-item__title'}>{title}</p>
        <p className={'time-line-item__description'}>{description}</p>
      </div>
    </div>
  )
}

export default TimeLineItem
