import { FC } from 'react'

import { Rule } from 'antd/es/form'
import { NamePath } from 'antd/es/form/interface'

import { INDUSTRY_OPTIONS } from '@/constants/formFieldOptions'

import SelectFormItem from './default-items/select-form-item/SelectFormItem'

interface IIndustryFormItemProps {
  name: NamePath
  fullName?: NamePath
  rules?: Rule[]
}

const IndustryFormItem: FC<IIndustryFormItemProps> = ({ name, rules, fullName }) => {
  return (
    <SelectFormItem
      label='Industry'
      name={name}
      rules={rules}
      options={INDUSTRY_OPTIONS}
      placeholder='Select from the list'
      fullName={fullName}
    />
  )
}

export default IndustryFormItem
