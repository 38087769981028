import { Flex } from 'antd'

import { patchApplication } from '@/api/app.service'
import CustomLabel from '@/components/form/custom-label/CustomLabel'
import RadioFormItem from '@/components/form/form-items/default-items/radio-form-item/RadioFormItem'
import TextAreaFormInput from '@/components/form/form-items/default-items/textarea-form-input/TextAreaFormInput'
import {
  BUSINESS_ACQUISITION_FIELD_NAMES_MAP,
  FORM_FIELD_NAMES_MAP
} from '@/constants/formFieldNames'
import { YES_OR_NO_TYPE_OPTIONS } from '@/constants/formFieldOptions'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import { TGetFieldByKey, TUseFieldEdit } from './types'

const { businessAcquisition } = FORM_FIELD_NAMES_MAP

const { additionalInformation, hasBusinessInformation, isBusinessChosen } =
  BUSINESS_ACQUISITION_FIELD_NAMES_MAP

const useBuyBusinessFieldEdit: TUseFieldEdit = (applicationId, form) => {
  const getSelectedField: TGetFieldByKey = (selectedKey) => {
    switch (selectedKey) {
      case 'buy-business-is-business-chosen':
        return {
          title: 'Business acquisition information',
          subtitle: 'Have you chosen the business you will acquire?',
          namePath: [businessAcquisition, isBusinessChosen],
          onSave: async ({ isBusinessChosen: isBusinessChosenValue }) => {
            await tryCatchWithMessage(async () => {
              const inputValue = [
                {
                  path: '/businessAcquisition/isBusinessChosen',
                  value: isBusinessChosenValue
                }
              ]

              !isBusinessChosenValue &&
                inputValue.push(
                  {
                    path: '/businessAcquisition/hasBusinessInformation',
                    value: false
                  },
                  {
                    path: '/businessAcquisition/additionalInformation',
                    value: ''
                  }
                )

              await patchApplication(applicationId, inputValue).then(() => {
                form.setFieldValue(
                  [businessAcquisition, isBusinessChosen],
                  isBusinessChosen
                )

                if (!isBusinessChosenValue) {
                  form.setFieldsValue({
                    [businessAcquisition]: {
                      isBusinessChosen: false,
                      hasBusinessInformation: false,
                      additionalInformation: ''
                    }
                  })
                }
              })
            })
          },
          input: () => (
            <RadioFormItem
              name={isBusinessChosen}
              options={YES_OR_NO_TYPE_OPTIONS}
            />
          )
        }
      case 'buy-business-has-business-info':
        return {
          title: 'Business acquisition information',
          subtitle: 'Do you have information about the business?',
          namePath: [businessAcquisition, hasBusinessInformation],
          onSave: async ({
            hasBusinessInformation: hasBusinessInformationValue
          }) => {
            await tryCatchWithMessage(async () => {
              const inputValue = [
                {
                  path: '/businessAcquisition/hasBusinessInformation',
                  value: hasBusinessInformationValue
                }
              ]

              !hasBusinessInformationValue &&
                inputValue.push({
                  path: '/businessAcquisition/additionalInformation',
                  value: ''
                })

              await patchApplication(applicationId, inputValue).then(() => {
                form.setFieldValue(
                  [businessAcquisition, hasBusinessInformation],
                  hasBusinessInformationValue
                )

                !hasBusinessInformationValue &&
                  form.setFieldValue(
                    [businessAcquisition, additionalInformation],
                    ''
                  )
              })
            })
          },
          input: () => (
            <RadioFormItem
              name={hasBusinessInformation}
              options={YES_OR_NO_TYPE_OPTIONS}
            />
          )
        }
      case 'buy-business-additional-info':
        return {
          title: 'Business acquisition information',
          subtitle: 'Additional information',
          namePath: [businessAcquisition, additionalInformation],
          onSave: async ({
            additionalInformation: additionalInformationValue
          }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: '/businessAcquisition/additionalInformation',
                  value: additionalInformationValue
                }
              ]).then(() => {
                form.setFieldValue(
                  [businessAcquisition, additionalInformation],
                  additionalInformationValue
                )
              })
            })
          },
          input: () => (
            <Flex vertical>
              <CustomLabel
                title=' Additional Information'
                subtitle='Please provide the name and address of the business you would like
              to buy, along with a link to the business website. Add other
              details you think may be relevant using the space provided.'
              />
              <TextAreaFormInput name={additionalInformation} showCount />
            </Flex>
          )
        }
      default:
        return null
    }
  }

  return getSelectedField
}

export default useBuyBusinessFieldEdit
