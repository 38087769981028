import { FC } from 'react'

import { Form } from 'antd'
import { useWatch } from 'antd/es/form/Form'

import { patchApplication } from '@/api/app.service'
import { uploadFormFiles } from '@/api/file.service'
import CustomLabel from '@/components/form/custom-label/CustomLabel'
import RadioFormItem from '@/components/form/form-items/default-items/radio-form-item/RadioFormItem'
import TextAreaFormInput from '@/components/form/form-items/default-items/textarea-form-input/TextAreaFormInput'
import FormUploadInput from '@/components/form/form-upload-input/FormUploadInput'
import NavigationControls from '@/components/navigation-controls/NavigationControls'
import {
  BUSINESS_ACQUISITION_FIELD_NAMES_MAP,
  FORM_FIELD_NAMES_MAP
} from '@/constants/formFieldNames'
import { YES_OR_NO_TYPE_OPTIONS } from '@/constants/formFieldOptions'
import { useTypedSelector } from '@/hooks/redux'
import useCustomWatch from '@/hooks/useCustomWatch'
import usePatchApplication from '@/hooks/usePatchApplication'
import { IStepProps } from '@/types/types'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import './BusinessAcquisitionInfo.scss'

const DEFAULT_VALUE = {
  isBusinessChosen: false,
  hasBusinessInformation: false,
  additionalInformation: null,
  financialDocuments: []
}

const {
  additionalInformation: additionalInformationField,
  financialDocuments: financialDocumentsField,
  hasBusinessInformation: hasBusinessInformationField,
  isBusinessChosen: isBusinessChosenField
} = BUSINESS_ACQUISITION_FIELD_NAMES_MAP

const { businessAcquisition: businessAcquisitionField } = FORM_FIELD_NAMES_MAP

const BusinessAcquisitionInfo: FC<IStepProps> = ({ next, prev }) => {
  const form = Form.useFormInstance()

  const isBusinessChosen = useCustomWatch(
    [businessAcquisitionField, isBusinessChosenField],
    form
  )

  const hasBusinessInformation = useCustomWatch(
    [businessAcquisitionField, hasBusinessInformationField],
    form
  )

  const financialDocuments = useWatch(
    [businessAcquisitionField, financialDocumentsField],
    { preserve: true, form }
  )

  const applicationId = useTypedSelector(
    ({ formState }) => formState.applicationId
  )

  const [patch, isLoading] = usePatchApplication(
    next,
    async ({ businessAcquisition }) => {
      await tryCatchWithMessage(async () => {
        const fullValue = {
          ...DEFAULT_VALUE,
          ...businessAcquisition,
          financialDocuments: businessAcquisition.financialDocuments ?? []
        }

        if (!fullValue.financialDocuments?.length) {
          await patchApplication(applicationId, [
            {
              path: '/businessAcquisition',
              value: fullValue
            }
          ])
          return
        }

        const uploadedFinancialDocuments = await uploadFormFiles(
          fullValue.financialDocuments
        )

        const formattedBusinessAcquisition = {
          ...fullValue,
          financialDocuments: uploadedFinancialDocuments
        }

        await patchApplication(applicationId, [
          {
            path: '/businessAcquisition',
            value: formattedBusinessAcquisition
          }
        ])
      })
    }
  )

  return (
    <div className='business-acquisition'>
      <h2 className={'business-acquisition__title'}>
        Have you chosen the business you will acquire?
      </h2>
      <p className={'business-acquisition__subtitle'}>
        Please select answer below:
      </p>
      <RadioFormItem
        name={[businessAcquisitionField, isBusinessChosenField]}
        options={YES_OR_NO_TYPE_OPTIONS}
      />

      {isBusinessChosen && (
        <>
          <RadioFormItem
            name={[businessAcquisitionField, hasBusinessInformationField]}
            options={YES_OR_NO_TYPE_OPTIONS}
            label='Do you have information about the business?'
          />
        </>
      )}
      {isBusinessChosen && hasBusinessInformation && (
        <>
          <CustomLabel
            title='Upload the CIM or tax returns'
            subtitle='Please upload the Confidential Information Memorandum (CIM) 
            or the most recent tax returns if you have access to these documents. 
            This information helps expedite your loan application.'
          />
          <FormUploadInput
            name={[businessAcquisitionField, financialDocumentsField]}
            fileList={financialDocuments}
            className='business-acquisition__upload-input'
          />
          <CustomLabel
            title=' Additional Information'
            subtitle='Please provide the name and address of the business you would like
              to buy, along with a link to the business website. Add other
              details you think may be relevant using the space provided.'
            />
            <TextAreaFormInput
              name={[businessAcquisitionField, additionalInformationField]}
              placeholder='Enter additional information'
              showCount
            />
        </>
      )}
      <NavigationControls
        isLoading={isLoading}
        onNext={() => {
          patch()
        }}
        onPrev={() => prev()}
      />
    </div>
  )
}

export default BusinessAcquisitionInfo
