import { FC } from 'react'

import { NamePath } from 'antd/es/form/interface'

import DatePickerFormItem from './default-items/date-picker-form-item/DatePickerFormItem'

interface IBirthDateFormItemProps {
  name: NamePath
}

const BirthDateFormItem: FC<IBirthDateFormItemProps> = ({ name }) => {
  return <DatePickerFormItem name={name} label='Date of Birth' />
}

export default BirthDateFormItem
