import { FC } from 'react'

import { Checkbox, CheckboxProps, Form } from 'antd'

import useGetDefaultColorsMap from '@/hooks/useGetDefaultColorsMap'

const CustomCheckbox: FC<CheckboxProps> = (props) => {
  const { errors } = Form.Item.useStatus()

  const { error: errorColor } = useGetDefaultColorsMap()

  return (
    <Checkbox
      style={{ color: errors.length ? errorColor : 'unset' }}
      {...props}
    >
      {props.children}
    </Checkbox>
  )
}

export default CustomCheckbox
