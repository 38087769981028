import { FC } from 'react'

import { Form, Input } from 'antd'

import {
  BORROWERS_SIGNATURE_FIELD_NAMES_MAP,
  FORM_FIELD_NAMES_MAP,
  OWNER_INFO_FIELD_NAMES_MAP
} from '@/constants/formFieldNames'

import './BorrowerNameFormItem.scss'

const { firstName, lastName } = OWNER_INFO_FIELD_NAMES_MAP

const { owners: ownersFieldName, borrowerSignature } = FORM_FIELD_NAMES_MAP

const { signerName } = BORROWERS_SIGNATURE_FIELD_NAMES_MAP

const BorrowerNameFormItem: FC = () => {
  const form = Form.useFormInstance()

  const getDefaultValue = () => {
    const firstNameValue = form.getFieldValue([ownersFieldName, '0', firstName])
    const lastNameValue = form.getFieldValue([ownersFieldName, '0', lastName])

    if (!firstNameValue && !lastNameValue) {
      return ''
    }

    return `${firstNameValue ?? ''} ${lastNameValue ?? ''}`
  }

  return (
    <Form.Item
      initialValue={getDefaultValue()}
      name={[borrowerSignature, signerName]}
      className={'borrower-name-input'}
      rules={[{ required: true }]}
      required={false}
      label='Name of Borrower Signing:'
    >
      <Input />
    </Form.Item>
  )
}

export default BorrowerNameFormItem
