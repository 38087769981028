import { FC } from 'react'

import { Descriptions, DescriptionsProps } from 'antd'

import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import { BusinessAcquisitionDto } from '@/generated-types'
import { optionalItem } from '@/utils/reviewUtils'

import { EditItem, RichEditItem } from '../edit-item'
import { IDescriptionProps } from '../types'

const { businessAcquisition } = FORM_FIELD_NAMES_MAP

const BuyBusinessInfoDescription: FC<IDescriptionProps> = ({
  form,
  onEditField
}) => {
  const {
    additionalInformation,
    hasBusinessInformation = false,
    isBusinessChosen = false
  }: Omit<BusinessAcquisitionDto, 'id'> = form.getFieldValue(
    businessAcquisition
  ) || {}

  const items: DescriptionsProps['items'] = [
    {
      key: 'is-business-chosen',
      label: 'Business Selected for Acquisition',
      children: (
        <EditItem
          onEdit={() => onEditField({ key: 'buy-business-is-business-chosen' })}
        >
          {isBusinessChosen ? 'Yes' : 'No'}
        </EditItem>
      )
    },
    ...optionalItem(isBusinessChosen, {
      key: 'has-business-information',
      label: 'Information about the business',
      children: (
        <EditItem
          onEdit={() => onEditField({ key: 'buy-business-has-business-info' })}
        >
          {hasBusinessInformation ? 'Yes' : 'No'}
        </EditItem>
      )
    }),
    ...optionalItem(hasBusinessInformation, {
      key: 'additional-information',
      label: 'Additional Information',
      className: 'review-step__column-description',
      children: (
        <RichEditItem
          onEdit={() => onEditField({ key: 'buy-business-additional-info' })}
        >
          {additionalInformation}
        </RichEditItem>
      )
    })
  ]

  return (
    <Descriptions
      className='review-step__descriptions'
      column={1}
      colon={false}
      items={items}
    />
  )
}

export default BuyBusinessInfoDescription
