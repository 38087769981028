import { FC, HTMLAttributes } from 'react'

const ArrowTopRight: FC<HTMLAttributes<SVGElement>> = (props) => {
  return (
    <svg
      width='11'
      height='11'
      viewBox='0 0 11 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M1.87031 10.0796L9.24915 1.71707M9.24915 1.71707L1.03868 2.19549M9.24915 1.71707L10.2557 9.87963'
        stroke='#FF6200'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default ArrowTopRight
