import { createQueryKeyStore } from '@lukemorales/query-key-factory'

import { PartnerDto } from '@/generated-types'

import APIinstance from './config'
import { getLogo } from './file.service'

export const partnersQueries = createQueryKeyStore({
  partner: {
    me: ['partner/me'],
    logo: (logoId?: string) => ({
      queryKey: [logoId],
      queryFn: () => getLogo(logoId)
    })
  }
})

export const getPartnerConfig = async (): Promise<Required<PartnerDto>> => {
  const { data } = await APIinstance.get<Required<PartnerDto>>('partners/me')
  return data
}
