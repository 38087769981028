import { useEffect, useState } from 'react'

import { FormInstance } from 'antd'
import { useWatch } from 'antd/es/form/Form'

import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import {
  BASIC_INFO_DEFAULT_STEPS,
  BASIC_INFO_WITHOUT_TIMELINE_STEPS,
  BUSINESS_DEFAULT_STEPS,
  BUSINESS_INFO_BUY_BUSINESS_STEPS,
  BUSINESS_INFO_BUY_REAL_ESTATE_STEPS,
  BUSINESS_INFO_START_BUSINESS_STEPS,
  OWNER_INFO_DEFAULT_STEPS,
  OWNER_INFO_START_BUSINESS_STEPS,
  REVIEW_DEFAULT_STEPS
} from '@/constants/formSteps'
import { setFormSteps, setStepIndexes } from '@/redux/features/timeLineSlice'
import { EFundingPurposeType } from '@/types/formFieldTypes'
import { IStepIndexes, TFormStep } from '@/types/formSteps'

import { useTypedDispatch } from './redux'
import useShouldShowRejectedView from './useShouldShowRejectedView'

const { fundingPurposeType } = FORM_FIELD_NAMES_MAP

interface IStepsByTimeline {
  basicInfoSteps: TFormStep[]
  businessInfoSteps: TFormStep[]
  ownerInfoSteps: TFormStep[]
  reviewSteps: TFormStep[]
}

const DEFAULT_STEPS_BY_TIMELINE: IStepsByTimeline = {
  basicInfoSteps: BASIC_INFO_DEFAULT_STEPS,
  businessInfoSteps: BUSINESS_DEFAULT_STEPS,
  ownerInfoSteps: OWNER_INFO_DEFAULT_STEPS,
  reviewSteps: REVIEW_DEFAULT_STEPS
}

const useTimeline = (form: FormInstance) => {
  const [stepsByTimeline, setStepsByTimeline] = useState<IStepsByTimeline>(
    DEFAULT_STEPS_BY_TIMELINE
  )

  const shouldShowRejectedView = useShouldShowRejectedView(form)
  const dispatch = useTypedDispatch()

  const changeStepsByTimeline = (newState: Partial<IStepsByTimeline>) =>
    setStepsByTimeline((prev) => ({ ...prev, ...newState }))

  const fundingPurpose = useWatch(fundingPurposeType, { form, preserve: true })


  useEffect(() => {
    switch (fundingPurpose) {
      case EFundingPurposeType.BuyBusiness:
        changeStepsByTimeline({
          basicInfoSteps: BASIC_INFO_WITHOUT_TIMELINE_STEPS,
          businessInfoSteps: BUSINESS_INFO_BUY_BUSINESS_STEPS,
          ownerInfoSteps: DEFAULT_STEPS_BY_TIMELINE.ownerInfoSteps
        })
        break
      case EFundingPurposeType.StartBusiness:
        changeStepsByTimeline({
          basicInfoSteps: BASIC_INFO_WITHOUT_TIMELINE_STEPS,
          businessInfoSteps: BUSINESS_INFO_START_BUSINESS_STEPS,
          ownerInfoSteps: OWNER_INFO_START_BUSINESS_STEPS
        })
        break
      case EFundingPurposeType.BuyCommercialRealEstate:
        changeStepsByTimeline({
          basicInfoSteps: BASIC_INFO_WITHOUT_TIMELINE_STEPS,
          businessInfoSteps: BUSINESS_INFO_BUY_REAL_ESTATE_STEPS,
          ownerInfoSteps: DEFAULT_STEPS_BY_TIMELINE.ownerInfoSteps
        })
        break
      default:
        changeStepsByTimeline({
          basicInfoSteps: DEFAULT_STEPS_BY_TIMELINE.basicInfoSteps,
          businessInfoSteps: DEFAULT_STEPS_BY_TIMELINE.businessInfoSteps,
          ownerInfoSteps: DEFAULT_STEPS_BY_TIMELINE.ownerInfoSteps
        })
    }
  }, [fundingPurpose])

  const generateStepIndexes = (
    stepsByTimeline: IStepsByTimeline
  ): IStepIndexes => {
    const { basicInfoSteps, businessInfoSteps, ownerInfoSteps, reviewSteps } =
      stepsByTimeline

    let latestIndex = 0

    const [
      basicInfoStepsIndexes,
      businessInfoStepsIndexes,
      ownerInfoStepsIndexes,
      reviewStepsIndexes
    ] = [basicInfoSteps, businessInfoSteps, ownerInfoSteps, reviewSteps].map(
      (steps) => {
        if (!steps.length) {
          return []
        }

        const currentStepIndexes = Array.from(
          { length: steps.length },
          (_, i) => (latestIndex ? i + 1 + latestIndex : i)
        ) as number[]

        latestIndex = currentStepIndexes.at(-1)!

        return currentStepIndexes
      }
    )

    return {
      basicInfo: basicInfoStepsIndexes,
      businessInfo: businessInfoStepsIndexes,
      ownerInfo: ownerInfoStepsIndexes,
      reviewSteps: reviewStepsIndexes
    }
  }

  useEffect(() => {
    const { basicInfoSteps, businessInfoSteps, ownerInfoSteps, reviewSteps } =
      stepsByTimeline

    const stepKeys = [
      ...basicInfoSteps,
      ...businessInfoSteps,
      ...ownerInfoSteps,
      ...reviewSteps
    ] as TFormStep[]

    const stepIndexes = generateStepIndexes({
      basicInfoSteps,
      businessInfoSteps,
      ownerInfoSteps,
      reviewSteps
    })

    dispatch(setFormSteps(stepKeys))
    dispatch(setStepIndexes(stepIndexes))
  }, [shouldShowRejectedView, stepsByTimeline])
}

export default useTimeline
