import { FC } from 'react'

import { Form, Input, InputProps } from 'antd'
import { Rule } from 'antd/es/form'
import { NamePath } from 'antd/es/form/interface'

import './InputFormItem.scss'

interface IInputFormItemProps extends InputProps {
  name: NamePath
  label?: string
  rules?: Rule[]
  validateDebouce?: number
}

const InputFormItem: FC<IInputFormItemProps> = ({
  name,
  label,
  rules,
  validateDebouce,
  ...rest
}) => {
  return (
    <Form.Item
      name={name}
      className={'input'}
      label={label}
      rules={rules ?? [{ required: true }]}
      validateDebounce={validateDebouce}
    >
      <Input {...rest} />
    </Form.Item>
  )
}

export default InputFormItem
