import { FC } from 'react'

import { Form, Input } from 'antd'
import { Rule } from 'antd/es/form'
import { NamePath } from 'antd/es/form/interface'

import locationIcon from '@/images/icons/location-icon.svg'

import './AddressFormItem.scss'

interface IAddressFormItemProps {
  name: NamePath
  label?: string
  rules?: Rule[]
}

const AddressFormItem: FC<IAddressFormItemProps> = ({
  name,
  rules,
  label = 'Address'
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      className={'address-input'}
      rules={[
        ...(rules ?? [
          {
            required: true,
            whitespace: true
          }
        ])
      ]}
    >
      <Input
        placeholder='Enter Address'
        prefix={<img src={locationIcon} alt={'location-icon'} />}
        autoComplete='address-line1'
      />
    </Form.Item>
  )
}

export default AddressFormItem
