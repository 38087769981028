import { FC } from 'react'

import { Typography } from 'antd'
import { Rule } from 'antd/es/form'
import { NamePath } from 'antd/es/form/interface'

import InputFormItem from '../default-items/input-form-item'

import './BusinessActivityCode.scss'

interface IBusinessActivityCodeProps {
  name: NamePath
  rules?: Rule[]
}

const BusinessActivityCode: FC<IBusinessActivityCodeProps> = ({
  name,
  rules
}) => {
  return (
    <>
      <InputFormItem
        label='NAICS Code'
        name={name}
        placeholder='Enter NAICS code'
        maxLength={6}
        inputMode='numeric'
        rules={[
          ...(rules ?? [
            {
              required: true
            }
          ]),
          {
            pattern: /^\d{6}$/,
            message:
              'Please enter a valid NAICS code to help us categorize your business correctly'
          }
        ]}
      />
      <Typography.Text className='helper-text'>
        Not sure which NAICS code applies to your business? Click{' '}
        <a
          href='https://www.naics.com/search/'
          referrerPolicy='no-referrer'
          target='_blank'
          rel='noreferrer'
        >
          here
        </a>{' '}
        to search for the correct code based on your industry.
      </Typography.Text>
    </>
  )
}
export default BusinessActivityCode
