import React from 'react'

import { patchApplication } from '@/api/app.service'
import FicoScoreFormItem from '@/components/form/form-items/FicoScoreFormItem'
import NavigationControls from '@/components/navigation-controls/NavigationControls'
import { FICO_SCORE_TEXT } from '@/constants/texts'
import { useTypedSelector } from '@/hooks/redux'
import usePatchApplication from '@/hooks/usePatchApplication'
import { IStepProps } from '@/types/types'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import './FicoScore.scss'

const FicoScore: React.FC<IStepProps> = ({ form, next, prev }) => {
  const applicationId = useTypedSelector(
    ({ formState }) => formState.applicationId
  )

  const [patch, isLoading] = usePatchApplication(
    next,
    async ({ ficoScoreType }: { ficoScoreType: string }) => {
      await tryCatchWithMessage(async () => {
        await patchApplication(applicationId, [
          {
            path: '/ficoScoreType',
            value: ficoScoreType
          }
        ])
      })
    }
  )

  return (
    <div className={'fico-score'}>
      <h2 className={'fico-score__title'}>{FICO_SCORE_TEXT.title}</h2>
      <p className={'fico-score__subtitle'}>{FICO_SCORE_TEXT.subtitle}</p>
      <FicoScoreFormItem />
      <NavigationControls
        isLoading={isLoading}
        onNext={() => {
          patch()
        }}
        onPrev={() => prev()}
      />
    </div>
  )
}

export default FicoScore
