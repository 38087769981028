import { Col, Row } from 'antd'

import { patchApplication } from '@/api/app.service'
import BusinessEinFormItem from '@/components/form/form-items/BusinessEinFormItem'
import BusinessEntityFormItem from '@/components/form/form-items/BusinessEntityFormItem'
import BusinessNameFormItem from '@/components/form/form-items/BusinessNameFormItem'
import BusinessStartDateFormItem from '@/components/form/form-items/BusinessStartDateFormItem'
import CityFormItem from '@/components/form/form-items/CityFormItem'
import EmployeesNumberFormItem from '@/components/form/form-items/EmployeesNumberFormItem'
import IndustryFormItem from '@/components/form/form-items/IndustryFormItem'
import SelectStateFormItem from '@/components/form/form-items/SelectStateFormItem'
import ZipCodeFormItem from '@/components/form/form-items/ZipCodeFormItem'
import AddressFormItem from '@/components/form/form-items/address-form-item/AddressFormItem'
import BusinessActivityCode from '@/components/form/form-items/business-activity-code/BusinessActivityCode'
import InputFormItem from '@/components/form/form-items/default-items/input-form-item'
import RadioFormItem from '@/components/form/form-items/default-items/radio-form-item/RadioFormItem'
import {
  ADDRESS_FIELD_NAMES_MAP,
  FORM_FIELD_NAMES_MAP,
  LOCAL_TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP,
  TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP
} from '@/constants/formFieldNames'
import { YES_OR_NO_TYPE_OPTIONS } from '@/constants/formFieldOptions'
import { BUSINESS_INFO_TEXT } from '@/constants/texts'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import { IEditField, TEditFieldKey, TUseFieldEdit } from './types'
import PhoneInputFormItem from '@/components/form/form-items/phone-input-form-item/PhoneInputFormItem'
import getFormattedPhoneNumber from '@/utils/getFormattedPhoneNumber'

const { targetBusinessOverview } = FORM_FIELD_NAMES_MAP

const {
  industryType,
  name,
  formationState,
  businessStartedAt,
  businessEntityType,
  businessEIN,
  businessActivityCode,
  isFranchise,
  dbaName,
  isDBA,
  franchiseName,
  legalAddressSameAsPhysical,
  physicalAddress,
  legalAddress,
  employeesCount,
  phoneNumber
} = TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP

const { city, zipCode, street, usaStateType } = ADDRESS_FIELD_NAMES_MAP

const { hasBusinessName } = LOCAL_TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP

const useTargetBusinessFieldEdit: TUseFieldEdit = (applicationId, form) => {
  const getAddressField = (addressName: string): IEditField => {
    return {
      title: BUSINESS_INFO_TEXT.title,
      subtitle: BUSINESS_INFO_TEXT.subtitle,
      namePath: [
        [targetBusinessOverview, addressName, street],
        [targetBusinessOverview, addressName, city],
        [targetBusinessOverview, addressName, usaStateType],
        [targetBusinessOverview, addressName, zipCode]
      ],
      input: () => (
        <Row>
          <Col span={24}>
            <AddressFormItem name={street} />
          </Col>
          <Col span={24}>
            <CityFormItem name={city} />
          </Col>
          <Col span={24}>
            <SelectStateFormItem name={usaStateType} />
          </Col>
          <Col span={24}>
            <ZipCodeFormItem name={zipCode} />
          </Col>
        </Row>
      ),
      onSave: async (newValue) => {
        const legalAddressSameAsPhysicalValue = form.getFieldValue([
          targetBusinessOverview,
          legalAddressSameAsPhysical
        ])

        const anotherAddress =
          addressName === physicalAddress ? legalAddress : physicalAddress

        const inputValue = [
          {
            path: `/targetBusinessOverview/${addressName}`,
            value: newValue
          }
        ]

        if (legalAddressSameAsPhysicalValue) {
          inputValue.push({
            path: `/targetBusinessOverview/${anotherAddress}`,
            value: newValue
          })
        }

        await tryCatchWithMessage(async () => {
          await patchApplication(applicationId, inputValue).then(() => {
            form.setFieldValue([targetBusinessOverview, addressName], newValue)

            legalAddressSameAsPhysicalValue &&
              form.setFieldValue(
                [targetBusinessOverview, anotherAddress],
                newValue
              )
          })
        })
      }
    }
  }

  const getSelectedField = (
    selectedFieldKey: TEditFieldKey
  ): IEditField | null => {
    switch (selectedFieldKey) {
      case 'target-business-has-name':
        return {
          title: BUSINESS_INFO_TEXT.title,
          subtitle: BUSINESS_INFO_TEXT.subtitle,
          namePath: [hasBusinessName],
          onSave: async ({ hasBusinessName: hasBusinessNameValue }) => {
            form.setFieldValue([hasBusinessName], hasBusinessNameValue)

            if (!hasBusinessNameValue) {
              await tryCatchWithMessage(async () => {
                await patchApplication<string | boolean>(applicationId, [
                  {
                    path: '/targetBusinessOverview/name',
                    value: ''
                  },
                  {
                    path: '/targetBusinessOverview/isDBA',
                    value: false
                  },
                  {
                    path: '/targetBusinessOverview/dbaName',
                    value: ''
                  }
                ]).then(() => {
                  form.setFieldValue([targetBusinessOverview, name], undefined)
                  form.setFieldValue([targetBusinessOverview, isDBA], undefined)
                  form.setFieldValue(
                    [targetBusinessOverview, dbaName],
                    undefined
                  )
                })
              })
            }
          },
          input: () => (
            <RadioFormItem
              name={hasBusinessName}
              options={YES_OR_NO_TYPE_OPTIONS}
              label='Presence of New Business Name'
            />
          )
        }
      case 'target-business-name':
        return {
          title: BUSINESS_INFO_TEXT.title,
          subtitle: BUSINESS_INFO_TEXT.subtitle,
          namePath: [targetBusinessOverview, name],
          onSave: async ({ name: nameValue }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: '/targetBusinessOverview/name',
                  value: nameValue
                }
              ]).then(() =>
                form.setFieldValue([targetBusinessOverview, name], nameValue)
              )
            })
          },
          input: () => <BusinessNameFormItem name={name} />
        }
      case 'target-business-as-dba':
        return {
          title: BUSINESS_INFO_TEXT.title,
          subtitle: BUSINESS_INFO_TEXT.subtitle,
          namePath: [targetBusinessOverview, isDBA],
          onSave: async ({ isDBA: isDBAaValue }) => {
            await tryCatchWithMessage(async () => {
              const inputValue = [
                {
                  path: '/targetBusinessOverview/isDBA',
                  value: isDBAaValue
                }
              ]
              !isDBAaValue &&
                inputValue.push({
                  path: '/targetBusinessOverview/dbaName',
                  value: ''
                })

              await patchApplication(applicationId, inputValue).then(() => {
                form.setFieldValue([targetBusinessOverview, isDBA], isDBAaValue)
                !isDBAaValue &&
                  form.setFieldValue([targetBusinessOverview, dbaName], '')
              })
            })
          },
          input: () => (
            <RadioFormItem name={isDBA} options={YES_OR_NO_TYPE_OPTIONS} />
          )
        }

      case 'target-business-dba-name':
        return {
          title: BUSINESS_INFO_TEXT.title,
          subtitle: BUSINESS_INFO_TEXT.subtitle,
          namePath: [targetBusinessOverview, dbaName],
          onSave: async ({ dbaName: dbaNameValue }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                { path: '/targetBusinessOverview/dbaName', value: dbaNameValue }
              ]).then(() =>
                form.setFieldValue(
                  [targetBusinessOverview, dbaName],
                  dbaNameValue
                )
              )
            })
          },
          input: () => (
            <InputFormItem
              name={dbaName}
              label="Name You're Doing Business As"
              placeholder='Enter name'
            />
          )
        }
      case 'target-business-state':
        return {
          title: BUSINESS_INFO_TEXT.title,
          subtitle: BUSINESS_INFO_TEXT.subtitle,
          namePath: [targetBusinessOverview, formationState],
          onSave: async ({ formationState: formationStateValue }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: '/targetBusinessOverview/formationState',
                  value: formationStateValue
                }
              ]).then(() =>
                form.setFieldValue(
                  [targetBusinessOverview, formationState],
                  formationStateValue
                )
              )
            })
          },
          input: () => <SelectStateFormItem name={formationState} />
        }
      case 'target-business-industry':
        return {
          title: BUSINESS_INFO_TEXT.title,
          subtitle: BUSINESS_INFO_TEXT.subtitle,
          namePath: [targetBusinessOverview, industryType],
          onSave: async ({ industryType: industryTypeValue }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: '/targetBusinessOverview/industryType',
                  value: industryTypeValue
                }
              ]).then(() =>
                form.setFieldValue(
                  [targetBusinessOverview, industryType],
                  industryTypeValue
                )
              )
            })
          },
          input: () => <IndustryFormItem name={industryType} />
        }
      case 'target-business-start-date':
        return {
          title: BUSINESS_INFO_TEXT.title,
          subtitle: BUSINESS_INFO_TEXT.subtitle,
          namePath: [targetBusinessOverview, businessStartedAt],
          onSave: async ({ businessStartedAt: businessStartedAtValue }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: '/targetBusinessOverview/businessStartedAt',
                  value: businessStartedAtValue
                }
              ]).then(() =>
                form.setFieldValue(
                  [targetBusinessOverview, businessStartedAt],
                  businessStartedAtValue
                )
              )
            })
          },
          input: () => <BusinessStartDateFormItem name={businessStartedAt} />
        }
      case 'target-business-entity-type':
        return {
          title: BUSINESS_INFO_TEXT.title,
          subtitle: BUSINESS_INFO_TEXT.subtitle,
          namePath: [targetBusinessOverview, businessEntityType],
          onSave: async ({
            businessEntityType: businessEntityTypeValue
          }: {
            businessEntityType: string
          }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: '/targetBusinessOverview/businessEntityType',
                  value: businessEntityTypeValue
                }
              ]).then(() =>
                form.setFieldValue(
                  [targetBusinessOverview, businessEntityType],
                  businessEntityTypeValue
                )
              )
            })
          },
          input: () => <BusinessEntityFormItem name={businessEntityType} />
        }
      case 'target-business-ein':
        return {
          title: BUSINESS_INFO_TEXT.title,
          subtitle: BUSINESS_INFO_TEXT.subtitle,
          namePath: [targetBusinessOverview, businessEIN],
          onSave: async ({
            businessEIN: businessEINValue
          }: {
            businessEIN: string
          }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: '/targetBusinessOverview/businessEIN',
                  value: businessEINValue?.replace(/-/g, '')
                }
              ]).then(() =>
                form.setFieldValue(
                  [targetBusinessOverview, businessEIN],
                  businessEINValue
                )
              )
            })
          },
          input: () => <BusinessEinFormItem name={businessEIN} />
        }
      case 'target-business-activity-code':
        return {
          title: BUSINESS_INFO_TEXT.title,
          subtitle: BUSINESS_INFO_TEXT.subtitle,
          namePath: [targetBusinessOverview, businessActivityCode],
          onSave: async ({
            businessActivityCode: businessActivityCodeValue
          }: {
            businessActivityCode: string
          }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: '/targetBusinessOverview/businessActivityCode',
                  value: businessActivityCodeValue
                }
              ]).then(() =>
                form.setFieldValue(
                  [targetBusinessOverview, businessActivityCode],
                  businessActivityCodeValue
                )
              )
            })
          },
          input: () => <BusinessActivityCode name={businessActivityCode} />
        }
      case 'target-business-if-franchise':
        return {
          title: BUSINESS_INFO_TEXT.title,
          subtitle: BUSINESS_INFO_TEXT.subtitle,
          namePath: [targetBusinessOverview, isFranchise],
          onSave: async ({
            isFranchise: isFranchiseValue
          }: {
            isFranchise: boolean
          }) => {
            await tryCatchWithMessage(async () => {
              const inputValue: { path: string; value: string | boolean }[] = [
                {
                  path: '/targetBusinessOverview/isFranchise',
                  value: isFranchiseValue
                }
              ]

              !isFranchiseValue &&
                inputValue.push({
                  path: '/targetBusinessOverview/franchiseName',
                  value: ''
                })

              await patchApplication(applicationId, inputValue).then(() => {
                form.setFieldValue(
                  [targetBusinessOverview, isFranchise],
                  isFranchiseValue
                )
                !isFranchiseValue &&
                  form.setFieldValue(
                    [targetBusinessOverview, franchiseName],
                    undefined
                  )
              })
            })
          },
          input: () => (
            <RadioFormItem
              label='Is it a franchise?'
              name={isFranchise}
              options={YES_OR_NO_TYPE_OPTIONS}
            />
          )
        }
      case 'target-business-franchise-name':
        return {
          title: BUSINESS_INFO_TEXT.title,
          subtitle: BUSINESS_INFO_TEXT.subtitle,
          namePath: [targetBusinessOverview, franchiseName],
          onSave: async ({
            franchiseName: franchiseNameValue
          }: {
            franchiseName: string
          }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: '/targetBusinessOverview/franchiseName',
                  value: franchiseNameValue
                }
              ]).then(() =>
                form.setFieldValue(
                  [targetBusinessOverview, franchiseName],
                  franchiseNameValue
                )
              )
            })
          },
          input: () => (
            <InputFormItem
              name={franchiseName}
              label='Name of Franchise'
              placeholder='Enter name'
            />
          )
        }
      case 'target-business-legal-address':
        return getAddressField(legalAddress)
      case 'target-business-physical-address':
        return getAddressField(physicalAddress)
      case 'target-business-employees-count':
        return {
          title: BUSINESS_INFO_TEXT.title,
          subtitle: BUSINESS_INFO_TEXT.subtitle,
          namePath: [targetBusinessOverview, employeesCount],
          onSave: async ({ employeesCount: employeesCountValue }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: '/targetBusinessOverview/employeesCount',
                  value: employeesCountValue
                }
              ]).then(() =>
                form.setFieldValue(
                  [targetBusinessOverview, employeesCount],
                  employeesCountValue
                )
              )
            })
          },
          input: () => <EmployeesNumberFormItem name={employeesCount} />
        }
      case 'target-business-phone-number':
        return {
          title: BUSINESS_INFO_TEXT.title,
          subtitle: BUSINESS_INFO_TEXT.subtitle,
          namePath: [targetBusinessOverview, phoneNumber],
          onSave: async ({ phoneNumber: phoneNumberValue }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: '/targetBusinessOverview/phoneNumber',
                  value: getFormattedPhoneNumber(phoneNumberValue)
                }
              ]).then(() =>
                form.setFieldValue(
                  [targetBusinessOverview, phoneNumber],
                  phoneNumberValue
                )
              )
            })
          },
          input: () => <PhoneInputFormItem name={phoneNumber}  />
        }
      default:
        return null
    }
  }

  return getSelectedField
}

export default useTargetBusinessFieldEdit
