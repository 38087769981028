import React, { FC, HTMLAttributes } from 'react'

const Warning: FC<HTMLAttributes<SVGElement>> = (props) => {
  return (
    <svg
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12.0014 9.49975V11.4998M12.0014 15.4998H12.0114M5.07339 19.4998H18.9294C20.4694 19.4998 21.4314 17.8328 20.6614 16.4998L13.7334 4.49975C12.9634 3.16675 11.0394 3.16675 10.2694 4.49975L3.34139 16.4998C2.57139 17.8328 3.53339 19.4998 5.07339 19.4998Z'
        stroke='#9D5E00'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default Warning
