import { ReactElement } from 'react'

import StepDesktopCompleted from '@/components/icons/StepDesktopCompleted'
import StepFourDesktop from '@/components/icons/StepFourDesktop'
import StepOneDesktop from '@/components/icons/StepOneDesktop'
import StepThreeDesktop from '@/components/icons/StepThreeDesktop'
import StepTwoDesktop from '@/components/icons/StepTwoDesktop'
import { TTimelineStep } from '@/types/formSteps'

interface ITimeLineStep {
  key: TTimelineStep
  notActiveDesktopIcon: ReactElement
  activeDesktopIcon: ReactElement
  completedDesktopIcon: ReactElement
  title: string
  description: string
}

export const timeLineSteps: ITimeLineStep[] = [
  {
    key: 'basic-information',
    notActiveDesktopIcon: <StepOneDesktop />,
    activeDesktopIcon: <StepOneDesktop status='active' />,
    completedDesktopIcon: <StepDesktopCompleted />,
    title: 'Basic Information',
    description: 'Loan Basics'
  },
  {
    key: 'business-information',
    notActiveDesktopIcon: <StepThreeDesktop />,
    activeDesktopIcon: <StepThreeDesktop status='active' />,
    completedDesktopIcon: <StepDesktopCompleted />,
    title: 'Business Information',
    description: 'Your Business Details'
  },
  {
    key: 'owner-information',
    notActiveDesktopIcon: <StepTwoDesktop />,
    activeDesktopIcon: <StepTwoDesktop status='active' />,
    completedDesktopIcon: <StepDesktopCompleted />,
    title: 'Owner Information',
    description: 'Ownership Details'
  },
  {
    key: 'review',
    notActiveDesktopIcon: <StepFourDesktop />,
    activeDesktopIcon: <StepFourDesktop status='active' />,
    completedDesktopIcon: <StepDesktopCompleted />,
    title: 'Review',
    description: 'Review & Confirm'
  }
]
