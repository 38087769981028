import { FC } from 'react'

import { REJECTION_REASONS } from '@/constants/rejectionReasons'

const AlternativeFundingDeclined: FC = () => {
  return (
    <div className='contact-declined'>
      <h2 className={'contact-declined__title'}>
        Thank You for Your Application!
      </h2>
      <p className={'contact-declined__subtitle'}>
        We&apos;re unable to offer funding that matches your criteria at this
        moment. Consider reapplying when:
      </p>
      <ul className={'contact-declined__rejection-reasons'}>
        {REJECTION_REASONS.map((reason, index) => (
          <li key={index}>{reason}</li>
        ))}
      </ul>
    </div>
  )
}

export default AlternativeFundingDeclined
