import { GenericAbortSignal } from 'axios'

import {
  AIAnalysisProgressDto,
  ApplicationDto,
  PatchRequestDto
} from '@/generated-types'

import APIinstance from './config'

export const submitApplication = async (
  input: ApplicationDto
): Promise<ApplicationDto> => {
  const { data } = await APIinstance.post<ApplicationDto>('applications', input)
  return data
}

export const patchApplication = async <T>(
  applicationId: string,
  input: (Omit<PatchRequestDto, 'op' | 'value'> & {
    value: object | T
  })[],
  signal?: GenericAbortSignal
): Promise<ApplicationDto> => {
  const inputArr = []

  for (const { value, path } of input) {
    inputArr.push({
      path,
      value,
      op: 'replace'
    })
  }

  const { data } = await APIinstance.patch<ApplicationDto>(
    `applications/${applicationId}`,
    JSON.stringify(inputArr),
    {
      signal
    }
  )

  return data
}

export const getApplication = async (
  applicationId: string
): Promise<ApplicationDto> => {
  const { data } = await APIinstance.get<ApplicationDto>(
    `applications/${applicationId}`
  )
  return data
}

export const analysisProgress = async (
  applicationId: string
): Promise<AIAnalysisProgressDto> => {
  const { data } = await APIinstance.get<AIAnalysisProgressDto>(
    `applications/${applicationId}/analysis-progress`
  )
  return data
}
