import { DescriptionsItemType } from 'antd/es/descriptions'

export const formatMoneyValue = (value: number = 0) =>
  value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

export const optionalItem = (
  condition: boolean,
  item: DescriptionsItemType
): DescriptionsItemType[] => {
  return condition ? [item] : []
}
