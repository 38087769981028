import { FC } from 'react'

import { DatePicker, Form } from 'antd'
import { Rule } from 'antd/es/form'
import { NamePath } from 'antd/es/form/interface'
import dayjs from 'dayjs'

import DEFAULT_DATE_FORMAT, {
  DEFAULT_DATE_FORMAT_PLACEHOLDER
} from '@/constants/defaultDateFormat'
import calendarIcon from '@/images/icons/calendar-icon.svg'

import './DatePickerFormItem.scss'

interface IDatePickerFormItemProps {
  name: NamePath
  label: string
  required?: boolean
  rules?: Rule[]
}

const DatePickerFormItem: FC<IDatePickerFormItemProps> = ({
  name,
  label,
  required = true,
  rules
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={
        rules ?? [
          {
            required,
          }
        ]
      }
      className={'date-wrapper'}
    >
      <DatePicker
        style={{ display: 'flex' }}
        format={DEFAULT_DATE_FORMAT}
        placeholder={DEFAULT_DATE_FORMAT_PLACEHOLDER}
        suffixIcon={<img src={calendarIcon} alt={'input-icon'} />}
        disabledDate={(currentDate) => {
          return currentDate && currentDate > dayjs().endOf('day')
        }}
        className={'date-picker'}
      />
    </Form.Item>
  )
}

export default DatePickerFormItem
