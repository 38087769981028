import { FC } from 'react'

import { useQueryClient } from '@tanstack/react-query'

import { partnersQueries } from '@/api/partners.service'
import EmailIcon from '@/components/icons/Email'
import { PartnerDto } from '@/generated-types'

const AlternativeFundingApproved: FC = () => {
  const queryClient = useQueryClient()

  const data = queryClient.getQueryData<Required<PartnerDto>>(
    partnersQueries.partner.me.queryKey
  )
  const partnerName = data?.name

  return (
    <div className={'contact-approved'}>
      <EmailIcon className={'contact-approved__icon'} />
      <h2 className='contact-approved__title'>Thank you!</h2>
      <p className={'contact-approved__subtitle'}>
        Your {partnerName} advocate will contact you soon.
      </p>
    </div>
  )
}

export default AlternativeFundingApproved
