import { createSlice } from '@reduxjs/toolkit'

interface IFormStateInitialState {
  applicationId: string
  isNavigatedFromReview: boolean
  onBackToReviewClick?: (step?: number) => Promise<void>
  isAtLeastOneDocumentUploaded: boolean
  isAgreedPrefill: boolean
  prefilledData: any
  isSignatureValid: boolean
}

const initialState: IFormStateInitialState = {
  applicationId: '',
  isNavigatedFromReview: false,
  isAtLeastOneDocumentUploaded: false,
  isAgreedPrefill: false,
  isSignatureValid: false,
  prefilledData: {
    id: null,
    totalFunding: null,
    fundingPurposeType: null,
    fundingPurpose: null,
    ficoScoreType: null,
    annualRevenue: null,
    annualRevenueType: null,
    annualProfit: null,
    annualProfitType: null,
    targetBusinessOverview: {
      id: null,
      name: null,
      industryType: null,
      usaStateType: null,
      businessStartedAt: null,
      isStarted: true,
      isFranchise: true,
      hasGuarantors: true
    },
    owners: [
      {
        id: null,
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        birthDate: null,
        businessWeight: null,
        isPrimary: true,
        address: {
          id: null,
          street: null,
          city: null,
          zipCode: null,
          usaStateType: null
        },
        socialSecurityNumber: null,
        maritalStatusType: null
      }
    ],
    businessOverview: {
      id: null,
      name: null,
      isDBA: false,
      dbaName: null,
      phoneNumber: null,
      address: {
        id: null,
        street: null,
        city: null,
        zipCode: null,
        usaStateType: null
      },
      businessStartedAt: null,
      businessEntityType: null,
      formationState: null,
      businessEIN: null,
      annualRevenue: null,
      annualProfit: null,
      businessActivityCode: null,
      legalAddress: {
        street: null,
        city: null,
        zipCode: null,
        usaStateType: null
      }
    },
    businessTaxReturn: [],
    personalTaxReturn: [],
    governmentIdentity: [],
    isDraft: false
  }
}

const formStateSlice = createSlice({
  name: 'formState',
  initialState,
  reducers: {
    setOnBackToReviewClick: (
      state,
      action: { payload: (step?: number) => Promise<void> }
    ) => {
      state.onBackToReviewClick = action.payload
    },
    setApplicationId: (state, action: { payload: string }) => {
      state.applicationId = action.payload
    },
    setIsNavigatedFromReview: (state, action: { payload: boolean }) => {
      state.isNavigatedFromReview = action.payload
    },
    toggleIsNavigatedFromReview: (state) => {
      state.isNavigatedFromReview = !state.isNavigatedFromReview
    },
    setIsAtLeastOneDocumentUploaded: (state, action: { payload: boolean }) => {
      state.isAtLeastOneDocumentUploaded = action.payload
    },
    setPrefilledData: (state, action: { payload: any }) => {
      state.prefilledData = action.payload
    },
    setIsAgreedPrefill: (state, action: { payload: boolean }) => {
      state.isAgreedPrefill = action.payload
    },
    setIsSignatureValid: (state, action: { payload: boolean }) => {
      state.isSignatureValid = action.payload
    }
  }
})

export const {
  toggleIsNavigatedFromReview,
  setApplicationId,
  setOnBackToReviewClick,
  setIsAtLeastOneDocumentUploaded,
  setPrefilledData,
  setIsAgreedPrefill,
  setIsSignatureValid
} = formStateSlice.actions
export default formStateSlice.reducer
