import { ReactElement } from 'react'

import { Flex, Form, Upload, UploadProps } from 'antd'
import { NamePath } from 'antd/es/form/interface'
import classNames from 'classnames'

import Delete from '@/components/icons/Delete'
import ImageFile from '@/components/icons/ImageFile'
import PdfFile from '@/components/icons/PdfFile'
import UploadIcon from '@/components/icons/UploadIcon'
import {
  TFormFile,
  beforeUpload,
  getFilesFromEvent,
} from '@/utils/formFileList'
import removeFile from '@/utils/removeFile'

import './FormUploadInput.scss'

type TFormUploadInputProps = Omit<UploadProps, 'name'> & {
  name: NamePath
  fileList?: TFormFile[]
  className?: string,
  filesMaxCount?: number,
  allowedTypes?: string[],
  supportsLabel?: string
}

const FormUploadInput = ({
  name,
  fileList = [],
  className = '',
  filesMaxCount = 1,
  allowedTypes,
  supportsLabel = 'PNG, JPG, PDF',
  ...props
}: TFormUploadInputProps): ReactElement => {
  return (
    <div className={`upload-input__upload-wrapper ${className}`}>
      <Form.Item
        name={name}
        valuePropName='fileList'
        getValueFromEvent={getFilesFromEvent}
      >
        <Upload
          type='drag'
          listType='text'
          beforeUpload={beforeUpload(allowedTypes, filesMaxCount, fileList)}
          iconRender={(): null => null}
          multiple
          maxCount={filesMaxCount}
          rootClassName={classNames('upload-input__wrapper', {
            disabled: fileList.length >= 10
          })}
          itemRender={(_originNode, file: TFormFile, _fileList, { remove }) => (
            <Flex gap={8} align='center'>
              {file.type === 'application/pdf' ? (
                <PdfFile className='file-type' />
              ) : (
                <ImageFile className='file-type' />
              )}
              {file.name}
              <Delete
                className='delete-icon'
                onClick={() => {
                  removeFile(file.id)
                  remove()
                }}
              />
            </Flex>
          )}
          {...props}
        >
          <div className='upload-input__upload-trigger'>
            <UploadIcon />
            <h3 className='upload-input__label upload-input__upload-trigger__label'>
              <span>Click to upload</span> or drag & drop
            </h3>
            <span className=' upload-input__upload-trigger__supports-label '>
              {`Supports: ${supportsLabel}`}
            </span>
          </div>
        </Upload>
      </Form.Item>
    </div>
  )
}

export default FormUploadInput
