import { FC, useState } from 'react'

import { Drawer, Flex } from 'antd'

import withDeviceDependent from '@/hocs/with-device-dependent/WithDeviceDependent'
import useIsMobile from '@/hooks/useIsMobile'

import Close from '../icons/Close'
import MenuIcon from '../icons/MenuIcon'
import Logo from '../logo/Logo'
import TimeLine from '../time-line/TimeLine'

import './FormMobileHeader.scss'

const FormMobileHeader: FC = () => {
  const [drawerOpened, setDrawerOpened] = useState(false)

  const toggleDrawerOpen = () => {
    setDrawerOpened((prev) => !prev)
  }

  const isMobile = useIsMobile()

  return (
    <>
      <Flex className='form-header' justify='space-between'>
        <Logo />
        <MenuIcon
          className='form-header__menu-icon'
          onClick={toggleDrawerOpen}
        />
      </Flex>
      <Drawer
        classNames={{
          body: 'drawer-body',
          header: 'drawer-header',
          content: 'drawer-content'
        }}
        open={drawerOpened}
        onClose={toggleDrawerOpen}
        title={<Logo />}
        closeIcon={<Close />}
        width={isMobile ? '100vw' : 375}
      >
        <TimeLine onChangeStep={toggleDrawerOpen} />
      </Drawer>
    </>
  )
}

export default withDeviceDependent(FormMobileHeader, false)
