import { useQuery } from '@tanstack/react-query'

import { partnersQueries } from '@/api/partners.service'

export const useGetLogo = (logoId?: string) => {
  const { data, isLoading } = useQuery({
    ...partnersQueries.partner.logo(logoId)
  })

  return { data, isLoading }
}
