import { FC, useEffect, useMemo, useRef, useState } from 'react'

import { FloatButton, Modal } from 'antd'
import classNames from 'classnames'

import Close from '@/components/icons/Close'
import DoubleArrow from '@/components/icons/DoubleArrow'
import PlayIcon from '@/components/icons/Play'
import { useTypedSelector } from '@/hooks/redux'
import VideoPoster from '@/images/video-guide-poster.png'

import './VideoGuide.scss'

const VideoGuide: FC = () => {
  const { formSteps, stepIndex } = useTypedSelector(({ timeLine }) => ({
    stepIndex: timeLine.formStep,
    formSteps: timeLine.formSteps
  }))

  const [isHidden, setIsHidden] = useState(false)
  const [isVideoGuideOpened, setIsVideoGuideOpened] = useState(false)

  const guideShownRef = useRef(false)

  const toggleVideoGuide = () => {
    if (isHidden) {
      setIsHidden(false)
      return
    }

    setIsVideoGuideOpened((prev) => !prev)
  }

  useEffect(() => {
    if (
      guideShownRef.current ||
      formSteps[stepIndex] !== 'primary-contact-details'
    ) {
      return
    }

    setIsVideoGuideOpened(true)
    guideShownRef.current = true
  }, [formSteps, stepIndex])

  const showVideoGuide = useMemo(() => {
    if (formSteps.length === 0 || !guideShownRef.current) {
      return false
    }

    return (
      !isVideoGuideOpened &&
      formSteps.indexOf('primary-contact-details') <= stepIndex &&
      formSteps[stepIndex] !== 'review'
    )
  }, [formSteps, stepIndex, isVideoGuideOpened])

  return (
    <>
      <Modal
        open={isVideoGuideOpened}
        onCancel={() => toggleVideoGuide()}
        className='video-guide'
        classNames={{
          content: 'video-guide__content',
          mask: 'video-guide__mask'
        }}
        footer={null}
        width={{
          xs: '343px',
          sm: '343px',
          md: '650px',
          lg: '650px',
          xl: '1234px',
          xxl: '1234px'
        }}
        closeIcon={<Close />}
        destroyOnClose
      >
        <video
          controls
          preload='metadata'
          width='100%'
          height='auto'
          poster={VideoPoster}
          onEnded={() => toggleVideoGuide()}
        >
          <source
            src='https://doclibrarystorage.s3.ap-southeast-2.amazonaws.com/public_video/Application+Form+Explainer.mp4'
            type='video/mp4'
          />
        </video>
      </Modal>
      {showVideoGuide && (
        <FloatButton
          className={classNames('float-btn', {
            'float-btn-hidden': isHidden
          })}
          description={
            <>
              <span className='float-btn__main-text'>Watch Video</span>
              <span className='float-btn__secondary-text'>
                Easy Apply Guide
              </span>
            </>
          }
          shape='square'
          icon={
            <>
              <div
                className='double-arrow-clickable-area'
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setIsHidden((prev) => !prev)
                }}
              />
              <DoubleArrow className='double-arrow-icon' />
              <PlayIcon className='play-icon' />
            </>
          }
          onClick={() => toggleVideoGuide()}
        />
      )}
    </>
  )
}

export default VideoGuide
