import { FC } from 'react'

import SecurityIcon from '@/components/icons/SecurityIcon'
import UserAgreement from '@/components/user-agreement/UserAgreement'

import './DataSecurity.scss'

const DataSecurity: FC = () => {
  return (
    <div className='security-info'>
      <div className='security-info__content'>
        <h2 className='security-info__title'>Your Data Is Safe With Us</h2>
        <p className='security-info__description'>
          Your privacy is our priority. We never sell your data to third
          parties. Your information is used only to process your application
          safely and efficiently.
        </p>
        <UserAgreement />
      </div>
      <SecurityIcon className='security-info__icon' />
    </div>
  )
}

export default DataSecurity
