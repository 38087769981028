import { TFormStep } from '@/types/formSteps'

const BASIC_INFO_DEFAULT_STEPS: TFormStep[] = [
  'funding-amount',
  'loan-purpose',
  'funds-access-time',
  'fico-score',
  'primary-contact-details'
]

const BASIC_INFO_WITHOUT_TIMELINE_STEPS: TFormStep[] = [
  'funding-amount',
  'loan-purpose',
  'fico-score',
  'primary-contact-details'
]

const BUSINESS_DEFAULT_STEPS: TFormStep[] = [
  'save-time-ai',
  'business-info',
  'business-entity-and-ein'
]

const BUSINESS_INFO_START_BUSINESS_STEPS: TFormStep[] = ['business-info']

const BUSINESS_INFO_BUY_REAL_ESTATE_STEPS: TFormStep[] = [
  'real-estate-acquisition-info',
  'save-time-ai',
  'business-info',
  'business-entity-and-ein'
]

const BUSINESS_INFO_BUY_BUSINESS_STEPS: TFormStep[] = [
  'business-acquisition-info',
  'save-time-ai'
]

const OWNER_INFO_DEFAULT_STEPS: TFormStep[] = ['business-owner-info']

const OWNER_INFO_START_BUSINESS_STEPS: TFormStep[] = [
  'business-owner-info',
  'start-business-documents-upload'
]

const REVIEW_DEFAULT_STEPS: TFormStep[] = ['review']

export {
  BASIC_INFO_DEFAULT_STEPS,
  BUSINESS_DEFAULT_STEPS,
  BUSINESS_INFO_BUY_BUSINESS_STEPS,
  REVIEW_DEFAULT_STEPS,
  BUSINESS_INFO_START_BUSINESS_STEPS,
  BUSINESS_INFO_BUY_REAL_ESTATE_STEPS,
  OWNER_INFO_DEFAULT_STEPS,
  BASIC_INFO_WITHOUT_TIMELINE_STEPS,
  OWNER_INFO_START_BUSINESS_STEPS
}
