import { FC } from 'react'

import { patchApplication } from '@/api/app.service'
import RadioFormItem from '@/components/form/form-items/default-items/radio-form-item/RadioFormItem'
import NavigationControls from '@/components/navigation-controls/NavigationControls'
import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import { TIMEFRAME_TYPE_OPTIONS } from '@/constants/formFieldOptions'
import { FUNDS_ACCESS_TIME_TEXT } from '@/constants/texts'
import { useTypedSelector } from '@/hooks/redux'
import usePatchApplication from '@/hooks/usePatchApplication'
import { IStepProps } from '@/types/types'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import './FundsAccessTime.scss'

const { timeframeType } = FORM_FIELD_NAMES_MAP

const FundsAccessTime: FC<IStepProps> = ({ next, prev }) => {
  const applicationId = useTypedSelector(
    ({ formState }) => formState.applicationId
  )

  const [patch, isLoading] = usePatchApplication(
    next,
    async ({ timeframeType: timeframeTypeValue }) => {
      await tryCatchWithMessage(async () => {
        await patchApplication(applicationId, [
          { path: '/timeframeType', value: timeframeTypeValue }
        ])
      })
    }
  )

  return (
    <div className={'funds-access-time'}>
      <h2 className={'funds-access-time__title'}>
        {FUNDS_ACCESS_TIME_TEXT.title}
      </h2>
      <p className={'funds-access-time__subtitle'}>
        {FUNDS_ACCESS_TIME_TEXT.subtitle}
      </p>
      <RadioFormItem name={timeframeType} options={TIMEFRAME_TYPE_OPTIONS} />
      <NavigationControls
        isLoading={isLoading}
        onNext={() => {
          patch()
        }}
        onPrev={() => prev()}
      />
    </div>
  )
}

export default FundsAccessTime
