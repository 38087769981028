import { FC } from 'react'

import { Descriptions, FormInstance } from 'antd'
import { DescriptionsItemType } from 'antd/es/descriptions'
import { DescriptionsProps } from 'antd/lib'
import dayjs from 'dayjs'
import { getFormattedNumber } from 'react-phone-hooks'

import DEFAULT_DATE_FORMAT from '@/constants/defaultDateFormat'
import { LOCAL_TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import {
  BUSINESS_ENTITY_OPTIONS_MAP,
  INDUSTRY_OPTIONS_MAP,
  USA_STATE_OPTIONS
} from '@/constants/formFieldOptions'
import { TargetBusinessOverviewDto } from '@/generated-types'
import getFormattedAddress from '@/utils/getFormattedAddress'
import getFormattedPhoneNumber from '@/utils/getFormattedPhoneNumber'
import { optionalItem } from '@/utils/reviewUtils'

import { EditItem } from '../edit-item'
import { IEditFieldState } from '../types'

interface IBusinessInformationDescriptionsProps {
  form: FormInstance
  onEditField: (state: IEditFieldState) => void
}

const TargetBusinessInfoDescription: FC<
  IBusinessInformationDescriptionsProps
> = ({ form, onEditField }) => {
  const {
    name,
    isDBA = false,
    dbaName,
    businessEntityType,
    formationState,
    businessStartedAt,
    businessEIN,
    businessActivityCode,
    isFranchise = false,
    franchiseName,
    employeesCount,
    industryType,
    phoneNumber,
    legalAddress,
    physicalAddress
  }: TargetBusinessOverviewDto = form.getFieldValue('targetBusinessOverview') ||
  {}

  const hasBusinessName = form.getFieldValue(
    LOCAL_TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP.hasBusinessName
  )

  const isAddressUnknown = form.getFieldValue(
    LOCAL_TARGET_BUSINESS_OVERVIEW_FIELD_NAMES_MAP.isAddressUnknown
  )

  const items: DescriptionsProps['items'] = [
    {
      key: 'has-business-name',
      label: 'Presence of New Business Name',
      children: (
        <EditItem
          onEdit={() => onEditField({ key: 'target-business-has-name' })}
        >
          {hasBusinessName ? 'Yes' : 'No'}
        </EditItem>
      )
    },
    ...optionalItem(hasBusinessName, {
      key: 'business-name',
      label: 'Business Name',
      children: (
        <EditItem onEdit={() => onEditField({ key: 'target-business-name' })}>
          {name}
        </EditItem>
      )
    }),
    ...optionalItem(hasBusinessName, {
      key: 'is-dba',
      label: 'Doing Business as DBA',
      children: (
        <EditItem onEdit={() => onEditField({ key: 'target-business-as-dba' })}>
          {isDBA ? 'Yes' : 'No'}
        </EditItem>
      )
    }),
    ...optionalItem(isDBA, {
      key: 'dba-name',
      label: 'Name You`re Doing Business As',
      children: (
        <EditItem
          onEdit={() => onEditField({ key: 'target-business-dba-name' })}
        >
          {dbaName}
        </EditItem>
      )
    }),
    {
      key: 'is-franchise',
      label: 'Is it Franchise?',
      children: (
        <EditItem
          onEdit={() => onEditField({ key: 'target-business-if-franchise' })}
        >
          {isFranchise ? 'Yes' : 'No'}
        </EditItem>
      )
    },
    ...optionalItem(isFranchise, {
      key: 'franchise-name',
      label: 'Name of Franchise',
      children: (
        <EditItem
          onEdit={() => onEditField({ key: 'target-business-franchise-name' })}
        >
          {franchiseName}
        </EditItem>
      )
    }),
    {
      key: 'employees-count',
      label: 'Number of Employees',
      children: (
        <EditItem
          onEdit={() => onEditField({ key: 'target-business-employees-count' })}
        >
          {employeesCount}
        </EditItem>
      )
    },
    {
      key: 'entity-type',
      label: 'Entity Type',
      children: (
        <EditItem
          onEdit={() => onEditField({ key: 'target-business-entity-type' })}
          editable={false}
        >
          {BUSINESS_ENTITY_OPTIONS_MAP[businessEntityType ?? '']}
        </EditItem>
      )
    },
    {
      key: 'formation-state',
      label: 'State of Formation',
      children: (
        <EditItem onEdit={() => onEditField({ key: 'target-business-state' })}>
          {
            USA_STATE_OPTIONS.find((state) => state.value === formationState)
              ?.label
          }
        </EditItem>
      )
    },
    {
      key: 'formation-date',
      label: 'Date of Formation',
      children: (
        <EditItem
          onEdit={() => onEditField({ key: 'target-business-start-date' })}
        >
          {businessStartedAt
            ? dayjs(businessStartedAt).format(DEFAULT_DATE_FORMAT.format)
            : ''}
        </EditItem>
      )
    },
    {
      key: 'business-ein',
      label: 'Business EIN',
      children: (
        <EditItem onEdit={() => onEditField({ key: 'target-business-ein' })}>
          {businessEIN?.replace(/\d/g, '*')}
        </EditItem>
      )
    },
    {
      key: 'business-activity-code',
      label: 'NAICS Code',
      children: (
        <EditItem
          onEdit={() => onEditField({ key: 'target-business-activity-code' })}
        >
          {businessActivityCode}
        </EditItem>
      )
    },
    {
      key: 'business-industry',
      label: 'Industry',
      children: (
        <EditItem
          onEdit={() => onEditField({ key: 'target-business-industry' })}
        >
          {INDUSTRY_OPTIONS_MAP[industryType ?? '']}
        </EditItem>
      )
    },
    {
      key: 'phone-number',
      label: 'Business Phone Number',
      children: (
        <EditItem
          onEdit={() => onEditField({ key: 'target-business-phone-number' })}
        >
          {getFormattedNumber(getFormattedPhoneNumber(phoneNumber ?? ''))}
        </EditItem>
      )
    },
    ...optionalItem(!isAddressUnknown, {
      key: 'legal-address',
      label: 'Business Address',
      children: (
        <EditItem
          onEdit={() => onEditField({ key: 'target-business-legal-address' })}
        >
          {getFormattedAddress(legalAddress)}
        </EditItem>
      )
    }),
    ...optionalItem(!isAddressUnknown, {
      key: 'physical-address',
      label: 'Mailing Address',
      children: (
        <EditItem
          onEdit={() =>
            onEditField({ key: 'target-business-physical-address' })
          }
        >
          {getFormattedAddress(physicalAddress)}
        </EditItem>
      )
    })
  ]

  return (
    <Descriptions
      className='review-step__descriptions'
      column={1}
      colon={false}
      items={items}
    />
  )
}

export default TargetBusinessInfoDescription
