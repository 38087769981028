import { FC } from 'react'

import { Col, Row } from 'antd'
import { NamePath } from 'antd/es/form/interface'

import { patchApplication } from '@/api/app.service'
import AnnualProfitFormItem from '@/components/form/form-items/AnnualProfitFormItem'
import AnnualRevenueFormItem from '@/components/form/form-items/AnnualRevenueFormItem'
import BusinessEinFormItem from '@/components/form/form-items/BusinessEinFormItem'
import BusinessEntityFormItem from '@/components/form/form-items/BusinessEntityFormItem'
import BusinessNameFormItem from '@/components/form/form-items/BusinessNameFormItem'
import BusinessStartDateFormItem from '@/components/form/form-items/BusinessStartDateFormItem'
import CityFormItem from '@/components/form/form-items/CityFormItem'
import EmployeesNumberFormItem from '@/components/form/form-items/EmployeesNumberFormItem'
import IndustryFormItem from '@/components/form/form-items/IndustryFormItem'
import SelectStateFormItem from '@/components/form/form-items/SelectStateFormItem'
import ZipCodeFormItem from '@/components/form/form-items/ZipCodeFormItem'
import AddressFormItem from '@/components/form/form-items/address-form-item/AddressFormItem'
import BusinessActivityCode from '@/components/form/form-items/business-activity-code/BusinessActivityCode'
import InputFormItem from '@/components/form/form-items/default-items/input-form-item'
import RadioFormItem from '@/components/form/form-items/default-items/radio-form-item/RadioFormItem'
import PhoneInputFormItem from '@/components/form/form-items/phone-input-form-item/PhoneInputFormItem'
import {
  ADDRESS_FIELD_NAMES_MAP,
  BUSINESS_OVERVIEW_FIELD_NAMES_MAP,
  FORM_FIELD_NAMES_MAP
} from '@/constants/formFieldNames'
import { YES_OR_NO_TYPE_OPTIONS } from '@/constants/formFieldOptions'
import {
  BUSINESS_INFO_TEXT,
  ENTITY_AND_EIN_INFO_TEXT,
  TARGET_BUSINESS_TEXT
} from '@/constants/texts'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import { TGetFieldByKey, TUseFieldEdit } from './types'

interface IEditField {
  title: string
  subtitle: string
  namePath: NamePath
  onSave: (newValue: any, ...rest: any) => Promise<void>
  input: FC
}

const { businessOverview } = FORM_FIELD_NAMES_MAP

const {
  name: businessInfoName,
  legalAddress: businessInfoLegalAddress,
  physicalAddress: businessInfoPhysicalAddress,
  businessActivityCode: businessActivityCodeField,
  employeesCount: employeesCount,
  legalAddressSameAsPhysical: legalAddressSameAsPhysical,
  phoneNumber: businessInfoPhoneNumber,
  businessStartedAt: businessInfoDateStarted,
  formationState,
  businessEntityType,
  businessEIN,
  isFranchise,
  franchiseName,
  annualRevenue,
  annualRevenueYear,
  annualProfit,
  annualProfitYear,
  industryType
} = BUSINESS_OVERVIEW_FIELD_NAMES_MAP

const { city, zipCode, street, usaStateType } = ADDRESS_FIELD_NAMES_MAP

const useBusinessFieldEdit: TUseFieldEdit = (applicationId, form) => {
  const getAddressField = (addressName: string): IEditField => {
    return {
      title: BUSINESS_INFO_TEXT.title,
      subtitle: BUSINESS_INFO_TEXT.subtitle,
      namePath: [
        [businessOverview, addressName, street],
        [businessOverview, addressName, city],
        [businessOverview, addressName, usaStateType],
        [businessOverview, addressName, zipCode]
      ],
      input: () => (
        <Row>
          <Col span={24}>
            <AddressFormItem name={street} />
          </Col>
          <Col span={24}>
            <CityFormItem name={city} />
          </Col>
          <Col span={24}>
            <SelectStateFormItem name={usaStateType} />
          </Col>
          <Col span={24}>
            <ZipCodeFormItem name={zipCode} />
          </Col>
        </Row>
      ),
      onSave: async (newValue) => {
        const legalAddressSameAsPhysicalValue = form.getFieldValue([
          businessOverview,
          legalAddressSameAsPhysical
        ])

        const anotherAddress =
          addressName === businessInfoPhysicalAddress
            ? businessInfoLegalAddress
            : businessInfoPhysicalAddress

        const inputValue = [
          {
            path: `/businessOverview/${addressName}`,
            value: newValue
          }
        ]

        if (legalAddressSameAsPhysicalValue) {
          inputValue.push({
            path: `/businessOverview/${anotherAddress}`,
            value: newValue
          })
        }

        await tryCatchWithMessage(async () => {
          await patchApplication(applicationId, inputValue).then(() => {
            form.setFieldValue([businessOverview, addressName], newValue)

            legalAddressSameAsPhysicalValue &&
              form.setFieldValue([businessOverview, anotherAddress], newValue)
          })
        })
      }
    }
  }

  const getSelectedField: TGetFieldByKey = (selectedKey) => {
    switch (selectedKey) {
      case 'business-info-name':
        return {
          title: BUSINESS_INFO_TEXT.title,
          subtitle: BUSINESS_INFO_TEXT.subtitle,
          namePath: [businessOverview, businessInfoName],
          input: () => <BusinessNameFormItem name={businessInfoName} />,
          onSave: async ({ name: businessInfoNameValue }: { name: string }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: '/businessOverview/name',
                  value: businessInfoNameValue
                }
              ]).then(() =>
                form.setFieldValue(
                  [businessOverview, businessInfoName],
                  businessInfoNameValue
                )
              )
            })
          }
        }
      case 'business-info-legal-address':
        return getAddressField(businessInfoLegalAddress)
      case 'business-info-physical-address':
        return getAddressField(businessInfoPhysicalAddress)
      case 'business-info-phone-number':
        return {
          title: BUSINESS_INFO_TEXT.title,
          subtitle: BUSINESS_INFO_TEXT.subtitle,
          namePath: [businessOverview, businessInfoPhoneNumber],
          input: () => (
            <PhoneInputFormItem
              name={businessInfoPhoneNumber}
              label='Business Phone Number'
            />
          ),
          onSave: async ({ phoneNumber }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: '/businessOverview/phoneNumber',
                  value: phoneNumber
                }
              ]).then(() =>
                form.setFieldValue(
                  [businessOverview, businessInfoPhoneNumber],
                  phoneNumber
                )
              )
            })
          }
        }
      case 'business-info-start-date':
        return {
          title: TARGET_BUSINESS_TEXT.title,
          subtitle: TARGET_BUSINESS_TEXT.subtitle,
          namePath: [businessOverview, businessInfoDateStarted],
          onSave: async ({
            businessStartedAt
          }: {
            businessStartedAt: string
          }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: '/businessOverview/businessStartedAt',
                  value: businessStartedAt
                }
              ]).then(() =>
                form.setFieldValue(
                  [businessOverview, businessInfoDateStarted],
                  businessStartedAt
                )
              )
            })
          },
          input: () => (
            <BusinessStartDateFormItem name={businessInfoDateStarted} />
          )
        }
      case 'business-info-formation-state':
        return {
          title: ENTITY_AND_EIN_INFO_TEXT.title,
          subtitle: ENTITY_AND_EIN_INFO_TEXT.subtitle,
          namePath: [businessOverview, formationState],
          onSave: async ({
            formationState: formationStateValue
          }: {
            formationState: string
          }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: '/businessOverview/formationState',
                  value: formationStateValue
                }
              ]).then(() =>
                form.setFieldValue(
                  [businessOverview, formationState],
                  formationStateValue
                )
              )
            })
          },
          input: () => (
            <SelectStateFormItem
              name={formationState}
              label='State of Formation'
            />
          )
        }
      case 'business-info-entity-type':
        return {
          title: ENTITY_AND_EIN_INFO_TEXT.title,
          subtitle: ENTITY_AND_EIN_INFO_TEXT.subtitle,
          namePath: [businessOverview, businessEntityType],
          onSave: async ({
            businessEntityType: businessEntityTypeValue
          }: {
            businessEntityType: string
          }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: '/businessOverview/businessEntityType',
                  value: businessEntityTypeValue
                }
              ]).then(() =>
                form.setFieldValue(
                  [businessOverview, businessEntityType],
                  businessEntityTypeValue
                )
              )
            })
          },
          input: () => <BusinessEntityFormItem name={businessEntityType} />
        }
      case 'business-info-ein':
        return {
          title: ENTITY_AND_EIN_INFO_TEXT.title,
          subtitle: ENTITY_AND_EIN_INFO_TEXT.subtitle,
          namePath: [businessOverview, businessEIN],
          onSave: async ({
            businessEIN: businessEINValue
          }: {
            businessEIN: string
          }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: '/businessOverview/businessEIN',
                  value: businessEINValue.replace(/-/g, '')
                }
              ]).then(() =>
                form.setFieldValue(
                  [businessOverview, businessEIN],
                  businessEINValue
                )
              )
            })
          },
          input: () => <BusinessEinFormItem name={businessEIN} />
        }
      case 'business-info-employees-count':
        return {
          title: BUSINESS_INFO_TEXT.title,
          subtitle: BUSINESS_INFO_TEXT.subtitle,
          namePath: [businessOverview, employeesCount],
          onSave: async ({
            employeesCount: employeesCountValue
          }: {
            employeesCount: string
          }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: '/businessOverview/employeesCount',
                  value: employeesCountValue
                }
              ]).then(() =>
                form.setFieldValue(
                  [businessOverview, employeesCount],
                  employeesCountValue
                )
              )
            })
          },
          input: () => <EmployeesNumberFormItem name={employeesCount} />
        }
      case 'business-info-activity=code':
        return {
          title: BUSINESS_INFO_TEXT.title,
          subtitle: BUSINESS_INFO_TEXT.subtitle,
          namePath: [businessOverview, businessActivityCodeField],
          onSave: async ({
            businessActivityCode: businessActivityCodeValue
          }: {
            businessActivityCode: string
          }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: '/businessOverview/businessActivityCode',
                  value: businessActivityCodeValue
                }
              ]).then(() =>
                form.setFieldValue(
                  [businessOverview, businessActivityCodeField],
                  businessActivityCodeValue
                )
              )
            })
          },
          input: () => <BusinessActivityCode name={businessActivityCodeField} />
        }
      case 'business-info-is-franchise':
        return {
          title: ENTITY_AND_EIN_INFO_TEXT.title,
          subtitle: ENTITY_AND_EIN_INFO_TEXT.subtitle,
          namePath: [businessOverview, isFranchise],
          onSave: async ({
            isFranchise: isFranchiseValue
          }: {
            isFranchise: boolean
          }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: '/businessOverview/isFranchise',
                  value: isFranchiseValue
                }
              ]).then(() =>
                form.setFieldValue(
                  [businessOverview, isFranchise],
                  isFranchiseValue
                )
              )
            })
          },
          input: () => (
            <RadioFormItem
              label='Is it a franchise?'
              name={isFranchise}
              options={YES_OR_NO_TYPE_OPTIONS}
            />
          )
        }
      case 'business-info-franchise-name':
        return {
          title: ENTITY_AND_EIN_INFO_TEXT.title,
          subtitle: ENTITY_AND_EIN_INFO_TEXT.subtitle,
          namePath: [businessOverview, franchiseName],
          onSave: async ({
            franchiseName: franchiseNameValue
          }: {
            franchiseName: string
          }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: '/businessOverview/franchiseName',
                  value: franchiseNameValue
                }
              ]).then(() =>
                form.setFieldValue(
                  [businessOverview, franchiseName],
                  franchiseNameValue
                )
              )
            })
          },
          input: () => (
            <InputFormItem
              name={franchiseName}
              label='Name of Franchise'
              placeholder='Enter name'
            />
          )
        }
      case 'business-info-annual-revenue':
        return {
          title: BUSINESS_INFO_TEXT.title,
          subtitle: BUSINESS_INFO_TEXT.subtitle,
          namePath: [
            [businessOverview, annualRevenue],
            [businessOverview, annualRevenueYear]
          ],
          onSave: async ({
            annualRevenue: annualRevenueValue,
            annualRevenueYear: annualRevenueYearValue
          }: {
            annualRevenue: number
            annualRevenueYear: number
          }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: '/businessOverview/annualRevenue',
                  value: annualRevenueValue
                },
                {
                  path: '/businessOverview/annualRevenueYear',
                  value: annualRevenueYearValue
                }
              ]).then(() => {
                form.setFieldValue(
                  [businessOverview, annualRevenue],
                  annualRevenueValue
                )
                form.setFieldValue(
                  [businessOverview, annualRevenueYear],
                  annualRevenueYearValue
                )
              })
            })
          },
          input: () => (
            <AnnualRevenueFormItem
              revenueName={annualRevenue}
              revenueYearName={annualRevenueYear}
            />
          )
        }
      case 'business-info-annual-profit':
        return {
          title: BUSINESS_INFO_TEXT.title,
          subtitle: BUSINESS_INFO_TEXT.subtitle,
          namePath: [
            [businessOverview, annualProfit],
            [businessOverview, annualProfitYear]
          ],
          onSave: async ({
            annualProfit: annualProfitValue,
            annualProfitYear: annualProfitYearValue
          }: {
            annualProfit: number
            annualProfitYear: number
          }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: '/businessOverview/annualProfit',
                  value: annualProfitValue
                },
                {
                  path: '/businessOverview/annualProfitYear',
                  value: annualProfitYearValue
                }
              ]).then(() => {
                form.setFieldValue(
                  [businessOverview, annualProfit],
                  annualProfitValue
                )
                form.setFieldValue(
                  [businessOverview, annualRevenueYear],
                  annualProfitYearValue
                )
              })
            })
          },
          input: () => (
            <AnnualProfitFormItem
              profitName={annualProfit}
              profitYearName={annualProfitYear}
            />
          )
        }
      case 'business-info-industry':
        return {
          title: ENTITY_AND_EIN_INFO_TEXT.title,
          subtitle: ENTITY_AND_EIN_INFO_TEXT.subtitle,
          namePath: [businessOverview, industryType],
          onSave: async ({ industryType: industryTypeValue }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: '/businessOverview/industryType',
                  value: industryTypeValue
                }
              ]).then(() =>
                form.setFieldValue(
                  [businessOverview, industryType],
                  industryTypeValue
                )
              )
            })
          },
          input: () => <IndustryFormItem name={industryType} />
        }
      default:
        return null
    }
  }

  return getSelectedField
}

export default useBusinessFieldEdit
