import { FC } from 'react'

import { Form, InputNumber, InputNumberProps } from 'antd'
import { Rule } from 'antd/es/form'
import { NamePath } from 'antd/es/form/interface'

import './InputFormItem.scss'

interface INumberInputFormItemProps extends InputNumberProps<number> {
  name: NamePath
  label?: string
  rules?: Rule[]
}

const NumberInputFormItem: FC<INumberInputFormItemProps> = ({
  name,
  label,
  rules,
  ...rest
}) => {
  return (
    <Form.Item
      name={name}
      className={'input'}
      label={label}
      rules={rules ?? [{ required: true }]}
    >
      <InputNumber inputMode='numeric' style={{ width: '100%' }} {...rest} />
    </Form.Item>
  )
}

export default NumberInputFormItem
