import { FC } from 'react'

import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import { EFundingPurposeType } from '@/types/formFieldTypes'

import { IDescriptionProps } from '../types'

import BusinessInformationDescriptions from './BusinessInformationDescriptions'
import BuyBusinessInfoDescription from './BuyBusinessInfoDescription'
import TargetBusinessInfoDescription from './TargetBusinessInfoDescription'

const { fundingPurposeType } = FORM_FIELD_NAMES_MAP

const BusinessInfoFactory: FC<IDescriptionProps> = ({ form, onEditField }) => {
  const fundingPurpose = form.getFieldValue(fundingPurposeType)

  const factory = () => {
    switch (fundingPurpose) {
      case EFundingPurposeType.StartBusiness:
        return (
          <TargetBusinessInfoDescription
            form={form}
            onEditField={onEditField}
          />
        )
      case EFundingPurposeType.BuyBusiness:
        return (
          <BuyBusinessInfoDescription form={form} onEditField={onEditField} />
        )
      default:
        return (
          <BusinessInformationDescriptions
            form={form}
            onEditField={onEditField}
          />
        )
    }
  }

  return <>{factory()}</>
}

export default BusinessInfoFactory
