import { FC } from 'react'

import { Checkbox, Form } from 'antd'

import './TermsAndConditions.scss'

const TermsAndConditions: FC = () => {
  return (
    <div className='terms'>
      <div className='terms__title'>Terms and Conditions</div>
      <div className='terms__content'>
        Credit Authorization: By submitting this Application Form; Business
        Applicant and each Individual (each Signer and collectively Signers
        represent, acknowledge and agree as follows: (i) Signer is authorized on
        behalf of the business and all Owners, whose name(s) you have entered
        above to initiate this pre-qualification process for credit, and (ii)
        all information you have provided is true, correct and complete and that
        you will notify us of changes to such information. Signer understands
        and agrees that LoanBud, Inc. and its Affiliates are authorized to
        contact third parties to conduct background checks and other
        investigative reports and make credit report inquiries (including
        requesting business and personal credit bureau reports from credit
        reporting agencies and other sources). By signing below, you are further
        providing written instructions to LoanBud, Inc. and its Affiliates under
        the Fair Credit Reporting Act authorizing LoanBud, Inc. and its
        Affiliates to obtain information from your personal and business credit
        profile, as well as the personal credit profiles for Signer and all
        listed Owners, to conduct a qualification for credit in connection with
        your business. E-Sign Consent: You hereby consent that your electronic
        signature on agreements and documents has the same legal and moral
        effect as if you signed such agreements and documents in ink and will be
        deemed valid, authentic, enforceable and binding. Pursuant to law and
        regulations, you agree and acknowledge that all electronic
        communications delivered to you by LoanBud, Inc. and its Affiliates (a)
        shall be given the same legal effect as signed paper communications, (b)
        shall be considered a writing or in writing and (c) shall be deemed for
        all purposes to have been signed and to constitute an original when
        printed from electronic files or records established and maintained in
        the normal course of business.
      </div>
      <Form.Item
        valuePropName='checked'
        name={'termsChecked'}
        className={'terms__checkbox'}
        rules={[{ required: true }]}
      >
        <Checkbox>
          By checking this box I agree to the terms and conditions.
        </Checkbox>
      </Form.Item>
    </div>
  )
}

export default TermsAndConditions
