import { FC } from 'react'

import { Col, Row } from 'antd'
import { NamePath } from 'antd/es/form/interface'
import dayjs from 'dayjs'

import { NumberInputFormItem } from './default-items/input-form-item'

interface IAnnualProfitFormItemProps {
  profitName: NamePath
  profitYearName: NamePath
}

const AnnualProfitFormItem: FC<IAnnualProfitFormItemProps> = ({
  profitName,
  profitYearName
}) => {
  const formatter = (value?: number) => {
    return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
  }

  return (
    <Row gutter={8}>
      <Col span={12}>
        <NumberInputFormItem
          label='Annual Profit'
          placeholder='Enter Annual Profit'
          suffix={<>USD</>}
          min={0}
          maxLength={10}
          name={profitName}
          controls={false}
          formatter={formatter}
          parser={(value) => Number(value?.replace(/,/g, ''))}
          rules={[
            {
              required: true,
              message: 'Required to help us find the best options'
            }
          ]}
        />
      </Col>
      <Col span={12}>
        <NumberInputFormItem
          label='Year'
          placeholder='Enter Year'
          max={Number(dayjs().format('YYYY'))}
          controls={false}
          name={profitYearName}
          rules={[
            {
              required: true,
              message: 'Required to complete'
            }
          ]}
        />
      </Col>
    </Row>
  )
}

export default AnnualProfitFormItem
