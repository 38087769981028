import { Button, Col, Form, FormInstance, Row } from 'antd'
import dayjs from 'dayjs'

import { patchApplication } from '@/api/app.service'
import BirthDateFormItem from '@/components/form/form-items/BirthDateFormItem'
import CitizenshipFormItem from '@/components/form/form-items/CitizenshipFormItem'
import CityFormItem from '@/components/form/form-items/CityFormItem'
import FirstNameFormItem from '@/components/form/form-items/FirstNameFormItem'
import MoneyAmountFormItem from '@/components/form/form-items/MoneyAmountFormItem'
import OptionalFormItem from '@/components/form/form-items/OptionalFormItem'
import SelectStateFormItem from '@/components/form/form-items/SelectStateFormItem'
import ZipCodeFormItem from '@/components/form/form-items/ZipCodeFormItem'
import AddressFormItem from '@/components/form/form-items/address-form-item/AddressFormItem'
import DatePickerFormItem from '@/components/form/form-items/default-items/date-picker-form-item/DatePickerFormItem'
import { NumberInputFormItem } from '@/components/form/form-items/default-items/input-form-item'
import RadioFormItem from '@/components/form/form-items/default-items/radio-form-item/RadioFormItem'
import TextAreaFormInput from '@/components/form/form-items/default-items/textarea-form-input/TextAreaFormInput'
import EmailInputFormItem from '@/components/form/form-items/email-input-form-item/EmailInputFormItem'
import PhoneInputFormItem from '@/components/form/form-items/phone-input-form-item/PhoneInputFormItem'
import Close from '@/components/icons/Close'
import PlusIcon from '@/components/icons/PlusIcon'
import SSNFormItem from '@/components/steps/business-owner-info/SSNFormItem'
import {
  ADDRESS_FIELD_NAMES_MAP,
  ANNUAL_AMOUNT_FIELD_NAMES_MAP,
  FORM_FIELD_NAMES_MAP,
  OWNER_INFO_FIELD_NAMES_MAP
} from '@/constants/formFieldNames'
import {
  MARTIAL_STATUS_OPTIONS,
  YES_OR_NO_TYPE_OPTIONS
} from '@/constants/formFieldOptions'
import { OWNER_INFO_TEXT } from '@/constants/texts'
import getFormattedPhoneNumber from '@/utils/getFormattedPhoneNumber'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import { TGetFieldByKey, TUseFieldEdit } from './types'

const { owners } = FORM_FIELD_NAMES_MAP

const {
  firstName,
  lastName,
  email,
  phoneNumber,
  birthDate,
  address,
  socialSecurityNumber,
  hasFiledBankruptcy,
  bankruptcyFillingDate,
  hasFelonyConviction,
  felonyConvictionDate,
  maritalStatusType,
  citizenshipStatusType,
  citizenshipStatusExplanation,
  annualDebts,
  hasTaxDebt,
  hasPendingLawsuits,
  pendingLawsuitsExplanation,
  hasUnsatisfiedJudgments,
  unsatisfiedJudgmentsExplanation,
  hasOtherBusinesses,
  hasCollateralRealEstate,
  realEstatePrice
} = OWNER_INFO_FIELD_NAMES_MAP

const { amount, year } = ANNUAL_AMOUNT_FIELD_NAMES_MAP

const { city, zipCode, street, usaStateType } = ADDRESS_FIELD_NAMES_MAP

const useOwnerInfoFieldEdit: TUseFieldEdit = (applicationId, form) => {
  const getFieldByKey: TGetFieldByKey = (selectedFieldKey) => {
    switch (selectedFieldKey) {
      case 'owner-info-first-name':
        return {
          title: OWNER_INFO_TEXT.title,
          subtitle: OWNER_INFO_TEXT.subtitle,
          input: () => <FirstNameFormItem name={firstName} />,
          namePath: firstName,
          onSave: async (
            { firstName: firstNameValue }: { firstName: string },
            { index }: { index: number }
          ) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: `/owners/${index}/firstName`,
                  value: firstNameValue
                }
              ]).then(() =>
                form.setFieldValue([owners, index, firstName], firstNameValue)
              )
            })
          }
        }
      case 'owner-info-last-name':
        return {
          title: OWNER_INFO_TEXT.title,
          subtitle: OWNER_INFO_TEXT.subtitle,
          input: () => <FirstNameFormItem name={lastName} />,
          namePath: lastName,
          onSave: async (
            { lastName: lastNameValue }: { lastName: string },
            { index }: { index: number }
          ) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: `/owners/${index}/lastName`,
                  value: lastNameValue
                }
              ]).then(() =>
                form.setFieldValue([owners, index, lastName], lastNameValue)
              )
            })
          }
        }
      case 'owner-info-email':
        return {
          title: OWNER_INFO_TEXT.title,
          subtitle: OWNER_INFO_TEXT.subtitle,
          input: () => <EmailInputFormItem name={email} />,
          namePath: email,
          onSave: async (
            { email: emailValue }: { email: string },
            { index }
          ) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: `/owners/${index}/email`,
                  value: emailValue
                }
              ]).then(() =>
                form.setFieldValue([owners, index, email], emailValue)
              )
            })
          }
        }
      case 'owner-info-phone':
        return {
          title: OWNER_INFO_TEXT.title,
          subtitle: OWNER_INFO_TEXT.subtitle,
          input: () => <PhoneInputFormItem name={phoneNumber} />,
          namePath: phoneNumber,
          onSave: async (
            { phoneNumber: phoneNumberValue }: { phoneNumber: string },
            { index }: { index: number }
          ) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: `/owners/${index}/phoneNumber`,
                  value: getFormattedPhoneNumber(phoneNumberValue)
                }
              ]).then(() =>
                form.setFieldValue(
                  [owners, index, phoneNumber],
                  phoneNumberValue
                )
              )
            })
          }
        }
      case 'owner-info-birth-date':
        return {
          title: OWNER_INFO_TEXT.title,
          subtitle: OWNER_INFO_TEXT.subtitle,
          input: () => <BirthDateFormItem name={birthDate} />,
          namePath: birthDate,
          onSave: async (
            { birthDate: birthDateValue }: { birthDate: string },
            { index }: { index: number }
          ) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: `/owners/${index}/birthDate`,
                  value: birthDateValue
                }
              ]).then(() =>
                form.setFieldValue([owners, index, birthDate], birthDateValue)
              )
            })
          }
        }
      case 'owner-info-address':
        return {
          title: OWNER_INFO_TEXT.title,
          subtitle: OWNER_INFO_TEXT.subtitle,
          namePath: [
            [address, street],
            [address, city],
            [address, usaStateType],
            [address, zipCode]
          ],
          onSave: async (newValue, { index }: { index: number }) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: `/owners/${index}/address`,
                  value: newValue
                }
              ]).then(() =>
                form.setFieldValue([owners, index, address], newValue)
              )
            })
          },
          input: () => (
            <Row>
              <Col span={24}>
                <AddressFormItem name={street} />
              </Col>
              <Col span={24}>
                <CityFormItem name={city} />
              </Col>
              <Col span={24}>
                <SelectStateFormItem name={usaStateType} />
              </Col>
              <Col span={24}>
                <ZipCodeFormItem name={zipCode} />
              </Col>
            </Row>
          )
        }
      case 'owner-info-ssn':
        return {
          title: OWNER_INFO_TEXT.title,
          subtitle: OWNER_INFO_TEXT.subtitle,
          namePath: socialSecurityNumber,
          onSave: async (
            {
              socialSecurityNumber: socialSecurityNumberValue
            }: { socialSecurityNumber: string },
            { index }: { index: number }
          ) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: `/owners/${index}/socialSecurityNumber`,
                  value: socialSecurityNumberValue.replace(/-/g, '')
                }
              ]).then(() =>
                form.setFieldValue(
                  [owners, index, socialSecurityNumber],
                  socialSecurityNumberValue
                )
              )
            })
          },
          input: () => (
            <SSNFormItem isPrimaryOwner={true} name={socialSecurityNumber} />
          )
        }
      case 'owner-info-filled-bankruptcy':
        return {
          title: OWNER_INFO_TEXT.title,
          subtitle: OWNER_INFO_TEXT.subtitle,
          namePath: [[hasFiledBankruptcy], [bankruptcyFillingDate]],
          onSave: async (
            {
              hasFiledBankruptcy: hasFiledBankruptcyValue,
              bankruptcyFillingDate: bankruptcyFillingDateValue
            },
            { index }: { index: number }
          ) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: `/owners/${index}/hasFiledBankruptcy`,
                  value: hasFiledBankruptcyValue
                },

                {
                  path: `/owners/${index}/bankruptcyFillingDate`,
                  value: hasFiledBankruptcyValue
                    ? bankruptcyFillingDateValue
                    : null
                }
              ]).then(() => {
                form.setFieldValue(
                  [owners, index, hasFiledBankruptcy],
                  hasFiledBankruptcyValue
                )
                form.setFieldValue(
                  [owners, index, bankruptcyFillingDate],
                  hasFiledBankruptcyValue ? bankruptcyFillingDateValue : null
                )
              })
            })
          },
          input: () => (
            <OptionalFormItem
              radioName={hasFiledBankruptcy}
              radioLabel='Have you ever filed bankruptcy?'
            >
              <DatePickerFormItem name={bankruptcyFillingDate} label='Date' />
            </OptionalFormItem>
          )
        }
      case 'owner-info-convicted-felony':
        return {
          title: OWNER_INFO_TEXT.title,
          subtitle: OWNER_INFO_TEXT.subtitle,
          namePath: [[hasFelonyConviction], [felonyConvictionDate]],
          onSave: async (
            {
              hasFelonyConviction: hasFelonyConvictionValue,
              felonyConvictionDate: felonyConvictionDateValue
            },
            { index }: { index: number }
          ) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: `/owners/${index}/hasFelonyConviction`,
                  value: hasFelonyConvictionValue
                },
                {
                  path: `/owners/${index}/felonyConvictionDate`,
                  value: hasFelonyConvictionValue
                    ? felonyConvictionDateValue
                    : null
                }
              ]).then(() => {
                form.setFieldValue(
                  [owners, index, hasFelonyConviction],
                  hasFelonyConvictionValue
                )
                form.setFieldValue(
                  [owners, index, felonyConvictionDate],
                  hasFelonyConvictionValue ? felonyConvictionDateValue : null
                )
              })
            })
          },
          input: () => (
            <OptionalFormItem
              radioName={hasFelonyConviction}
              radioLabel='Have you ever filed felony?'
            >
              <DatePickerFormItem name={felonyConvictionDate} label='Date' />
            </OptionalFormItem>
          )
        }
      case 'owner-info-martial-status':
        return {
          title: OWNER_INFO_TEXT.title,
          subtitle: OWNER_INFO_TEXT.subtitle,
          namePath: maritalStatusType,
          onSave: async (
            {
              maritalStatusType: maritalStatusTypeValue
            }: { maritalStatusType: string },
            { index }: { index: number }
          ) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: `/owners/${index}/maritalStatusType`,
                  value: maritalStatusTypeValue
                }
              ]).then(() =>
                form.setFieldValue(
                  [owners, index, maritalStatusType],
                  maritalStatusTypeValue
                )
              )
            })
          },
          input: () => (
            <RadioFormItem
              name={maritalStatusType}
              options={MARTIAL_STATUS_OPTIONS}
              label='Current marital status'
            />
          )
        }
      case 'owner-info-citizenship-status':
        return {
          title: OWNER_INFO_TEXT.title,
          subtitle: OWNER_INFO_TEXT.subtitle,
          namePath: [[citizenshipStatusType], [citizenshipStatusExplanation]],
          onSave: async (
            {
              citizenshipStatusType: citizenshipStatusTypeValue,
              citizenshipStatusExplanation: citizenshipStatusExplanationValue
            },
            { index }
          ) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: `/owners/${index}/citizenshipStatusType`,
                  value: citizenshipStatusTypeValue
                },
                {
                  path: `/owners/${index}/citizenshipStatusExplanation`,
                  value: citizenshipStatusExplanationValue
                }
              ]).then(() => {
                form.setFieldValue(
                  [owners, index, citizenshipStatusType],
                  citizenshipStatusTypeValue
                )
                form.setFieldValue(
                  [owners, index, citizenshipStatusExplanation],
                  citizenshipStatusExplanationValue
                )
              })
            })
          },
          input: () => (
            <CitizenshipFormItem
              statusExplanationName={citizenshipStatusExplanation}
              statusName={citizenshipStatusType}
              required
            />
          )
        }
      case 'owner-info-tax-debt':
        return {
          title: OWNER_INFO_TEXT.title,
          subtitle: OWNER_INFO_TEXT.subtitle,
          namePath: [[annualDebts], [hasTaxDebt]],
          onSave: async (
            { hasTaxDebt: hasTaxDebtValue, annualDebts: annualDebtsValue },
            { index }
          ) => {
            await tryCatchWithMessage(async () => {
              const inputValue = [
                {
                  path: `/owners/${index}/hasTaxDebt`,
                  value: hasTaxDebtValue
                },
                {
                  path: `/owners/${index}/annualDebts`,
                  value: hasTaxDebtValue ? annualDebtsValue : []
                }
              ]
              await patchApplication(applicationId, inputValue).then(() => {
                form.setFieldValue([owners, index, hasTaxDebt], hasTaxDebtValue)
                form.setFieldValue(
                  [owners, index, annualDebts],
                  hasTaxDebtValue
                    ? annualDebtsValue
                    : [{ amount: undefined, year: undefined }]
                )
              })
            })
          },
          input: ({ mainForm }: { mainForm: FormInstance }) => (
            <OptionalFormItem
              radioName={hasTaxDebt}
              radioLabel='Do you owe any money to the IRS, for business taxes or personal taxes, including if you are on a payment plan?'
            >
              <Form.List
                initialValue={[{ amount: undefined, year: undefined }]}
                name={annualDebts}
              >
                {(fields, { add, remove }) => {
                  const handleRemove = (key: number) => () => {
                    if (fields.length === 1) {
                      mainForm.setFieldValue([hasTaxDebt], false)
                      mainForm.setFieldValue(
                        [annualDebts],
                        [{ amount: undefined, year: undefined }]
                      )
                    }
                    remove(key)
                  }

                  return (
                    <>
                      {fields.map(({ key, name }) => (
                        <div key={key} className='annual-debt'>
                          <MoneyAmountFormItem
                            name={[name, amount]}
                            label='Amount'
                            placeholder='Enter Amount'
                          />
                          <NumberInputFormItem
                            label='Year'
                            placeholder='Enter Year'
                            max={Number(dayjs().format('YYYY'))}
                            controls={false}
                            name={[name, year]}
                          />
                          <Close
                            className='annual-debt__close-icon'
                            onClick={handleRemove(key)}
                          />
                        </div>
                      ))}
                      <Button
                        type='dashed'
                        color='primary'
                        size='small'
                        className='legal-info__add-btn'
                        icon={<PlusIcon />}
                        onClick={() =>
                          add({ amount: undefined, year: undefined })
                        }
                      >
                        Add Another Year
                      </Button>
                    </>
                  )
                }}
              </Form.List>
            </OptionalFormItem>
          )
        }
      case 'owner-info-has-pending-lawsuit':
        return {
          title: OWNER_INFO_TEXT.title,
          subtitle: OWNER_INFO_TEXT.subtitle,
          namePath: [[hasPendingLawsuits], [pendingLawsuitsExplanation]],
          onSave: async (
            {
              hasPendingLawsuits: hasPendingLawsuitsValue,
              pendingLawsuitsExplanation: pendingLawsuitsExplanationValue
            },
            { index }
          ) => {
            await tryCatchWithMessage(async () => {
              const inputValue = [
                {
                  path: `/owners/${index}/hasPendingLawsuits`,
                  value: hasPendingLawsuitsValue
                },
                {
                  path: `/owners/${index}/pendingLawsuitsExplanation`,
                  value: hasPendingLawsuitsValue
                    ? pendingLawsuitsExplanationValue
                    : null
                }
              ]

              await patchApplication(applicationId, inputValue).then(() => {
                form.setFieldValue(
                  [owners, index, hasPendingLawsuits],
                  hasPendingLawsuitsValue
                )
                form.setFieldValue(
                  [owners, index, pendingLawsuitsExplanation],
                  hasPendingLawsuitsValue
                    ? pendingLawsuitsExplanationValue
                    : undefined
                )
              })
            })
          },
          input: () => (
            <OptionalFormItem
              radioName={hasPendingLawsuits}
              radioLabel='Any pending lawsuits or legal proceedings?'
            >
              <TextAreaFormInput
                name={pendingLawsuitsExplanation}
                label='Please explain'
              />
            </OptionalFormItem>
          )
        }
      case 'owner-info-has-judgement':
        return {
          title: OWNER_INFO_TEXT.title,
          subtitle: OWNER_INFO_TEXT.subtitle,
          namePath: [
            [hasUnsatisfiedJudgments],
            [unsatisfiedJudgmentsExplanation]
          ],
          onSave: async (
            {
              hasUnsatisfiedJudgments: hasUnsatisfiedJudgmentsValue,
              unsatisfiedJudgmentsExplanation:
                unsatisfiedJudgmentsExplanationValue
            },
            { index }
          ) => {
            await tryCatchWithMessage(async () => {
              const inputValue = [
                {
                  path: `/owners/${index}/hasUnsatisfiedJudgments`,
                  value: hasUnsatisfiedJudgmentsValue
                },
                {
                  path: `/owners/${index}/unsatisfiedJudgmentsExplanation`,
                  value: hasUnsatisfiedJudgmentsValue
                    ? unsatisfiedJudgmentsExplanationValue
                    : null
                }
              ]

              await patchApplication(applicationId, inputValue).then(() => {
                form.setFieldValue(
                  [owners, index, hasUnsatisfiedJudgments],
                  hasUnsatisfiedJudgmentsValue
                )
                form.setFieldValue(
                  [owners, index, unsatisfiedJudgmentsExplanation],
                  hasUnsatisfiedJudgmentsValue
                    ? unsatisfiedJudgmentsExplanationValue
                    : undefined
                )
              })
            })
          },
          input: () => (
            <OptionalFormItem
              radioName={hasUnsatisfiedJudgments}
              radioLabel='Any unsatisfied judgments or court orders?'
            >
              <TextAreaFormInput
                name={unsatisfiedJudgmentsExplanation}
                label='Please explain'
              />
            </OptionalFormItem>
          )
        }
      case 'owner-info-has-other-business':
        return {
          title: OWNER_INFO_TEXT.title,
          subtitle: OWNER_INFO_TEXT.subtitle,
          namePath: hasOtherBusinesses,
          onSave: async (
            { hasOtherBusinesses: hasOtherBusinessesValue },
            { index }
          ) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: `/owners/${index}/hasOtherBusinesses`,
                  value: hasOtherBusinessesValue
                }
              ]).then(() =>
                form.setFieldValue(
                  [owners, index, hasOtherBusinesses],
                  hasOtherBusinessesValue
                )
              )
            })
          },
          input: () => (
            <RadioFormItem
              name={hasOtherBusinesses}
              options={YES_OR_NO_TYPE_OPTIONS}
              label='Do you have a 20% or greater ownership stake in any other businesses?'
            />
          )
        }
      case 'owner-info-has-collateral-real-estate':
        return {
          title: OWNER_INFO_TEXT.title,
          subtitle: OWNER_INFO_TEXT.subtitle,
          namePath: hasCollateralRealEstate,
          onSave: async (
            { hasCollateralRealEstate: hasCollateralRealEstateValue },
            { index }
          ) => {
            await tryCatchWithMessage(async () => {
              const inputValue = [
                {
                  path: `/owners/${index}/hasCollateralRealEstate`,
                  value: hasCollateralRealEstateValue
                }
              ]

              !hasCollateralRealEstateValue &&
                inputValue.push({
                  path: `/owners/${index}/realEstatePrice`,
                  value: null
                })

              await patchApplication(applicationId, inputValue).then(() => {
                form.setFieldValue(
                  [owners, index, hasCollateralRealEstate],
                  hasCollateralRealEstateValue
                )
                !hasCollateralRealEstateValue &&
                  form.setFieldValue(
                    [owners, index, realEstatePrice],
                    undefined
                  )
              })
            })
          },
          input: () => (
            <RadioFormItem
              name={hasCollateralRealEstate}
              options={YES_OR_NO_TYPE_OPTIONS}
              label='Do you own any real estate that can be pledged as collateral?'
            />
          )
        }
      case 'owner-info-real-estate-price':
        return {
          title: OWNER_INFO_TEXT.title,
          subtitle: OWNER_INFO_TEXT.subtitle,
          namePath: realEstatePrice,
          onSave: async (
            { realEstatePrice: realEstatePriceValue },
            { index }
          ) => {
            await tryCatchWithMessage(async () => {
              await patchApplication(applicationId, [
                {
                  path: `/owners/${index}/realEstatePrice`,
                  value: realEstatePriceValue
                }
              ]).then(() =>
                form.setFieldValue(
                  [owners, index, realEstatePrice],
                  realEstatePriceValue
                )
              )
            })
          },
          input: () => (
            <MoneyAmountFormItem
              label='How much is real estate worth?'
              name={realEstatePrice}
            />
          )
        }
      default:
        return null
    }
  }

  return getFieldByKey
}

export default useOwnerInfoFieldEdit
