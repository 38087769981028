import { useMemo } from 'react'

import { Form } from 'antd'

import { useTypedSelector } from '../redux'

import { IEditField, TEditFieldKey } from './types'
import useBasicInfoFieldEdit from './useBasicInfoFieldEdit'
import useBusinessFieldEdit from './useBusinessFieldEdit'
import useOwnerInfoFieldEdit from './useOwnerInfoFieldEdit'
import useTargetBusinessFieldEdit from './useTargetBusinessFieldEdit'
import useBuyBusinessFieldEdit from './useBuyBusinessFieldEdit'

const DEFAULT_STATE: IEditField = {
  title: '',
  subtitle: '',
  namePath: null,
  onSave: async () => {},
  input: () => <></>
}

const useFieldEdit = (selectedFieldKey: TEditFieldKey | null): IEditField => {
  const form = Form.useFormInstance()

  const applicationId = useTypedSelector(
    ({ formState }) => formState.applicationId
  )

  const getBasicFieldByKey = useBasicInfoFieldEdit(applicationId, form)
  const getBuyBusinessFieldByKey = useBuyBusinessFieldEdit(applicationId, form)
  const getTargetBusinessFieldByKey = useTargetBusinessFieldEdit(
    applicationId,
    form
  )
  const getBusinessFieldByKey = useBusinessFieldEdit(applicationId, form)
  const getOwnerInfoFieldByKey = useOwnerInfoFieldEdit(applicationId, form)

  const getSelectedField = (selectedFieldKey: TEditFieldKey) => {
    const getSelectedFieldFuncs = [
      getBasicFieldByKey,
      getBuyBusinessFieldByKey,
      getTargetBusinessFieldByKey,
      getBusinessFieldByKey,
      getOwnerInfoFieldByKey
    ]

    for (const func of getSelectedFieldFuncs) {
      const res = func(selectedFieldKey)
      if (!!res) {
        return res
      }
    }
    return null
  }

  const selectedField: IEditField = useMemo(() => {
    if (!selectedFieldKey) {
      return DEFAULT_STATE
    }

    const selectedField = getSelectedField(selectedFieldKey)

    return selectedField ?? DEFAULT_STATE
  }, [selectedFieldKey])

  return selectedField
}

export default useFieldEdit
