import { FC } from 'react'

const Flag: FC = () => {
  return (
    <svg
      width='89'
      height='89'
      viewBox='0 0 89 89'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        opacity='0.18'
        cx='44.5'
        cy='44.5'
        r='44'
        fill='var(--brand-primary)'
      />
      <rect
        x='14.5'
        y='14.5'
        width='60'
        height='60'
        rx='30'
        fill='var(--brand-primary)'
      />
      <path
        d='M35.5 47.375C35.5 47.375 36.625 46.25 40 46.25C43.375 46.25 45.625 48.5 49 48.5C52.375 48.5 53.5 47.375 53.5 47.375V33.875C53.5 33.875 52.375 35 49 35C45.625 35 43.375 32.75 40 32.75C36.625 32.75 35.5 33.875 35.5 33.875V47.375ZM35.5 47.375V55.25'
        stroke='#FCFCFC'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default Flag
