export function base64ToBlob(base64: string, contentType = 'image/png') {
  const base64WithoutPrefix = base64.includes(',')
    ? base64.split(',')[1]
    : base64

  const byteCharacters = atob(base64WithoutPrefix)
  const byteArrays = []

  for (let i = 0; i < byteCharacters.length; i += 512) {
    const slice = byteCharacters.slice(i, i + 512)
    const byteNumbers = new Array(slice.length)
    for (let j = 0; j < slice.length; j++) {
      byteNumbers[j] = slice.charCodeAt(j)
    }
    byteArrays.push(new Uint8Array(byteNumbers))
  }

  return new Blob(byteArrays, { type: contentType })
}
