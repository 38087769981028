import { FC, useEffect, useState } from 'react'

import AutoAnimateContainer from '@/components/auto-animate-container/AutoAnimateContainer'

import RejectedViewContactApproved from './AlternativeFundingApproved'
import RejectedViewContactDeclined from './AlternativeFundingDeclined'
import RejectedViewInfo from './AlternativeFundingInfo'

import './AlternativeFunding.scss'

const AlternativeFunding: FC = () => {
  const [isContactApproved, setIsContactApproved] = useState<boolean | null>(
    null
  )

  useEffect(() => {
    window.document.body.classList.add('without-progress-bar')

    return () => window.document.body.classList.remove('without-progress-bar')
  }, [isContactApproved])

  return (
    <AutoAnimateContainer className={'full-screen-block rejection-view'}>
      {isContactApproved === null ? (
        <RejectedViewInfo next={(value) => setIsContactApproved(value)} />
      ) : isContactApproved ? (
        <RejectedViewContactApproved />
      ) : (
        <RejectedViewContactDeclined />
      )}
    </AutoAnimateContainer>
  )
}

export default AlternativeFunding
