import { FC } from 'react'

import { NamePath } from 'antd/es/form/interface'

import InputFormItem from './default-items/input-form-item/InputFormItem'
import { InputProps } from 'antd'

interface IFirstNameFormItemProps extends InputProps {
  name: NamePath
}

const FirstNameFormItem: FC<IFirstNameFormItemProps> = ({ name, ...rest }) => {
  return (
    <InputFormItem
      name={name}
      autoComplete='given-name'
      label='First Name'
      placeholder='First Name'
      rules={[
        {
          required: true,
          whitespace: true
        },
      ]}
      {...rest}
    />
  )
}

export default FirstNameFormItem
