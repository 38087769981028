import { ReactElement } from 'react'

import { TStepIconProps } from '@/types/stepIcon'
import getStepIconColor from '@/utils/getStepIconColor'

const StepTwoDesktop = ({ status }: TStepIconProps): ReactElement => {
  const color = getStepIconColor(status)

  return (
    <svg
      width='34'
      height='34'
      viewBox='0 0 34 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.75'
        y='0.75'
        width='32.5'
        height='32.5'
        rx='4.25'
        stroke={color}
        strokeWidth='1.5'
      />
      <g clipPath='url(#clip0_4420_209603)'>
        <path
          d='M19.5846 12.0013C19.5846 13.428 18.428 14.5846 17.0013 14.5846V16.0846C19.2565 16.0846 21.0846 14.2565 21.0846 12.0013H19.5846ZM17.0013 14.5846C15.5746 14.5846 14.418 13.428 14.418 12.0013H12.918C12.918 14.2565 14.7461 16.0846 17.0013 16.0846V14.5846ZM14.418 12.0013C14.418 10.5746 15.5746 9.41797 17.0013 9.41797V7.91797C14.7461 7.91797 12.918 9.74614 12.918 12.0013H14.418ZM17.0013 9.41797C18.428 9.41797 19.5846 10.5746 19.5846 12.0013H21.0846C21.0846 9.74614 19.2565 7.91797 17.0013 7.91797V9.41797ZM14.5013 18.5846H19.5013V17.0846H14.5013V18.5846ZM19.5013 23.7513H14.5013V25.2513H19.5013V23.7513ZM14.5013 23.7513C13.0746 23.7513 11.918 22.5947 11.918 21.168H10.418C10.418 23.4231 12.2461 25.2513 14.5013 25.2513V23.7513ZM22.0846 21.168C22.0846 22.5947 20.928 23.7513 19.5013 23.7513V25.2513C21.7565 25.2513 23.5846 23.4231 23.5846 21.168H22.0846ZM19.5013 18.5846C20.928 18.5846 22.0846 19.7412 22.0846 21.168H23.5846C23.5846 18.9128 21.7565 17.0846 19.5013 17.0846V18.5846ZM14.5013 17.0846C12.2461 17.0846 10.418 18.9128 10.418 21.168H11.918C11.918 19.7412 13.0746 18.5846 14.5013 18.5846V17.0846Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_4420_209603'>
          <rect
            width='20'
            height='20'
            fill='white'
            transform='translate(7 7)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default StepTwoDesktop
