import { ReactElement } from 'react'

import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'

import AppLayout from '@/components/layout/AppLayout'
import HomePage from '@/pages/home-page/HomePage'
import ThanksPage from '@/pages/thanks-page/ThanksPage'
import EAppRoute from '@/types/appRoutes'

const AppRouter = (): ReactElement => {
  return (
    <HashRouter>
      <Routes>
        <Route element={<AppLayout />}>
          <Route index element={<HomePage />} />
          <Route path={EAppRoute.Thanks} element={<ThanksPage />} />
        </Route>

        <Route path='*' element={<Navigate to={EAppRoute.Thanks} />} />
      </Routes>
    </HashRouter>
  )
}

export default AppRouter
