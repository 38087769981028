import { FC } from 'react'

import { InputNumberProps } from 'antd'
import { Rule } from 'antd/es/form'
import { NamePath } from 'antd/es/form/interface'

import { NumberInputFormItem } from './default-items/input-form-item'

interface IEmployeesNumberProps extends InputNumberProps<number> {
  name: NamePath
  rules?: Rule[]
}

const EmployeesNumberFormItem: FC<IEmployeesNumberProps> = ({
  name,
  rules,
  ...rest
}) => {
  return (
    <NumberInputFormItem
      name={name}
      label='Number of Employees'
      placeholder='Enter Number of Employees'
      min={0}
      rules={rules}
      {...rest}
    />
  )
}

export default EmployeesNumberFormItem
