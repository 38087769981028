import React from 'react'

import { patchApplication } from '@/api/app.service'
import FundingPurposeInput from '@/components/form/form-items/FundingPurposeFormItem'
import NavigationControls from '@/components/navigation-controls/NavigationControls'
import { FUNDING_PURPOSE_TEXT } from '@/constants/texts'
import { useTypedSelector } from '@/hooks/redux'
import usePatchApplication from '@/hooks/usePatchApplication'
import { IStepProps } from '@/types/types'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import './FundsFor.scss'

const FundsFor: React.FC<IStepProps> = ({ form, next, prev }) => {
  const applicationId = useTypedSelector(
    ({ formState }) => formState.applicationId
  )

  const [patch, isLoading] = usePatchApplication(
    next,
    async ({
      fundingPurpose: fundingPurposeValue,
      fundingPurposeType: fundingPurposeTypeValue
    }: {
      fundingPurpose: string
      fundingPurposeType: string
    }) => {
      const values = [
        {
          path: '/fundingPurposeType',
          value: fundingPurposeTypeValue
        },
        {
          path: '/fundingPurpose',
          value: fundingPurposeValue
        }
      ]

      await tryCatchWithMessage(async () => {
        await patchApplication(applicationId, values)
      })
    }
  )

  return (
    <div className={'funds-for'}>
      <h2 className={'funds-for__title'}>{FUNDING_PURPOSE_TEXT.title}</h2>
      <p className={'funds-for__subtitle'}>{FUNDING_PURPOSE_TEXT.subtitle}</p>
      <FundingPurposeInput />
      <NavigationControls
        isLoading={isLoading}
        onNext={() => {
          patch()
        }}
        onPrev={() => prev()}
      />
    </div>
  )
}

export default FundsFor
