import { FC } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { Col, Row, Typography } from 'antd'

import { patchApplication } from '@/api/app.service'
import { partnersQueries } from '@/api/partners.service'
import FirstNameFormItem from '@/components/form/form-items/FirstNameFormItem'
import LastNameFormItem from '@/components/form/form-items/LastNameFormItem'
import CheckboxFormItem from '@/components/form/form-items/default-items/checkbox-form-item/CheckboxFormItem'
import EmailInputFormItem from '@/components/form/form-items/email-input-form-item/EmailInputFormItem'
import PhoneInputFormItem from '@/components/form/form-items/phone-input-form-item/PhoneInputFormItem'
import NavigationControls from '@/components/navigation-controls/NavigationControls'
import {
  FORM_FIELD_NAMES_MAP,
  OWNER_INFO_FIELD_NAMES_MAP
} from '@/constants/formFieldNames'
import {
  OWNER_INFO_BUY_BUSINESS_TEXT,
  OWNER_INFO_TEXT
} from '@/constants/texts'
import { PartnerDto } from '@/generated-types'
import { useTypedSelector } from '@/hooks/redux'
import useCustomWatch from '@/hooks/useCustomWatch'
import usePatchApplication from '@/hooks/usePatchApplication'
import useSetPrefilledData from '@/hooks/useSetPrefilledData'
import { EFundingPurposeType, TFormOwnerField } from '@/types/formFieldTypes'
import { IStepProps } from '@/types/types'
import getFormattedPhoneNumber from '@/utils/getFormattedPhoneNumber'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import './PrimaryContactDetails.scss'

const { firstName, lastName, email, phoneNumber, birthDate } =
  OWNER_INFO_FIELD_NAMES_MAP

const { owners, fundingPurposeType } = FORM_FIELD_NAMES_MAP

const PrimaryContactDetails: FC<IStepProps> = ({ next, form, prev }) => {
  const queryClient = useQueryClient()

  const fundingPurposeTypeValue = useCustomWatch(fundingPurposeType, form)
  const buyBusinessLoan =
    fundingPurposeTypeValue === EFundingPurposeType.BuyBusiness

  const data = queryClient.getQueryData<Required<PartnerDto>>(
    partnersQueries.partner.me.queryKey
  )
  const partnerName = data?.name

  useSetPrefilledData(
    form,
    [firstName, lastName, email, birthDate],
    'owners',
    0
  )

  const { applicationId } = useTypedSelector(({ formState, timeLine }) => ({
    applicationId: formState.applicationId,
    currentStep: timeLine.formStep
  }))

  const onPatch = async ({ owners = [] }) => {
    await tryCatchWithMessage(async () => {
      await patchApplication(applicationId, [
        {
          path: '/owners',
          value: owners.map((owner: TFormOwnerField, idx: number) => ({
            ...owner,
            isPrimary: idx === 0,
            phoneNumber: getFormattedPhoneNumber(owner.phoneNumber)
          }))
        }
      ])
    })
  }

  const [patch, isLoading] = usePatchApplication(next, onPatch)

  return (
    <div className={'owner-info'}>
      <h2 className={'owner-info__title'}>{OWNER_INFO_TEXT.title}</h2>
      <p className={'owner-info__subtitle'}>
        {buyBusinessLoan
          ? OWNER_INFO_BUY_BUSINESS_TEXT.subtitle
          : OWNER_INFO_TEXT.subtitle}
      </p>
      <Row gutter={16}>
        <Col span={24}>
          <FirstNameFormItem name={[owners, 0, firstName]} />
        </Col>
        <Col span={24}>
          <LastNameFormItem name={[owners, 0, lastName]} />
        </Col>
        <Col span={24}>
          <EmailInputFormItem name={[owners, 0, email]} />
        </Col>
        <Col span={24}>
          <PhoneInputFormItem
            name={[owners, 0, phoneNumber]}
            label={'Mobile Phone Number'}
          />
        </Col>
        <Col span={24}>
          <Typography.Text
            style={{
              fontFamily: 'Poppins',
              display: 'inline-block',
              marginBottom: 16
            }}
          >
            By providing your phone number below, you agree to receive messages
            via SMS/text and calls from us in regards to your loan application.
            These messages may be sent using an autodialer or prerecorded voice
            technology. Consent is not a condition of purchase. Message and data
            rates may apply. You may opt out at any time by replying STOP to any
            message or following the opt-out instructions in the message.
          </Typography.Text>
          <CheckboxFormItem name={'notificationsAgreed'}>
            I agree to receive SMS and automated calls from {partnerName} under
            the terms outlined above.
          </CheckboxFormItem>
        </Col>
      </Row>
      <NavigationControls
        isLoading={isLoading}
        onNext={() => patch()}
        onPrev={() => prev()}
      />
    </div>
  )
}

export default PrimaryContactDetails
