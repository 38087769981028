import { FC, useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { Button, Divider, Flex } from 'antd'

import { partnersQueries } from '@/api/partners.service'
import { PartnerDto } from '@/generated-types'

import PrivacyModal from './PrivacyModal'
import TermsModal from './TermsModal'

import './UserAgreement.scss'

const UserAgreement: FC = () => {
  const [modalToShow, setModalToShow] = useState<'terms' | 'policy' | ''>('')

  const queryClient = useQueryClient()
  const data = queryClient.getQueryData<Required<PartnerDto>>(
    partnersQueries.partner.me.queryKey
  )

  const buttonProps = {
    size: 'small',
    type: 'link',
    style: { padding: 0, textDecoration: 'none' }
  } as const

  const modalProps = {
    onCancel: () => setModalToShow(''),
    destroyOnClose: true,
    footer: null,
    centered: true
  } as const

  return (
    <>
      <Flex style={{ width: 'fit-content' }}>
        <Button
          className='agreement'
          onClick={() => setModalToShow('terms')}
          {...buttonProps}
        >
          Terms of Service
        </Button>
        <Divider className='divider' type='vertical' />
        <Button
          className='agreement'
          onClick={() => setModalToShow('policy')}
          {...buttonProps}
        >
          Privacy Policy
        </Button>
      </Flex>

      <TermsModal
        open={modalToShow === 'terms'}
        content={data?.termsOfUse}
        {...modalProps}
      />
      <PrivacyModal
        open={modalToShow === 'policy'}
        content={data?.privacyPolicy}
        {...modalProps}
      />
    </>
  )
}

export default UserAgreement
