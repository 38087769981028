import { ReactElement } from 'react'

import { NamePath } from 'antd/es/form/interface'

import SecureFormItem from '@/components/form/form-items/SecureFormItem'

const ENVIRONMENT = process.env.ENVIRONMENT || ''

type TSSNFormItemProps = {
  name: NamePath
  fullName?: NamePath
  isPrimaryOwner: boolean
}

const SSNFormItem = ({
  name,
  fullName,
  isPrimaryOwner
}: TSSNFormItemProps): ReactElement => {
  const formatValue = (value: string, partFormat = (part: string) => part) => {
    if (!value) return ''

    const sanitized = value.replace(/[^\d-]/g, '')
    const digits = sanitized.replace(/-/g, '')

    const formatted = digits.replace(
      /^(\d{0,3})(\d{0,2})(\d{0,4})$/,
      (_match, p1, p2, p3) => {
        let res = partFormat(p1)

        if (p2) res += `-${partFormat(p2)}`
        if (p3) res += `-${partFormat(p3)}`

        return res
      }
    )

    // check if there is last char - "-" and if it is a suitable place for it
    if (
      sanitized.endsWith('-') &&
      [3, 5].some((length) => digits.length === length)
    ) {
      return formatted + '-'
    }

    return formatted
  }

  const validator = (value: string) => {
    if (!value) {
      return Promise.resolve()
    }

    const rawValue = value.replace(/-/g, '')

    if (['dev', 'local-dev'].includes(ENVIRONMENT) && rawValue === '000000000') {
      return Promise.resolve()
    }

    const isValidValue = /^\d{3}-\d{2}-\d{4}$/.test(value)
    const isValidRawValue =
      /^(?!(\d)\1{8})(?!123456789)(?!078051120)(?!219099999)(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/.test(
        rawValue
      )

    return isValidRawValue && isValidValue
      ? Promise.resolve()
      : Promise.reject(new Error('Please enter a valid Social Security Number'))
  }

  return (
    <SecureFormItem
      name={name}
      fullName={fullName}
      rules={[{ required: isPrimaryOwner }]}
      label='Social Security Number'
      placeholder='Enter Social Security Number'
      formatRawValue={formatValue}
      validator={validator}
      maxLength={11}
      inputMode='numeric'
    />
  )
}

export default SSNFormItem
