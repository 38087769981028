import { FC } from 'react'

interface IEmailProps {
  className?: string
}

const Email: FC<IEmailProps> = ({ className }) => {
  return (
    <svg
      width='74'
      height='73'
      viewBox='0 0 74 73'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <circle
        opacity='0.18'
        cx='37'
        cy='36.5'
        r='36.5'
        fill='var(--brand-primary)'
      />
      <rect
        x='12.1172'
        y='11.6172'
        width='49.7727'
        height='49.7727'
        rx='24.8864'
        fill='var(--brand-primary)'
      />
      <g clipPath='url(#clip0_3516_564549)'>
        <path
          d='M27.9822 27.7969C26.7801 27.7969 25.8047 28.7722 25.8047 29.9744C25.8047 30.6595 26.1268 31.3036 26.6757 31.7165L36.5473 39.1202C37.0645 39.5058 37.7722 39.5058 38.2893 39.1202L48.1609 31.7165C48.7099 31.3036 49.032 30.6595 49.032 29.9744C49.032 28.7722 48.0566 27.7969 46.8544 27.7969H27.9822ZM25.8047 32.8778V42.3139C25.8047 43.9153 27.1067 45.2173 28.7081 45.2173H46.1286C47.73 45.2173 49.032 43.9153 49.032 42.3139V32.8778L39.1604 40.2815C38.6581 40.6592 38.0467 40.8634 37.4183 40.8634C36.7899 40.8634 36.1786 40.6592 35.6763 40.2815L25.8047 32.8778Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_3516_564549'>
          <rect
            width='23.2273'
            height='23.2273'
            fill='white'
            transform='translate(25.8047 24.8906)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Email
