import { useCallback, useEffect } from 'react'

import { Form } from 'antd'
import throttle from 'lodash.throttle'

const useValidationObserve = (
  onError: (err: any) => void,
  onSuccess: () => void
) => {
  const form = Form.useFormInstance()
  const value = Form.useWatch((values) => values, { form, preserve: false })

  const validate = useCallback(
    throttle(
      () => {
        form
          .validateFields({ validateOnly: true })
          .then(() => onSuccess())
          .catch(onError)
      },
      600,
      {
        leading: true,
        trailing: true
      }
    ),
    [form]
  )

  useEffect(() => {
    if (!value) {
      return
    }
    validate()
  }, [value, validate])
}

export default useValidationObserve
