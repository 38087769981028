import React, { FC, ReactNode, useEffect, useState } from 'react'

import { useTypedDispatch, useTypedSelector } from '@/hooks/redux'
import { setStepMessage } from '@/redux/features/timeLineSlice'
import { TFormStep, TStepMessage } from '@/types/formSteps'

import DataSecurity from './data-security/DataSecurity'
import PreQualifiedMsg from './pre-qualified-msg/PreQualifiedMsg'
import PrefillDataError from './prefill-date-error/PrefillDataError'

const DEFAULT_MESSAGES: Partial<Record<TFormStep, TStepMessage>> = {
  'primary-contact-details': 'data-security'
}

const StepMessageFactory: FC = () => {
  const dispatch = useTypedDispatch()
  const { currentMessage, currentStepIndex, formSteps } = useTypedSelector(
    ({ timeLine }) => ({
      currentMessage: timeLine.stepMessage,
      currentStepIndex: timeLine.formStep,
      formSteps: timeLine.formSteps
    })
  )

  const [localCurrentMessages, setLocalCurrentMessages] = useState<
    TStepMessage[] | null
  >([])

  useEffect(() => {
    const messages: TStepMessage[] = []
    const currentStepKey = formSteps[currentStepIndex]

    DEFAULT_MESSAGES[currentStepKey] &&
      messages.push(DEFAULT_MESSAGES[currentStepKey])

    if (currentMessage && currentMessage.stepIndex === currentStepIndex) {
      messages.unshift(currentMessage.msgType)
    }

    const timeout = setTimeout(() => setLocalCurrentMessages(messages), 500)
    return () => clearTimeout(timeout)
  }, [currentMessage, currentStepIndex, formSteps])

  const onCloseMsg = () => {
    dispatch(setStepMessage(null))
  }

  const messagesFactory = (msgType: TStepMessage | null): ReactNode | null => {
    switch (msgType) {
      case 'pre-qualified':
        return <PreQualifiedMsg key={msgType} onClose={onCloseMsg} />
      case 'prefill-data-error':
        return <PrefillDataError key={msgType} onClose={onCloseMsg} />
      case 'data-security':
        return <DataSecurity />
      default:
        return null
    }
  }

  return (
    <>
      {localCurrentMessages?.map((msgType) => (
        <React.Fragment key={msgType}>
          {messagesFactory(msgType)}
        </React.Fragment>
      ))}
    </>
  )
}

export default StepMessageFactory
