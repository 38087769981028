import { FC } from 'react'

import { Flex, Typography } from 'antd'

import Close from '@/components/icons/Close'
import StarCheckIcon from '@/components/icons/StarCheckIcon'

import './PreQualifiedMsg.scss'

interface IPreQualifiedMsgProps {
  onClose: () => void
}

const PreQualifiedMsg: FC<IPreQualifiedMsgProps> = ({ onClose }) => {
  return (
    <div className='pre-qualified'>
      <Flex align='center' gap={8}>
        <StarCheckIcon />
        <Typography.Title level={4} className='pre-qualified__title'>
          Great News!
        </Typography.Title>
        <Close className='pre-qualified__close-icon' onClick={onClose} />
      </Flex>
      <Typography.Text className='pre-qualified__subtitle'>
        Based on the information you&apos;ve shared, you&apos;re likely eligible
        for one or more of our funding options
      </Typography.Text>
      <Typography.Text className='pre-qualified__subtitle'>
        Let&apos;s finish gathering a few details about the business owners to
        complete your application.
      </Typography.Text>
    </div>
  )
}

export default PreQualifiedMsg
