import { useTypedSelector } from '@/hooks/redux'

const useProgress = () => {
  const { currentFormStep, currentTimelineStep, formSteps } =
    useTypedSelector(({ timeLine }) => ({
      currentFormStep: timeLine.formStep,
      currentTimelineStep: timeLine.timelineStep,
      formSteps: timeLine.formSteps,
    }))

    // - 1 to not count review step
  const progressLength = formSteps?.length ? formSteps?.length - 1 : 0

  return { currentIndex: currentFormStep, progressLength, currentTimelineStep }
}

export default useProgress
