export const TOTAL_FUNDING_TEXT = {
  title: 'How much funding do you need?',
  subtitle: 'Please enter any amount between $50,000 and $5,000,000+.'
}

export const FUNDING_PURPOSE_TEXT = {
  title: 'What is the purpose of the loan?',
  subtitle: 'Please select how you intend to use the loan funds.'
}

export const FICO_SCORE_TEXT = {
  title: 'What is your FICO® credit score?',
  subtitle: 'Please select the range that applies to you:'
}

export const FUNDS_ACCESS_TIME_TEXT = {
  title: 'How quickly do you need the funds?',
  subtitle: 'Choose one of the options provided:'
}

export const TARGET_BUSINESS_TEXT = {
  title: 'Business Industry & Details',
  subtitle: 'Please provide a few details about your business:'
}

export const OWNER_INFO_TEXT = {
  title: 'Primary Contact Details',
  subtitle: "Please provide the primary business owner's contact details:"
}

export const OWNER_INFO_BUY_BUSINESS_TEXT = {
  title: 'Primary Contact Details',
  subtitle:
    'Please provide additional details about the business entity that will serve as the loan borrower.'
}

export const BUSINESS_INFO_TEXT = {
  title: 'Business Information',
  subtitle:
    'Please provide additional details about the business entity that will serve as the loan borrower.'
}

export const ENTITY_AND_EIN_INFO_TEXT = {
  title: 'Entity and EIN Information',
  subtitle: 'Please provide these details about your business:'
}

export const BUSINESS_OWNERSHIP_TEXT = {
  title: 'Owner Information',
  subtitle: 'Please provide contact details for all of the business owners:'
}

export const BUSINESS_OWNERSHIP_SOLE_PROPRIETOR_TEXT = {
  ...BUSINESS_OWNERSHIP_TEXT,
  subtitle: 'Please provide the contact details for the business owner:'
}

export const REQUIRED_FIELD_ERROR_MESSAGE =
  'Please fill out this required field'
export const REQUIRED_FIELD_SHORT_ERROR_MESSAGE = 'This field is required'