import { useState } from 'react'

import useValidationObserve from './useValidationObserve'

const useValidateStep = (
  onError?: (err: any) => void,
  onSuccess?: () => void
) => {
  const [isValid, setIsValid] = useState(true)

  const handleErrors = (err: any) => {
    !err.outOfDate && setIsValid(false)
    onError?.(err)
  }

  const handleSuccess = () => {
    setIsValid(true)
    onSuccess?.()
  }

  useValidationObserve(handleErrors, handleSuccess)

  return isValid
}

export default useValidateStep
