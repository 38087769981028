import { FC } from 'react'

import { Form } from 'antd'

import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import useCustomWatch from '@/hooks/useCustomWatch'
import { EFundingPurposeType } from '@/types/formFieldTypes'
import { IStepProps } from '@/types/types'

import BusinessMainInfo from './BusinessMainInfo'
import StartBusinessInfo from './StartBusinessInfo'

const { fundingPurposeType } = FORM_FIELD_NAMES_MAP

const BusinessInfoFactory: FC<IStepProps> = (props) => {
  const form = Form.useFormInstance()

  const fundingPurpose = useCustomWatch(fundingPurposeType, {
    form,
    preserve: true
  })

  return (
    <>
      {EFundingPurposeType.StartBusiness === fundingPurpose ? (
        <StartBusinessInfo {...props} />
      ) : (
        <BusinessMainInfo {...props} />
      )}
    </>
  )
}

export default BusinessInfoFactory
