import { EffectCallback, useEffect, useRef } from 'react'

const useUpdateEffect = (effect: EffectCallback, deps: any[]) => {
  const isInitialRenderRef = useRef(true)

  useEffect(() => {
    if (isInitialRenderRef.current) {
      isInitialRenderRef.current = false
      return
    }
    return effect()
  }, deps)
}

export default useUpdateEffect
