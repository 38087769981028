import { useEffect, useState } from 'react'

const useIsDesktop = () => {
  const [isDesktop, setIsDesktop] = useState(true)

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => setIsDesktop(window.innerWidth >= 1440), 100)
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return isDesktop
}

export default useIsDesktop
