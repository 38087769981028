import { FC, ReactNode } from 'react'

import './SectionLabel.scss'

interface SectionLabelProps {
  children: ReactNode
}

const SectionLabel: FC<SectionLabelProps> = ({ children }) => {
  return <div className='section-label'>{children}</div>
}

export default SectionLabel
