import { FC, memo, useEffect, useState } from 'react'

import { useWatch } from 'antd/es/form/Form'

import { patchApplication } from '@/api/app.service'
import InfoContainer from '@/components/info-container/InfoContainer'
import NavigationControls from '@/components/navigation-controls/NavigationControls'
import { FORM_FIELD_NAMES_MAP } from '@/constants/formFieldNames'
import {
  BUSINESS_OWNERSHIP_SOLE_PROPRIETOR_TEXT,
  BUSINESS_OWNERSHIP_TEXT
} from '@/constants/texts'
import { useTypedSelector } from '@/hooks/redux'
import useIsSoleProprietor from '@/hooks/uesIsSoleProprietor'
import usePatchApplication from '@/hooks/usePatchApplication'
import { TFormOwnerField } from '@/types/formFieldTypes'
import { IStepProps } from '@/types/types'
import getFormattedPhoneNumber from '@/utils/getFormattedPhoneNumber'
import tryCatchWithMessage from '@/utils/tryCatchWithMessage'

import OwnershipFields from './components/ownership-fields/OwnershipFields'
import useOwnersValidation from './useOwnersValidation'

import './BusinessOwnerInfo.scss'

const { owners: ownersFieldName } = FORM_FIELD_NAMES_MAP

const BusinessOwnerInfo: FC<IStepProps> = memo(({ next, prev }) => {
  const [activeTab, setActiveTab] = useState(0)
  const [activeAccordionKey, setActiveAccordionKey] = useState('0')

  const {
    handleCatchUncompletedFields,
    handleValidationSuccess,
    handleCatchError,
    uncompletedOwners
  } = useOwnersValidation(
    Number(activeAccordionKey),
    setActiveTab,
    setActiveAccordionKey
  )

  const owners = useWatch(ownersFieldName) || []

  const applicationId = useTypedSelector(
    ({ formState }) => formState.applicationId
  )

  const [patch, isLoading] = usePatchApplication(
    next,
    async ({ owners = [] }) => {
      await tryCatchWithMessage(async () => {
        const propsObj = {
          path: '/owners',
          value: owners.map((owner: TFormOwnerField, idx: number) => ({
            ...owner,
            phoneNumber: getFormattedPhoneNumber(owner.phoneNumber),
            isPrimary: idx === 0,
            socialSecurityNumber: owner.socialSecurityNumber?.replace(/-/g, '')
          }))
        }

        await patchApplication(applicationId, [propsObj])
      })
    }
  )

  const handleNext = () => {
    if (activeTab === 1) {
      patch()
      return
    }

    setActiveTab(1)
  }

  const handlePrev = () => {
    if (activeTab === 0) {
      prev()
      return
    }

    setActiveTab(0)
  }

  const ownershipPercentage = owners.reduce(
    (acc: number, val?: { businessWeight?: number }) =>
      acc + (val?.businessWeight ?? 0),
    0
  )

  const isOwnershipValid = ownershipPercentage === 100

  const isSoleProprietor = useIsSoleProprietor()

  const isDisabledNextButton = (isValid: boolean) => {
    if (activeTab === 0) {
      const uncompletedOwner = uncompletedOwners.find(
        ({ ownerIndex }) => ownerIndex === Number(activeAccordionKey)
      )

      return !!uncompletedOwner ? !uncompletedOwner.isTabsValid.first : false
    }

    const isDisabled = !isSoleProprietor && !isOwnershipValid
    return isDisabled || !isValid
  }

  return (
    <div className={'business-info'}>
      <h2 className={'business-info__title'}>
        {
          (isSoleProprietor
            ? BUSINESS_OWNERSHIP_SOLE_PROPRIETOR_TEXT
            : BUSINESS_OWNERSHIP_TEXT
          ).title
        }
      </h2>
      <p className={'business-info__subtitle'}>
        {
          (isSoleProprietor
            ? BUSINESS_OWNERSHIP_SOLE_PROPRIETOR_TEXT
            : BUSINESS_OWNERSHIP_TEXT
          ).subtitle
        }
      </p>
      <OwnershipFields
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        isSoleProprietor={isSoleProprietor}
        uncompletedOwners={uncompletedOwners}
        activeAccordionKey={activeAccordionKey}
        setActiveAccordionKey={setActiveAccordionKey}
      />
      <NavigationControls
        isLoading={isLoading}
        onNext={handleNext}
        onPrev={handlePrev}
        handleCatchError={handleCatchError}
        onCatchUncompletedFields={handleCatchUncompletedFields}
        onSuccessValidation={handleValidationSuccess}
        nextBtnDisabled={isDisabledNextButton}
      />
      <InfoContainer
        className={'business-info__info-block'}
        title='Soft Credit Pull'
      >
        A soft credit pull is a type of credit check that does not impact your
        credit score. It is often used for things like pre-qualification offers,
        background checks, or when you check your own credit. Unlike a hard
        credit pull, which can lower your score temporarily, a soft pull is a
        quick look at your credit report and is untraceable.​
      </InfoContainer>
    </div>
  )
})

export default BusinessOwnerInfo
