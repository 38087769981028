import { ReactElement } from 'react'

import { Flex } from 'antd'
import Lottie from 'lottie-react'

import FormFooter from '@/components/form-footer/FormFooter'
import CheckIcon from '@/components/icons/CheckIcon'
import ConfettiAnimation from '@/images/confettiAnimation.json'

import './ThanksPage.scss'

const ThanksPage = (): ReactElement => {
  return (
    <div className={'thanks-page'}>
      <Lottie
        className='thanks-page__animation'
        animationData={ConfettiAnimation}
        loop={false}
      />
      <Flex className={'thanks-page__content'}>
        <CheckIcon />
        <h2 className={'thanks-page__title'}>Thank you for applying.</h2>
        <p className={'thanks-page__subtitle'}>
          We&apos;ll review your information and follow up soon. <br />
          Check your email for a confirmation message!
        </p>
      </Flex>
      <FormFooter className={'thanks-page__footer'} />
    </div>
  )
}

export default ThanksPage
