import { ReactElement, useMemo } from 'react'

import { Button, Flex } from 'antd'
import { FormInstance } from 'antd/lib'

import Flag from '@/components/icons/Flag'
import { useTypedDispatch } from '@/hooks/redux'
import { changeStep } from '@/redux/features/timeLineSlice'

import './PartiallyApprovedView.scss'

interface IProps {
  form: FormInstance
}

const PartiallyApprovedView = ({ form }: IProps): ReactElement => {
  const dispatch = useTypedDispatch()

  const { title, subtitle } = useMemo(() => {
    switch (true) {
      case form.getFieldValue('hasGuarantors'):
        return {
          title: 'Just a Few Steps Away',
          subtitle:
            'Complete these final questions to see your personalized funding options.'
        }
      default:
        return {
          title: 'You’re almost there!',
          subtitle:
            'Answer the final questions to unlock your personalized loan options.'
        }
    }
  }, [form])

  return (
    <Flex vertical align='center' className='partially-approved'>
      <Flag />
      <h2 className={'partially-approved__title'}>{title}</h2>
      <p className={'partially-approved__subtitle'}>{subtitle}</p>
      <Button
        type={'primary'}
        onClick={() => {
          setTimeout(() => {
            dispatch(changeStep({ direction: 'forward' }))
          }, 100)
        }}
        className={'partially-approved__next'}
      >
        Next
      </Button>
    </Flex>
  )
}

export default PartiallyApprovedView
